import { BehaviorSubject, map, Observable } from 'rxjs';
import { inject } from '@angular/core';
import { ResourceEnum } from '@supy.api/permissions';

import { ResourceCheck } from '@supy/authz';
import { hasUnleashAccess, ToggleFeature, UnleashService } from '@supy/common';

import { SideNavItem, SideNavItemType } from '../core';

export abstract class SideNavService {
  protected readonly itemsSubject$ = new BehaviorSubject<SideNavItem[]>([]);
  protected readonly unleashService: UnleashService;
  protected allowedActionsMap: Map<ResourceEnum, string[]>;

  get items$(): Observable<SideNavItem[]> {
    return this.itemsSubject$.asObservable().pipe(map(items => this.filterOffItems(items)));
  }

  constructor() {
    this.unleashService = inject(UnleashService);
  }

  protected setFeatureToggleListener(): void {
    this.unleashService.enabledTogglesChanges$.subscribe(toggles =>
      this.itemsSubject$.next(this.getItemsOffStatus(this.itemsSubject$.getValue(), toggles)),
    );
  }

  private filterOffItems(items: SideNavItem[]): SideNavItem[] {
    return items.reduce<SideNavItem[]>((acc, cur) => {
      if (!cur.off) {
        acc.push({ ...cur, ...(cur.children?.length && { children: this.filterOffItems(cur.children) }) });
      }

      return acc;
    }, []);
  }

  private getItemsOffStatus(items: SideNavItem[], toggles: string[]): SideNavItem[] {
    return items.map(item => {
      let quickActionOff = false;

      if (this.allowedActionsMap && item.quickActionPermissions) {
        quickActionOff = item.quickActionPermissions.every(permission => {
          const allowedActions = this.allowedActionsMap.get(permission.resource.kind as ResourceEnum);

          return !allowedActions || permission.actions.every(action => !allowedActions.includes(action));
        });
      }

      const off =
        this.checkFeature(item.toggleKeys, toggles, false) || this.checkFeature(item.disabledToggleKeys, toggles);
      const childrenStatus = item.children?.length ? this.getItemsOffStatus(item.children, toggles) : undefined;

      quickActionOff =
        quickActionOff ||
        this.checkFeature(item.quickActionToggleKeys, toggles, false) ||
        this.checkFeature(item.disabledQuickActionToggleKeys, toggles);

      return {
        ...item,
        ...(childrenStatus && { children: childrenStatus }),
        off,
        quickActionOff,
      };
    });
  }

  private checkFeature(
    togglesToCheckFor: ToggleFeature | ToggleFeature[] | undefined,
    togglesToCheckAgainst: string[],
    on = true,
  ): boolean {
    if (!togglesToCheckFor) {
      return false;
    }

    return on
      ? hasUnleashAccess(togglesToCheckAgainst, togglesToCheckFor)
      : !hasUnleashAccess(togglesToCheckAgainst, togglesToCheckFor);
  }

  protected getResourcesFromItems(items: SideNavItem[]): ResourceCheck[] {
    const resources = new Set<ResourceCheck>();

    for (const item of items) {
      if (item.permissions) {
        item.permissions.forEach(permission => resources.add(permission));
      }

      if (item.quickActionPermissions) {
        item.quickActionPermissions.forEach(permission => resources.add(permission));
      }

      if (item.children?.length) {
        this.getResourcesFromItems(item.children).forEach(resource => resources.add(resource));
      }
    }

    const resourceMap = new Map<string, ResourceCheck>();

    for (const resource of Array.from(resources)) {
      const key = resource.resource.kind;
      const existingResource = resourceMap.get(key);

      if (existingResource) {
        resourceMap.set(key, {
          ...existingResource,
          actions: Array.from(new Set([...existingResource.actions, ...resource.actions])),
        });
      } else {
        resourceMap.set(key, resource);
      }
    }

    return Array.from(resourceMap.values());
  }

  protected getAuthorizedItems(items: SideNavItem[], allowedActionsMap: Map<ResourceEnum, string[]>): SideNavItem[] {
    return items
      .reduce<SideNavItem[]>((acc, cur) => {
        let item = cur;

        const allPermissionsIsDenied =
          item.permissions?.length &&
          item.permissions.every(
            permission =>
              !permission.actions.some(action =>
                allowedActionsMap.get(permission.resource.kind as ResourceEnum)?.includes(action),
              ),
          );

        if (allPermissionsIsDenied) {
          return acc;
        }

        if (item.quickActionPermissions) {
          item = {
            ...item,
            quickActionOff: item.quickActionPermissions.every(
              permission =>
                !permission.actions.every(action =>
                  allowedActionsMap.get(permission.resource.kind as ResourceEnum)?.includes(action),
                ),
            ),
          };
        }

        if (item.children?.length) {
          item = { ...item, children: this.getAuthorizedItems(item.children, allowedActionsMap) };
        }
        acc.push(item);

        return acc;
      }, [])
      .filter(item => item.type !== SideNavItemType.Group || item.children?.length);
  }

  protected getFirstUrl(item: SideNavItem | undefined): string {
    if (item) {
      if (item.url) {
        return item.url;
      }

      if (item.children?.length) {
        return this.getFirstUrl(item.children.at(0));
      }
    }

    return '/404';
  }

  protected hasUrl(items: SideNavItem[], url: string): boolean {
    for (const item of items) {
      if (item.url === url) {
        return true;
      }

      if (item.children && this.hasUrl(item.children, url)) {
        return true;
      }
    }

    return false;
  }
}
