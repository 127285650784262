import { Inject, Injectable } from '@angular/core';
import { Intercom, IntercomBootInput, IntercomCompany } from '@supy-io/ngx-intercom';

import { IntercomConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

export interface IntercomUserInput {
  readonly email?: string;
  readonly id?: string;
  readonly createdAt?: Date;
  readonly firstName?: string;
  readonly lastName?: string;
}

@Injectable()
export class IntercomService {
  private static inited: boolean;

  constructor(
    @Inject(APP_CONFIG) private readonly config: IntercomConfig,
    private readonly intercom: Intercom,
  ) {}

  show(message?: string): void {
    this.intercom.show(message);
  }

  start(user: IntercomUserInput, company?: IntercomCompany): void {
    const { email, id: user_id, createdAt, firstName, lastName } = user;
    const created_at = Number(new Date(createdAt));

    const intercomData: IntercomBootInput = {
      app_id: this.config.intercom.appId,
      email,
      user_id,
      created_at,
      name: `${firstName} ${lastName}`,
      hide_default_launcher: true,
      alignment: 'left',
    };

    if (company) {
      intercomData.company = company;
    }

    this.updateData(intercomData);
  }

  startAnonymous(): void {
    const intercomData: IntercomBootInput = {
      app_id: this.config.intercom.appId,
      email: null,
      user_id: null,
      created_at: null,
      name: null,
      company: null,
      hide_default_launcher: true,
      alignment: 'left',
    };

    this.updateData(intercomData);
  }

  clearSession(): void {
    if (IntercomService.inited) {
      this.intercom.shutdown();
      IntercomService.inited = false;
    }
  }

  private updateData(data: IntercomBootInput) {
    if (IntercomService.inited) {
      this.intercom.update(data);
    } else {
      this.intercom.boot(data);
      IntercomService.inited = true;
    }
  }
}
