"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AntarcticaTimezone = void 0;
var AntarcticaTimezone;
(function (AntarcticaTimezone) {
  AntarcticaTimezone["Casey"] = "Antarctica/Casey";
  AntarcticaTimezone["Davis"] = "Antarctica/Davis";
  AntarcticaTimezone["Macquarie"] = "Antarctica/Macquarie";
  AntarcticaTimezone["Mawson"] = "Antarctica/Mawson";
  AntarcticaTimezone["Palmer"] = "Antarctica/Palmer";
  AntarcticaTimezone["Rothera"] = "Antarctica/Rothera";
  AntarcticaTimezone["Troll"] = "Antarctica/Troll";
})(AntarcticaTimezone = exports.AntarcticaTimezone || (exports.AntarcticaTimezone = {}));
