import { catchError, Observable, throwError } from 'rxjs';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HttpErrorResult } from '../../models';
import { SnackbarService } from './snackbar.service';

@Injectable()
export class SnackbarErrorInterceptor<T> implements HttpInterceptor {
  constructor(private readonly snackbarService: SnackbarService) {}

  intercept(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const isAuthError = error instanceof HttpErrorResponse &&
          (error.status as HttpStatusCode) === HttpStatusCode.Unauthorized;

        if ((request.url.includes('/api/inventory-stock-counts') && request.url.endsWith('/submit')) || isAuthError) {
          return throwError(() => error);
        }

        let errorMsg: string;

        if (request.url.includes('version.yaml') || request.url.startsWith('https://edge-config.ops.supy.io')) {
          errorMsg = `Trouble reaching our servers. Check connection if this persists.`;
        } else if (error.error instanceof ErrorEvent) {
          errorMsg = `client side Error: ${error.error?.message}`;
        } else {
          // server side error
          errorMsg = `${(error as HttpErrorResult).error?.message ?? error.message}`;
        }

        this.snackbarService.open(errorMsg, { variant: 'error' });

        return throwError(() => error);
      }),
    );
  }
}

export const SNACKBAR_INTERCEPTOR = { provide: HTTP_INTERCEPTORS, useClass: SnackbarErrorInterceptor, multi: true };
