"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InMemoryStorageProvider = exports.LocalStorageProvider = exports.UnleashClient = exports.resolveFetch = exports.EVENTS = void 0;
var tiny_emitter_1 = require("tiny-emitter");
var metrics_1 = require("./metrics");
var storage_provider_inmemory_1 = require("./storage-provider-inmemory");
exports.InMemoryStorageProvider = storage_provider_inmemory_1.default;
var storage_provider_local_1 = require("./storage-provider-local");
exports.LocalStorageProvider = storage_provider_local_1.default;
var events_handler_1 = require("./events-handler");
var util_1 = require("./util");
var DEFINED_FIELDS = ['userId', 'sessionId', 'remoteAddress'];
exports.EVENTS = {
  INIT: 'initialized',
  ERROR: 'error',
  READY: 'ready',
  UPDATE: 'update',
  IMPRESSION: 'impression'
};
var IMPRESSION_EVENTS = {
  IS_ENABLED: 'isEnabled',
  GET_VARIANT: 'getVariant'
};
var defaultVariant = {
  name: 'disabled',
  enabled: false
};
var storeKey = 'repo';
var resolveFetch = function () {
  try {
    if ('fetch' in window) {
      return fetch.bind(window);
    } else if ('fetch' in globalThis) {
      return fetch.bind(globalThis);
    }
  } catch (e) {
    console.error('Unleash failed to resolve "fetch"', e);
  }
  return undefined;
};
exports.resolveFetch = resolveFetch;
var UnleashClient = /** @class */function (_super) {
  __extends(UnleashClient, _super);
  function UnleashClient(_a) {
    var storageProvider = _a.storageProvider,
      url = _a.url,
      clientKey = _a.clientKey,
      _b = _a.disableRefresh,
      disableRefresh = _b === void 0 ? false : _b,
      _c = _a.refreshInterval,
      refreshInterval = _c === void 0 ? 30 : _c,
      _d = _a.metricsInterval,
      metricsInterval = _d === void 0 ? 30 : _d,
      _e = _a.disableMetrics,
      disableMetrics = _e === void 0 ? false : _e,
      appName = _a.appName,
      _f = _a.environment,
      environment = _f === void 0 ? 'default' : _f,
      context = _a.context,
      _g = _a.fetch,
      fetch = _g === void 0 ? (0, exports.resolveFetch)() : _g,
      bootstrap = _a.bootstrap,
      _h = _a.bootstrapOverride,
      bootstrapOverride = _h === void 0 ? true : _h,
      _j = _a.headerName,
      headerName = _j === void 0 ? 'Authorization' : _j,
      _k = _a.customHeaders,
      customHeaders = _k === void 0 ? {} : _k;
    var _this = _super.call(this) || this;
    _this.toggles = [];
    _this.etag = '';
    _this.readyEventEmitted = false;
    // Validations
    if (!url) {
      throw new Error('url is required');
    }
    if (!clientKey) {
      throw new Error('clientKey is required');
    }
    if (!appName) {
      throw new Error('appName is required.');
    }
    _this.eventsHandler = new events_handler_1.default();
    _this.toggles = bootstrap && bootstrap.length > 0 ? bootstrap : [];
    _this.url = url instanceof URL ? url : new URL(url);
    _this.clientKey = clientKey;
    _this.headerName = headerName;
    _this.customHeaders = customHeaders;
    _this.storage = storageProvider || new storage_provider_local_1.default();
    _this.refreshInterval = disableRefresh ? 0 : refreshInterval * 1000;
    _this.context = __assign({
      appName: appName,
      environment: environment
    }, context);
    _this.ready = new Promise(function (resolve) {
      _this.init().then(resolve).catch(function (error) {
        console.error(error);
        _this.emit(exports.EVENTS.ERROR, error);
        resolve();
      });
    });
    if (!fetch) {
      console.error('Unleash: You must either provide your own "fetch" implementation or run in an environment where "fetch" is available.');
    }
    _this.fetch = fetch;
    _this.bootstrap = bootstrap && bootstrap.length > 0 ? bootstrap : undefined;
    _this.bootstrapOverride = bootstrapOverride;
    _this.metrics = new metrics_1.default({
      onError: _this.emit.bind(_this, exports.EVENTS.ERROR),
      appName: appName,
      metricsInterval: metricsInterval,
      disableMetrics: disableMetrics,
      url: _this.url,
      clientKey: clientKey,
      fetch: fetch,
      headerName: headerName
    });
    return _this;
  }
  UnleashClient.prototype.getAllToggles = function () {
    return __spreadArray([], this.toggles, true);
  };
  UnleashClient.prototype.isEnabled = function (toggleName) {
    var toggle = this.toggles.find(function (t) {
      return t.name === toggleName;
    });
    var enabled = toggle ? toggle.enabled : false;
    this.metrics.count(toggleName, enabled);
    if (toggle === null || toggle === void 0 ? void 0 : toggle.impressionData) {
      var event_1 = this.eventsHandler.createImpressionEvent(this.context, enabled, toggleName, IMPRESSION_EVENTS.IS_ENABLED);
      this.emit(exports.EVENTS.IMPRESSION, event_1);
    }
    return enabled;
  };
  UnleashClient.prototype.getVariant = function (toggleName) {
    var toggle = this.toggles.find(function (t) {
      return t.name === toggleName;
    });
    if (toggle) {
      this.metrics.count(toggleName, true);
      if (toggle.impressionData) {
        var event_2 = this.eventsHandler.createImpressionEvent(this.context, toggle.enabled, toggleName, IMPRESSION_EVENTS.GET_VARIANT, toggle.variant.name);
        this.emit(exports.EVENTS.IMPRESSION, event_2);
      }
      return toggle.variant;
    } else {
      this.metrics.count(toggleName, false);
      return defaultVariant;
    }
  };
  UnleashClient.prototype.updateContext = function (context) {
    return __awaiter(this, void 0, void 0, function () {
      var staticContext;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            // @ts-expect-error Give the user a nicer error message when
            // including static fields in the mutable context object
            if (context.appName || context.environment) {
              console.warn("appName and environment are static. They can't be updated with updateContext.");
            }
            staticContext = {
              environment: this.context.environment,
              appName: this.context.appName
            };
            this.context = __assign(__assign({}, staticContext), context);
            if (!this.timerRef) return [3 /*break*/, 2];
            return [4 /*yield*/, this.fetchToggles()];
          case 1:
            _a.sent();
            _a.label = 2;
          case 2:
            return [2 /*return*/];
        }
      });
    });
  };
  UnleashClient.prototype.getContext = function () {
    return __assign({}, this.context);
  };
  UnleashClient.prototype.setContextField = function (field, value) {
    var _a, _b;
    if (DEFINED_FIELDS.includes(field)) {
      this.context = __assign(__assign({}, this.context), (_a = {}, _a[field] = value, _a));
    } else {
      var properties = __assign(__assign({}, this.context.properties), (_b = {}, _b[field] = value, _b));
      this.context = __assign(__assign({}, this.context), {
        properties: properties
      });
    }
    if (this.timerRef) {
      this.fetchToggles();
    }
  };
  UnleashClient.prototype.init = function () {
    return __awaiter(this, void 0, void 0, function () {
      var sessionId, _a;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            return [4 /*yield*/, this.resolveSessionId()];
          case 1:
            sessionId = _b.sent();
            this.context = __assign({
              sessionId: sessionId
            }, this.context);
            _a = this;
            return [4 /*yield*/, this.storage.get(storeKey)];
          case 2:
            _a.toggles = _b.sent() || [];
            if (!(this.bootstrap && (this.bootstrapOverride || this.toggles.length === 0))) return [3 /*break*/, 4];
            return [4 /*yield*/, this.storage.save(storeKey, this.bootstrap)];
          case 3:
            _b.sent();
            this.toggles = this.bootstrap;
            this.emit(exports.EVENTS.READY);
            _b.label = 4;
          case 4:
            this.emit(exports.EVENTS.INIT);
            return [2 /*return*/];
        }
      });
    });
  };
  UnleashClient.prototype.start = function () {
    return __awaiter(this, void 0, void 0, function () {
      var interval;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (this.timerRef) {
              console.error('Unleash SDK has already started, if you want to restart the SDK you should call client.stop() before starting again.');
              return [2 /*return*/];
            }
            return [4 /*yield*/, this.ready];
          case 1:
            _a.sent();
            this.metrics.start();
            interval = this.refreshInterval;
            return [4 /*yield*/, this.fetchToggles()];
          case 2:
            _a.sent();
            if (interval > 0) {
              this.timerRef = setInterval(function () {
                return _this.fetchToggles();
              }, interval);
            }
            return [2 /*return*/];
        }
      });
    });
  };
  UnleashClient.prototype.stop = function () {
    if (this.timerRef) {
      clearInterval(this.timerRef);
      this.timerRef = undefined;
    }
    this.metrics.stop();
  };
  UnleashClient.prototype.resolveSessionId = function () {
    return __awaiter(this, void 0, void 0, function () {
      var sessionId;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!this.context.sessionId) return [3 /*break*/, 1];
            return [2 /*return*/, this.context.sessionId];
          case 1:
            return [4 /*yield*/, this.storage.get('sessionId')];
          case 2:
            sessionId = _a.sent();
            if (!!sessionId) return [3 /*break*/, 4];
            sessionId = Math.floor(Math.random() * 1000000000);
            return [4 /*yield*/, this.storage.save('sessionId', sessionId)];
          case 3:
            _a.sent();
            _a.label = 4;
          case 4:
            return [2 /*return*/, sessionId];
        }
      });
    });
  };
  UnleashClient.prototype.getHeaders = function () {
    var _a;
    var headers = (_a = {}, _a[this.headerName] = this.clientKey, _a.Accept = 'application/json', _a['Content-Type'] = 'application/json', _a['If-None-Match'] = this.etag, _a);
    Object.entries(this.customHeaders).filter(util_1.notNullOrUndefined).forEach(function (_a) {
      var name = _a[0],
        value = _a[1];
      return headers[name] = value;
    });
    return headers;
  };
  UnleashClient.prototype.storeToggles = function (toggles) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.toggles = toggles;
            this.emit(exports.EVENTS.UPDATE);
            return [4 /*yield*/, this.storage.save(storeKey, toggles)];
          case 1:
            _a.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  UnleashClient.prototype.fetchToggles = function () {
    return __awaiter(this, void 0, void 0, function () {
      var context, urlWithQuery_1, response, data, e_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!this.fetch) return [3 /*break*/, 8];
            _a.label = 1;
          case 1:
            _a.trys.push([1, 7,, 8]);
            context = this.context;
            urlWithQuery_1 = new URL(this.url.toString());
            // Add context information to url search params. If the properties
            // object is included in the context, flatten it into the search params
            // e.g. /?...&property.param1=param1Value&property.param2=param2Value
            Object.entries(context).filter(util_1.notNullOrUndefined).forEach(function (_a) {
              var contextKey = _a[0],
                contextValue = _a[1];
              if (contextKey === 'properties' && contextValue) {
                Object.entries(contextValue).filter(util_1.notNullOrUndefined).forEach(function (_a) {
                  var propertyKey = _a[0],
                    propertyValue = _a[1];
                  return urlWithQuery_1.searchParams.append("properties[".concat(propertyKey, "]"), propertyValue);
                });
              } else {
                urlWithQuery_1.searchParams.append(contextKey, contextValue);
              }
            });
            return [4 /*yield*/, this.fetch(urlWithQuery_1.toString(), {
              cache: 'no-cache',
              headers: this.getHeaders()
            })];
          case 2:
            response = _a.sent();
            if (!(response.ok && response.status !== 304)) return [3 /*break*/, 5];
            this.etag = response.headers.get('ETag') || '';
            return [4 /*yield*/, response.json()];
          case 3:
            data = _a.sent();
            return [4 /*yield*/, this.storeToggles(data.toggles)];
          case 4:
            _a.sent();
            if (!this.bootstrap && !this.readyEventEmitted) {
              this.emit(exports.EVENTS.READY);
              this.readyEventEmitted = true;
            }
            return [3 /*break*/, 6];
          case 5:
            if (!response.ok && response.status !== 304) {
              console.error('Unleash: Fetching feature toggles did not have an ok response');
              this.emit(exports.EVENTS.ERROR, {
                type: 'HttpError',
                code: response.status
              });
            }
            _a.label = 6;
          case 6:
            return [3 /*break*/, 8];
          case 7:
            e_1 = _a.sent();
            console.error('Unleash: unable to fetch feature toggles', e_1);
            this.emit(exports.EVENTS.ERROR, e_1);
            return [3 /*break*/, 8];
          case 8:
            return [2 /*return*/];
        }
      });
    });
  };
  return UnleashClient;
}(tiny_emitter_1.TinyEmitter);
exports.UnleashClient = UnleashClient;
