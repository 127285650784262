import { HorizontalAlignment, PositionSettings, VerticalAlignment } from '@infragistics/igniteui-angular';

export type TooltipPosition = 'top' | 'bottom' | 'right' | 'left';

export const tooltipPositionSettings: Record<TooltipPosition, PositionSettings> = {
  top: {
    horizontalDirection: HorizontalAlignment.Center,
    horizontalStartPoint: HorizontalAlignment.Center,
    verticalStartPoint: VerticalAlignment.Top,
    verticalDirection: VerticalAlignment.Top,
  },
  bottom: {
    horizontalDirection: HorizontalAlignment.Center,
    horizontalStartPoint: HorizontalAlignment.Center,
    verticalStartPoint: VerticalAlignment.Bottom,
    verticalDirection: VerticalAlignment.Bottom,
  },
  right: {
    horizontalDirection: HorizontalAlignment.Right,
    horizontalStartPoint: HorizontalAlignment.Right,
    verticalStartPoint: VerticalAlignment.Middle,
    verticalDirection: VerticalAlignment.Middle,
  },
  left: {
    horizontalDirection: HorizontalAlignment.Left,
    horizontalStartPoint: HorizontalAlignment.Left,
    verticalStartPoint: VerticalAlignment.Middle,
    verticalDirection: VerticalAlignment.Middle,
  },
} as const;
