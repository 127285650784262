import { BaseSelectItem } from '../models';

export enum PreferredType {
  Regular = 'regular',
  RevenueSharing = 'revenueSharing',
  Referral = 'referral',
}
export const PreferredTypes: BaseSelectItem[] = Object.values(PreferredType).map(value => ({
  label: value === PreferredType.RevenueSharing ? 'revenue sharing' : value,
  value,
}));
