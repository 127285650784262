import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileName',
})
export class FileNamePipe implements PipeTransform {
  transform(url: string) {
    const lastChunk = decodeURIComponent(url).split('/').at(-1);

    return lastChunk.substring(0, lastChunk.lastIndexOf('-')) + lastChunk.substring(lastChunk.lastIndexOf('.'));
  }
}
