<section class="splash-screen">
  <div class="splash-screen__content">
    <img class="splash-screen__logo" src="/assets/images/supy-logo-wide.svg" />
    <svg class="splash-screen__loading" viewBox="0 0 100 100">
      <circle cx="50" cy="50" fill="none" stroke-width="6" r="40" stroke-dasharray="188 64"></circle>
    </svg>
  </div>
  <div class="splash-screen__footer">
    <div>
      <span class="splash-screen__footer-text">Is it taking too long? try to</span>
      <a class="splash-screen__footer-link" href="/refresh">&nbsp;refresh</a>
    </div>
    <div>
      <span class="splash-screen__footer-text">or if that didn't help</span>
      <a class="splash-screen__footer-link" href="/purge">&nbsp;reset and log out</a>
    </div>
  </div>
</section>
