"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Culture = void 0;
var Culture;
(function (Culture) {
  Culture["AfrikaansSouthAfrica"] = "af-ZA";
  Culture["AlbanianAlbania"] = "sq-AL";
  Culture["ArabicUnitedArabEmirates"] = "ar-AE";
  Culture["ArabicBahrain"] = "ar-BH";
  Culture["ArabicAlgeria"] = "ar-DZ";
  Culture["ArabicEgypt"] = "ar-EG";
  Culture["ArabicIraq"] = "ar-IQ";
  Culture["ArabicJordan"] = "ar-JO";
  Culture["ArabicKuwait"] = "ar-KW";
  Culture["ArabicLebanon"] = "ar-LB";
  Culture["ArabicLibya"] = "ar-LY";
  Culture["ArabicMorocco"] = "ar-MA";
  Culture["ArabicOman"] = "ar-OM";
  Culture["ArabicQatar"] = "ar-QA";
  Culture["ArabicSaudiArabia"] = "ar-SA";
  Culture["ArabicSyria"] = "ar-SY";
  Culture["ArabicTunisia"] = "ar-TN";
  Culture["ArabicYemen"] = "ar-YE";
  Culture["ArmenianArmenia"] = "hy-AM";
  Culture["AzeriLatinAzerbaijan"] = "az-Latn-AZ";
  Culture["AzeriCyrillicAzerbaijan"] = "az-Cyrl-AZ";
  Culture["BasqueBasque"] = "eu-ES";
  Culture["BelarusianBelarus"] = "be-BY";
  Culture["BengaliBangladesh"] = "bn-BD";
  Culture["BengaliIndia"] = "bn-IN";
  Culture["BosnianBosniaAndHerzegovina"] = "bs-BA";
  Culture["BulgarianBulgaria"] = "bg-BG";
  Culture["CatalanCatalan"] = "ca-ES";
  Culture["ChineseSimplifiedChina"] = "zh-CN";
  Culture["ChineseTraditionalHongKong"] = "zh-HK";
  Culture["ChineseSimplifiedSingapore"] = "zh-SG";
  Culture["ChineseTraditionalTaiwan"] = "zh-TW";
  Culture["CroatianCroatia"] = "hr-HR";
  Culture["CzechCzechRepublic"] = "cs-CZ";
  Culture["DanishDenmark"] = "da-DK";
  Culture["DivehiMaldives"] = "dv-MV";
  Culture["DutchNetherlands"] = "nl-NL";
  Culture["DutchBelgium"] = "nl-BE";
  Culture["EnglishUnitedStates"] = "en-US";
  Culture["EnglishUnitedKingdom"] = "en-GB";
  Culture["EnglishAustralia"] = "en-AU";
  Culture["EnglishCanada"] = "en-CA";
  Culture["EnglishNewZealand"] = "en-NZ";
  Culture["EnglishIreland"] = "en-IE";
  Culture["EnglishSouthAfrica"] = "en-ZA";
  Culture["EnglishJamaica"] = "en-JM";
  Culture["EnglishCaribbean"] = "en-029";
  Culture["EnglishBelize"] = "en-BZ";
  Culture["EnglishTrinidadAndTobago"] = "en-TT";
  Culture["EstonianEstonia"] = "et-EE";
  Culture["FaroeseFaroeIslands"] = "fo-FO";
  Culture["PersianIran"] = "fa-IR";
  Culture["FinnishFinland"] = "fi-FI";
  Culture["FrenchFrance"] = "fr-FR";
  Culture["FrenchBelgium"] = "fr-BE";
  Culture["FrenchCanada"] = "fr-CA";
  Culture["FrenchSwitzerland"] = "fr-CH";
  Culture["FrenchLuxembourg"] = "fr-LU";
  Culture["FrenchMonaco"] = "fr-MC";
  Culture["GalicianGalician"] = "gl-ES";
  Culture["GeorgianGeorgia"] = "ka-GE";
  Culture["GermanGermany"] = "de-DE";
  Culture["GermanSwitzerland"] = "de-CH";
  Culture["GermanAustria"] = "de-AT";
  Culture["GermanLuxembourg"] = "de-LU";
  Culture["GermanLiechtenstein"] = "de-LI";
  Culture["GreekGreece"] = "el-GR";
  Culture["GujaratiIndia"] = "gu-IN";
  Culture["HebrewIsrael"] = "he-IL";
  Culture["HindiIndia"] = "hi-IN";
  Culture["HungarianHungary"] = "hu-HU";
  Culture["IcelandicIceland"] = "is-IS";
  Culture["IndonesianIndonesia"] = "id-ID";
  Culture["ItalianItaly"] = "it-IT";
  Culture["ItalianSwitzerland"] = "it-CH";
  Culture["JapaneseJapan"] = "ja-JP";
  Culture["KannadaIndia"] = "kn-IN";
  Culture["KazakhKazakhstan"] = "kk-KZ";
  Culture["KonkaniIndia"] = "kok-IN";
  Culture["KoreanKorea"] = "ko-KR";
  Culture["KyrgyzKyrgyzstan"] = "ky-KG";
  Culture["LatvianLatvia"] = "lv-LV";
  Culture["LithuanianLithuania"] = "lt-LT";
  Culture["MacedonianMacedonia"] = "mk-MK";
  Culture["MalayMalaysia"] = "ms-MY";
  Culture["MalayBruneiDarussalam"] = "ms-BN";
  Culture["MarathiIndia"] = "mr-IN";
  Culture["MongolianMongolia"] = "mn-MN";
  Culture["NorwegianBokmalNorway"] = "nb-NO";
  Culture["NorwegianNynorskNorway"] = "nn-NO";
  Culture["PolishPoland"] = "pl-PL";
  Culture["PortugueseBrazil"] = "pt-BR";
  Culture["PortuguesePortugal"] = "pt-PT";
  Culture["PunjabiIndia"] = "pa-IN";
  Culture["RomanianRomania"] = "ro-RO";
  Culture["RussianRussia"] = "ru-RU";
  Culture["SanskritIndia"] = "sa-IN";
  Culture["SerbianLatinSerbiaAndMontenegro"] = "sr-Latn-CS";
  Culture["SerbianCyrillicSerbiaAndMontenegro"] = "sr-Cyrl-CS";
  Culture["SlovakSlovakia"] = "sk-SK";
  Culture["SlovenianSlovenia"] = "sl-SI";
  Culture["SpanishSpain"] = "es-ES";
  Culture["SpanishMexico"] = "es-MX";
  Culture["SpanishGuatemala"] = "es-GT";
  Culture["SpanishCostaRica"] = "es-CR";
  Culture["SpanishPanama"] = "es-PA";
  Culture["SpanishDominicanRepublic"] = "es-DO";
  Culture["SpanishVenezuela"] = "es-VE";
  Culture["SpanishColombia"] = "es-CO";
  Culture["SpanishPeru"] = "es-PE";
  Culture["SpanishArgentina"] = "es-AR";
  Culture["SpanishEcuador"] = "es-EC";
  Culture["SpanishChile"] = "es-CL";
  Culture["SpanishUruguay"] = "es-UY";
  Culture["SpanishParaguay"] = "es-PY";
  Culture["SpanishBolivia"] = "es-BO";
  Culture["SpanishElSalvador"] = "es-SV";
  Culture["SpanishHonduras"] = "es-HN";
  Culture["SpanishNicaragua"] = "es-NI";
  Culture["SpanishPuertoRico"] = "es-PR";
  Culture["SwahiliKenya"] = "sw-KE";
  Culture["SwedishSweden"] = "sv-SE";
  Culture["SwedishFinland"] = "sv-FI";
  Culture["SyriacSyria"] = "syr-SY";
  Culture["TamilIndia"] = "ta-IN";
  Culture["TatarRussia"] = "tt-RU";
  Culture["TeluguIndia"] = "te-IN";
  Culture["ThaiThailand"] = "th-TH";
  Culture["TurkishTurkey"] = "tr-TR";
  Culture["UkrainianUkraine"] = "uk-UA";
  Culture["UrduPakistan"] = "ur-PK";
  Culture["UzbekLatinUzbekistan"] = "uz-Latn-UZ";
  Culture["UzbekCyrillicUzbekistan"] = "uz-Cyrl-UZ";
  Culture["VietnameseVietnam"] = "vi-VN";
  Culture["WelshUnitedKingdom"] = "cy-GB";
  Culture["WolofSenegal"] = "wo-SN";
})(Culture = exports.Culture || (exports.Culture = {}));
