import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxNavbarModule } from '@infragistics/igniteui-angular';

import { HeaderComponent, SecondaryHeaderComponent } from './components';

@NgModule({
  declarations: [HeaderComponent, SecondaryHeaderComponent],
  imports: [CommonModule, IgxNavbarModule],
  exports: [HeaderComponent, IgxNavbarModule, SecondaryHeaderComponent],
})
export class HeaderModule {}
