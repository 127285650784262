import { TrackByFunction } from '@angular/core';

export const areArraysEqual = <T>(array1: T[], array2: T[]) => {
  if (array1 === array2) {
    return true;
  }

  if (array1 === null || array2 === null) {
    return false;
  }

  if (array1.length !== array2.length) {
    return false;
  }

  return array1.every((value, index) => value === array2[index]);
};

export function trackByProperty<T extends object>(property: keyof T): TrackByFunction<T> {
  return (_index: number, obj: T) => obj[property];
}

export function trackByIndex<T extends object>(): TrackByFunction<T> {
  return (index: number) => index;
}

export function isOneOf<T>(item: T, items: T[]): boolean {
  return items.includes(item);
}

export function sortByKey<T>(items: T[], sortKey: keyof T): T[] {
  return items.sort((a, b) => {
    const aValue = a[sortKey];
    const bValue = b[sortKey];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return aValue.localeCompare(bValue);
    }

    return aValue > bValue ? 1 : -1;
  });
}

export function sortNestedByKey<T extends object>(items: T[], sortKey: keyof T, childrenKey: keyof T): T[] {
  const res = sortByKey<T>(items, sortKey);

  return res.map(item => {
    const children = item[childrenKey] as T[];

    return !children?.length ? item : { ...item, [childrenKey]: sortNestedByKey<T>(children, sortKey, childrenKey) };
  });
}

export function sortByName<T extends { name: string }>(data: T[]): T[] {
  const dataCopy = [...data];

  dataCopy.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  return dataCopy;
}
