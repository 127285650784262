import { CodeInputModule as CodeModule } from 'angular-code-input';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CodeInputComponent } from './components';

@NgModule({
  declarations: [CodeInputComponent],
  imports: [CommonModule, CodeModule],
  exports: [CodeInputComponent],
})
export class CodeInputModule {}
