<div class="secondary-layout">
  <div class="secondary-layout__container">
    <ng-content select="[header]"></ng-content>
  </div>

  <main>
    <div class="secondary-layout__container">
      <ng-content select="[content]"></ng-content>
    </div>
  </main>
</div>
