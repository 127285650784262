import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Tracer, TracerProvider } from '@opentelemetry/api';
import { ZoneContextManager } from '@opentelemetry/context-zone-peer-dep';
import { CompositePropagator, W3CBaggagePropagator, W3CTraceContextPropagator } from '@opentelemetry/core';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http/build/esnext';
import { Resource } from '@opentelemetry/resources';
import { BatchSpanProcessor, ConsoleSpanExporter } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { ATTR_SERVICE_NAME, ATTR_SERVICE_VERSION } from '@opentelemetry/semantic-conventions';

import { DEFAULT_TRACER, SDK_OPTIONS } from '../common';
import { OpenTelemetryModuleOptions } from '../config';
import { ITraceService } from './base';

@Injectable()
export class TraceService implements ITraceService, OnDestroy {
  private readonly tracerProvider: WebTracerProvider;

  constructor(@Inject(SDK_OPTIONS) options: OpenTelemetryModuleOptions) {
    const tracerProvider = new WebTracerProvider({
      resource: new Resource({
        [ATTR_SERVICE_NAME]: options.application.name,
        [ATTR_SERVICE_VERSION]: options.application.version,
      }),
    });

    if (options.tracing?.debug) {
      const consoleExporter = new ConsoleSpanExporter();
      const consoleProcessor = new BatchSpanProcessor(consoleExporter);

      tracerProvider.addSpanProcessor(consoleProcessor);
    }

    const otlpExporter = new OTLPTraceExporter({
      url: options.tracing?.url,
    });

    const otlpProcessor = new BatchSpanProcessor(otlpExporter);

    tracerProvider.addSpanProcessor(otlpProcessor);

    tracerProvider.register({
      contextManager: new ZoneContextManager(),
      propagator: new CompositePropagator({
        propagators: [new W3CTraceContextPropagator(), new W3CBaggagePropagator() /*new SentryPropagator()*/],
      }),
    });

    this.tracerProvider = tracerProvider;
  }

  getTracer(name: string = DEFAULT_TRACER): Tracer {
    return this.tracerProvider.getTracer(name);
  }

  getTracerProvider(): TracerProvider {
    return this.tracerProvider;
  }

  async ngOnDestroy(): Promise<void> {
    await this.tracerProvider.forceFlush();
    await this.tracerProvider.shutdown();
  }
}
