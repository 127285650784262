import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[supySelectFooter]',
})
export class SelectFooterTemplateDirective {
  constructor(public template: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[supySelectHeader]',
})
export class SelectHeaderTemplateDirective {
  constructor(public template: TemplateRef<unknown>) {}
}
