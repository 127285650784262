import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconColor, IconModule, IconSize, IconType } from '../icon';

@Component({
  selector: 'supy-empty',
  standalone: true,
  imports: [IconModule],
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyComponent {
  @Input() readonly iconName: IconType = 'empty';

  @Input() readonly title: string = 'No data found';

  @Input() readonly iconSize: IconSize = 'extra-huge';

  @Input() readonly iconColor: IconColor = 'primary';
}
