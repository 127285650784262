<supy-button
  class="supy-editable-options__toggle"
  #toggler
  (buttonClick)="toggle()"
  [color]="buttonColor"
  [density]="buttonSize"
  [disabled]="disabled"
>
  <ng-container *ngIf="options?.length; else noOptions">
    <supy-icon *ngIf="iconSuffix" [name]="iconSuffix" [size]="iconSize" [color]="iconColor"></supy-icon>
    <span>{{ label }} {{ showCount ? '(' + options?.length + ')' : '' }}</span>
  </ng-container>
  <ng-template #noOptions>
    <supy-icon *ngIf="inputSuffix" [name]="inputSuffix" [size]="iconSize"></supy-icon>
    <span>{{ emptyLabel }}</span>
  </ng-template>
</supy-button>

<div class="supy-editable-options__dropdown" igxToggle>
  <div class="supy-editable-options__form">
    <supy-input
      [placeholder]="placeholder"
      (keyup.enter)="addOption()"
      autocomplete="off"
      [suffix]="inputSuffix"
      [formControl]="inputValue"
    >
      <supy-input-error *ngIf="inputValue.invalid">
        <div class="supy-editable-options__flex">
          <supy-icon name="close-circle" size="extra-small" color="error"></supy-icon>
          <span class="supy-editable-options__form--error-text">Invalid Input</span>
        </div>
      </supy-input-error>
    </supy-input>
  </div>
  <div class="supy-editable-options__list" *ngIf="options?.length; else emptyTemplate">
    <div class="supy-editable-options__list--item" *ngFor="let option of options" @fadeInOut>
      <span class="supy-editable-options__list--item-title">{{ option }}</span>
      <supy-button variant="icon" color="error-tertiary" (buttonClick)="remove.emit(option)">
        <supy-icon name="delete" size="small"></supy-icon>
      </supy-button>
    </div>
  </div>
</div>

<ng-template #emptyTemplate>
  <div class="supy-editable-options__empty">
    <supy-icon name="exclamation-hex" size="small" color="secondary"></supy-icon>
    <span>{{ emptyTitle }}</span>
  </div>
</ng-template>
