import { Role, RoleScopeType } from './roles.model';
import { UserSettings } from './user-settings.entity';

export type UserId = string;

export enum UserState {
  active = 'active',
  blocked = 'blocked',
  deleted = 'deleted',
}

export class SimpleUser {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly photoUrl?: string;

  constructor(args: Partial<SimpleUser>) {
    this.id = args.id ?? '';
    this.firstName = args.firstName ?? '';
    this.lastName = args.lastName ?? '';
    this.photoUrl = args.photoUrl ?? '';
  }

  static default(): SimpleUser {
    return { id: '', firstName: '', lastName: '', photoUrl: '' };
  }
}

export class User extends SimpleUser {
  readonly email: string;
  readonly phone: string;
  readonly state: UserState;
  readonly settings?: UserSettings;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly roles: Role[];
  readonly 'roles.scope.type'?: RoleScopeType;
  readonly fullName?: string;

  constructor(args: Partial<User>) {
    super({
      id: args.id,
      firstName: args.firstName,
      lastName: args.lastName,
      photoUrl: args.photoUrl,
    });
    this.email = args.email ?? '';
    this.phone = args.phone ?? '';
    this.state = args.state ?? UserState.active;
    this.settings = args.settings ?? new UserSettings();
    this.createdAt = args.createdAt ?? new Date();
    this.updatedAt = args.updatedAt ?? new Date();
    this.roles = args.roles ?? [];
    this.fullName = `${this.firstName} ${this.lastName}`;
  }
}
