import { catchError, from, Observable, switchMapTo, throwError } from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { APP_CONFIG } from '@supy/core';

import { NotFoundConfig } from '../../config';

@Injectable()
export class NotFoundInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    @Inject(APP_CONFIG) private readonly config: NotFoundConfig,
  ) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const { apiUrl, apiUrlBff, apiUrlPrefix } = this.config;

    if (
      (request.url.startsWith(`${apiUrl}${apiUrlPrefix}`) || request.url.startsWith(`${apiUrlBff}${apiUrlPrefix}`)) &&
      !request.url.includes('/auth')
    ) {
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse && (error.status as HttpStatusCode) === HttpStatusCode.NotFound) {
            return from(this.router.navigateByUrl('404', { skipLocationChange: true, replaceUrl: true })).pipe(
              switchMapTo(throwError(() => error)),
            );
          }

          return throwError(() => error);
        }),
      );
    }

    return next.handle(request);
  }
}
