import { ConnectionPositionPair } from '@angular/cdk/overlay';

const BOTTOM_START_POSITION: ConnectionPositionPair = {
  overlayX: 'start',
  overlayY: 'top',
  originX: 'start',
  originY: 'bottom',
};

const BOTTOM_END_POSITION: ConnectionPositionPair = {
  overlayX: 'end',
  overlayY: 'top',
  originX: 'end',
  originY: 'bottom',
};

const BOTTOM_CENTER_POSITION: ConnectionPositionPair = {
  overlayX: 'center',
  overlayY: 'top',
  originX: 'center',
  originY: 'bottom',
};

const TOP_START_POSITION: ConnectionPositionPair = {
  overlayX: 'start',
  overlayY: 'bottom',
  originX: 'start',
  originY: 'top',
};

const TOP_END_POSITION: ConnectionPositionPair = {
  overlayX: 'end',
  overlayY: 'bottom',
  originX: 'end',
  originY: 'top',
};

const TOP_CENTER_POSITION: ConnectionPositionPair = {
  overlayX: 'center',
  overlayY: 'bottom',
  originX: 'center',
  originY: 'top',
};

export const DROPDOWN_TREE_LIST_POSITIONS: ConnectionPositionPair[] = [
  BOTTOM_START_POSITION,
  BOTTOM_CENTER_POSITION,
  BOTTOM_END_POSITION,
  TOP_START_POSITION,
  TOP_CENTER_POSITION,
  TOP_END_POSITION,
];
