import { ChangeDetectionStrategy, Component, OnInit, Optional } from '@angular/core';

import { IntercomService } from '@supy/components';

@Component({
  selector: 'supy-authentication',
  templateUrl: './authentication.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthenticationComponent implements OnInit {
  constructor(@Optional() private readonly intercomService: IntercomService) {}

  ngOnInit(): void {
    this.intercomService?.startAnonymous();
  }
}
