<igx-tabs class="supy-tabs" *ngIf="items" (selectedIndexChange)="selectedIndexChange.emit($event)">
  <ng-container *ngFor="let item of items; trackBy: trackByLabel">
    <igx-tab-item
      *ngIf="!item?.hidden"
      routerLinkActive
      [routerLinkActiveOptions]="{ exact: false }"
      #routerItem="routerLinkActive"
      [selected]="routerItem.isActive"
      [disabled]="item.disabled"
    >
      <igx-tab-header *ngIf="!headerTemplate" [routerLink]="item.path">
        <supy-icon [name]="item.icon" igxPrefix size="small" *ngIf="item?.icon"></supy-icon>
        <span igxTabHeaderLabel>{{ item.label }}</span>
        <span igxSuffix class="supy-tabs__count supy-tabs__count--{{ item.badgeColor }}" *ngIf="item?.badgeText">{{
          item?.badgeText
        }}</span>
      </igx-tab-header>
      <igx-tab-header *ngIf="headerTemplate" [routerLink]="item.path">
        <div igxTabHeaderLabel>
          <ng-template
            [ngTemplateOutlet]="headerTemplate"
            [ngTemplateOutletContext]="{ $implicit: item }"
          ></ng-template>
        </div>
      </igx-tab-header>
    </igx-tab-item>
  </ng-container>
</igx-tabs>

<igx-tabs
  class="supy-tabs"
  *ngIf="!items && children"
  [selectedIndex]="selectedIndex"
  (selectedIndexChange)="selectedIndexChange.emit($event)"
>
  <igx-tab-item *ngFor="let child of children" [disabled]="child.disabled">
    <igx-tab-header *ngIf="child.header">
      <div igxTabHeaderLabel>
        <ng-template [ngTemplateOutlet]="child.header.template"></ng-template>
      </div>
    </igx-tab-header>
    <igx-tab-content *ngIf="child.content">
      <ng-template [ngTemplateOutlet]="child.content.template"></ng-template>
    </igx-tab-content>
  </igx-tab-item>
</igx-tabs>
