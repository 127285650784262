import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxCardModule } from '@infragistics/igniteui-angular';

import { LoadingOverlayModule } from '../loading-overlay';
import { CardComponent } from './components/card/card.component';

@NgModule({
  declarations: [CardComponent],
  imports: [CommonModule, IgxCardModule, LoadingOverlayModule],
  exports: [CardComponent],
})
export class CardModule {}
