import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxAvatarModule } from '@infragistics/igniteui-angular';

import { SupyCommonModule } from '@supy/common';

import { ActivityLogComponent } from './components';

@NgModule({
  declarations: [ActivityLogComponent],
  imports: [CommonModule, IgxAvatarModule, SupyCommonModule],
  exports: [ActivityLogComponent],
})
export class ActivityLogModule {}
