import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { IconModule } from '../icon';
import { BreadcrumbComponent } from './components';

@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [CommonModule, RouterModule, IconModule],
  exports: [BreadcrumbComponent],
})
export class BreadcrumbModule {}
