import { ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core';

import { TabContentComponent } from '../tab-content';
import { TabHeaderComponent } from '../tab-header';

@Component({
  selector: 'supy-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
  @Input() readonly disabled: boolean;
  @ContentChild(TabHeaderComponent, { static: false }) readonly header: TabHeaderComponent;
  @ContentChild(TabContentComponent, { static: false }) readonly content: TabContentComponent;
}
