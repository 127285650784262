import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxCalendarModule, IgxToggleModule } from '@infragistics/igniteui-angular';

import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { InputModule } from '../input';
import { LoaderModule } from '../loader';
import { CalendarPickerComponent } from './components';

@NgModule({
  declarations: [CalendarPickerComponent],
  imports: [CommonModule, IgxToggleModule, IgxCalendarModule, ButtonModule, IconModule, InputModule, LoaderModule],
  exports: [CalendarPickerComponent],
})
export class CalendarPickerModule {}
