import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Optional,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import Pickr from '@simonwep/pickr';

type ColorPickerType = 'inline' | 'popup';

@Component({
  selector: 'supy-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerComponent implements ControlValueAccessor, AfterViewInit {
  @Input() readonly type: ColorPickerType = 'inline';

  @ViewChild('picker') readonly pickerElement: ElementRef<HTMLElement>;

  picker: Pickr;
  #value: string;
  #disabled = false;

  private onChange: (value: string) => void;
  private onTouched: () => void;
  private touched = false;

  constructor(
    private readonly hostElement: ElementRef<HTMLElement>,
    private readonly cdr: ChangeDetectorRef,
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    this.createPicker();
    this.moveInteractionToGrid();
  }

  private moveInteractionToGrid(): void {
    const { nativeElement } = this.hostElement;
    const interaction = nativeElement.querySelector('.pcr-interaction');
    const selection = nativeElement.querySelector('.pcr-selection');

    selection.append(interaction);
  }

  private createPicker(): void {
    const options = this.createPickerOptions();

    this.picker = Pickr.create(options);
    this.initializePickerEvents();
  }

  private createPickerOptions(): Pickr.Options {
    return {
      default: this.#value || null,
      el: this.pickerElement.nativeElement,
      container: this.hostElement.nativeElement,
      theme: 'monolith',
      inline: this.type === 'inline',
      showAlways: this.type === 'inline',
      useAsButton: this.type === 'inline',
      disabled: this.disabled,
      components: {
        preview: true,
        hue: true,
        opacity: true,
        interaction: {
          input: true,
          hex: true,
          clear: true,
        },
      },
    };
  }

  private initializePickerEvents(): void {
    this.picker
      .on('change', (color: Pickr.HSVaColor) => {
        this.onValueChange(color);
      })
      .on('clear', () => {
        this.picker.setColor('#00000000', true);
        this.onValueChange(null);
      })
      .on('show', () => {
        this.markAsTouched();
      });

    if (this.#disabled) {
      this.picker.disable();
    }
  }

  get value(): string {
    return this.#value;
  }

  get disabled(): boolean {
    return this.#disabled;
  }

  onValueChange(color: Pickr.HSVaColor): void {
    if (!color) {
      return this.onChange(null);
    }

    const hexValue = color.toHEXA().toString();

    this.onChange(hexValue);
  }

  writeValue(value: string): void {
    this.#value = value;
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.#disabled = disabled;

    if (disabled) {
      this.picker?.disable();
    } else {
      this.picker?.enable();
    }
  }

  private markAsTouched(): void {
    if (this.touched) {
      return;
    }

    this.touched = true;
    this.onTouched?.();
    this.emitTouchStatusChanged();
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
