import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

type EmailDomainType = `${string}.${string}`;

interface EmailValidatorOptions {
  readonly domain: EmailDomainType;
}

export function supyEmail({ domain }: EmailValidatorOptions): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;

    if (!value) {
      return null;
    }

    const regex = new RegExp(`${domain}$`, 'i');

    if (Validators.email(control)) {
      return { email: true };
    }

    if (!regex.test(value)) {
      return { 'email.domain': true };
    }

    return null;
  };
}
