export class AuthUser {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly photoUrl?: string;
  readonly email: string;
  readonly phone: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly accessToken: string;
  readonly refreshToken: string;
  readonly firebaseToken: string;

  constructor(args: Partial<AuthUser>) {
    this.id = args.id ?? '';
    this.firstName = args.firstName ?? '';
    this.lastName = args.lastName ?? '';
    this.photoUrl = args.photoUrl;
    this.email = args.email ?? '';
    this.phone = args.phone ?? '';
    this.accessToken = args.accessToken ?? '';
    this.refreshToken = args.refreshToken ?? '';
    this.firebaseToken = args.firebaseToken ?? '';
    this.createdAt = args.createdAt ?? new Date();
    this.updatedAt = args.updatedAt ?? new Date();
  }

  get displayName(): string {
    return this.firstName;
  }

  get initials(): string {
    return (this.firstName[0] ?? '?') + (this.lastName[0] ?? '?');
  }
}
