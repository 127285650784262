"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CountryAlpha3 = void 0;
var CountryAlpha3;
(function (CountryAlpha3) {
  CountryAlpha3["Afghanistan"] = "AFG";
  CountryAlpha3["Albania"] = "ALB";
  CountryAlpha3["Algeria"] = "DZA";
  CountryAlpha3["Andorra"] = "AND";
  CountryAlpha3["Angola"] = "AGO";
  CountryAlpha3["AntiguaAndBarbuda"] = "ATG";
  CountryAlpha3["Argentina"] = "ARG";
  CountryAlpha3["Armenia"] = "ARM";
  CountryAlpha3["Australia"] = "AUS";
  CountryAlpha3["Austria"] = "AUT";
  CountryAlpha3["Azerbaijan"] = "AZE";
  CountryAlpha3["Bahamas"] = "BHS";
  CountryAlpha3["Bahrain"] = "BHR";
  CountryAlpha3["Bangladesh"] = "BGD";
  CountryAlpha3["Barbados"] = "BRB";
  CountryAlpha3["Belarus"] = "BLR";
  CountryAlpha3["Belgium"] = "BEL";
  CountryAlpha3["Belize"] = "BLZ";
  CountryAlpha3["Benin"] = "BEN";
  CountryAlpha3["Bhutan"] = "BTN";
  CountryAlpha3["Bolivia"] = "BOL";
  CountryAlpha3["BosniaAndHerzegovina"] = "BIH";
  CountryAlpha3["Botswana"] = "BWA";
  CountryAlpha3["Brazil"] = "BRA";
  CountryAlpha3["Brunei"] = "BRN";
  CountryAlpha3["Bulgaria"] = "BGR";
  CountryAlpha3["BurkinaFaso"] = "BFA";
  CountryAlpha3["Burundi"] = "BDI";
  CountryAlpha3["CaboVerde"] = "CPV";
  CountryAlpha3["Cambodia"] = "KHM";
  CountryAlpha3["Cameroon"] = "CMR";
  CountryAlpha3["Canada"] = "CAN";
  CountryAlpha3["CentralAfricanRepublic"] = "CAF";
  CountryAlpha3["Chad"] = "TCD";
  CountryAlpha3["Chile"] = "CHL";
  CountryAlpha3["China"] = "CHN";
  CountryAlpha3["Colombia"] = "COL";
  CountryAlpha3["Comoros"] = "COM";
  CountryAlpha3["Congo"] = "COG";
  CountryAlpha3["CostaRica"] = "CRI";
  CountryAlpha3["Croatia"] = "HRV";
  CountryAlpha3["Cuba"] = "CUB";
  CountryAlpha3["Cyprus"] = "CYP";
  CountryAlpha3["CzechRepublic"] = "CZE";
  CountryAlpha3["Denmark"] = "DNK";
  CountryAlpha3["Djibouti"] = "DJI";
  CountryAlpha3["Dominica"] = "DMA";
  CountryAlpha3["DominicanRepublic"] = "DOM";
  CountryAlpha3["EastTimor"] = "TLS";
  CountryAlpha3["Ecuador"] = "ECU";
  CountryAlpha3["Egypt"] = "EGY";
  CountryAlpha3["ElSalvador"] = "SLV";
  CountryAlpha3["EquatorialGuinea"] = "GNQ";
  CountryAlpha3["Eritrea"] = "ERI";
  CountryAlpha3["Estonia"] = "EST";
  CountryAlpha3["Eswatini"] = "SWZ";
  CountryAlpha3["Ethiopia"] = "ETH";
  CountryAlpha3["Fiji"] = "FJI";
  CountryAlpha3["Finland"] = "FIN";
  CountryAlpha3["France"] = "FRA";
  CountryAlpha3["Gabon"] = "GAB";
  CountryAlpha3["Gambia"] = "GMB";
  CountryAlpha3["Georgia"] = "GEO";
  CountryAlpha3["Germany"] = "DEU";
  CountryAlpha3["Ghana"] = "GHA";
  CountryAlpha3["Greece"] = "GRC";
  CountryAlpha3["Grenada"] = "GRD";
  CountryAlpha3["Guatemala"] = "GTM";
  CountryAlpha3["Guinea"] = "GIN";
  CountryAlpha3["GuineaBissau"] = "GNB";
  CountryAlpha3["Guyana"] = "GUY";
  CountryAlpha3["Haiti"] = "HTI";
  CountryAlpha3["Honduras"] = "HND";
  CountryAlpha3["Hungary"] = "HUN";
  CountryAlpha3["Iceland"] = "ISL";
  CountryAlpha3["India"] = "IND";
  CountryAlpha3["Indonesia"] = "IDN";
  CountryAlpha3["Iran"] = "IRN";
  CountryAlpha3["Iraq"] = "IRQ";
  CountryAlpha3["Ireland"] = "IRL";
  CountryAlpha3["Israel"] = "ISR";
  CountryAlpha3["Italy"] = "ITA";
  CountryAlpha3["Jamaica"] = "JAM";
  CountryAlpha3["Japan"] = "JPN";
  CountryAlpha3["Jordan"] = "JOR";
  CountryAlpha3["Kazakhstan"] = "KAZ";
  CountryAlpha3["Kenya"] = "KEN";
  CountryAlpha3["Kiribati"] = "KIR";
  CountryAlpha3["KoreaNorth"] = "PRK";
  CountryAlpha3["KoreaSouth"] = "KOR";
  CountryAlpha3["Kuwait"] = "KWT";
  CountryAlpha3["Kyrgyzstan"] = "KGZ";
  CountryAlpha3["Laos"] = "LAO";
  CountryAlpha3["Latvia"] = "LVA";
  CountryAlpha3["Lebanon"] = "LBN";
  CountryAlpha3["Lesotho"] = "LSO";
  CountryAlpha3["Liberia"] = "LBR";
  CountryAlpha3["Libya"] = "LBY";
  CountryAlpha3["Liechtenstein"] = "LIE";
  CountryAlpha3["Lithuania"] = "LTU";
  CountryAlpha3["Luxembourg"] = "LUX";
  CountryAlpha3["Madagascar"] = "MDG";
  CountryAlpha3["Malawi"] = "MWI";
  CountryAlpha3["Malaysia"] = "MYS";
  CountryAlpha3["Maldives"] = "MDV";
  CountryAlpha3["Mali"] = "MLI";
  CountryAlpha3["Malta"] = "MLT";
  CountryAlpha3["MarshallIslands"] = "MHL";
  CountryAlpha3["Mauritania"] = "MRT";
  CountryAlpha3["Mauritius"] = "MUS";
  CountryAlpha3["Mexico"] = "MEX";
  CountryAlpha3["Micronesia"] = "FSM";
  CountryAlpha3["Moldova"] = "MDA";
  CountryAlpha3["Monaco"] = "MCO";
  CountryAlpha3["Mongolia"] = "MNG";
  CountryAlpha3["Montenegro"] = "MNE";
  CountryAlpha3["Morocco"] = "MAR";
  CountryAlpha3["Mozambique"] = "MOZ";
  CountryAlpha3["Myanmar"] = "MMR";
  CountryAlpha3["Namibia"] = "NAM";
  CountryAlpha3["Nauru"] = "NRU";
  CountryAlpha3["Nepal"] = "NPL";
  CountryAlpha3["Netherlands"] = "NLD";
  CountryAlpha3["NewZealand"] = "NZL";
  CountryAlpha3["Nicaragua"] = "NIC";
  CountryAlpha3["Niger"] = "NER";
  CountryAlpha3["Nigeria"] = "NGA";
  CountryAlpha3["NorthMacedonia"] = "MKD";
  CountryAlpha3["Norway"] = "NOR";
  CountryAlpha3["Oman"] = "OMN";
  CountryAlpha3["Pakistan"] = "PAK";
  CountryAlpha3["Palau"] = "PLW";
  CountryAlpha3["Panama"] = "PAN";
  CountryAlpha3["PapuaNewGuinea"] = "PNG";
  CountryAlpha3["Paraguay"] = "PRY";
  CountryAlpha3["Peru"] = "PER";
  CountryAlpha3["Philippines"] = "PHL";
  CountryAlpha3["Poland"] = "POL";
  CountryAlpha3["Portugal"] = "PRT";
  CountryAlpha3["Qatar"] = "QAT";
  CountryAlpha3["Romania"] = "ROU";
  CountryAlpha3["Russia"] = "RUS";
  CountryAlpha3["Rwanda"] = "RWA";
  CountryAlpha3["SaintKittsAndNevis"] = "KNA";
  CountryAlpha3["SaintLucia"] = "LCA";
  CountryAlpha3["SaintVincentAndTheGrenadines"] = "VCT";
  CountryAlpha3["Samoa"] = "WSM";
  CountryAlpha3["SanMarino"] = "SMR";
  CountryAlpha3["SaoTomeAndPrincipe"] = "STP";
  CountryAlpha3["SaudiArabia"] = "SAU";
  CountryAlpha3["Senegal"] = "SEN";
  CountryAlpha3["Serbia"] = "SRB";
  CountryAlpha3["Seychelles"] = "SYC";
  CountryAlpha3["SierraLeone"] = "SLE";
  CountryAlpha3["Singapore"] = "SGP";
  CountryAlpha3["Slovakia"] = "SVK";
  CountryAlpha3["Slovenia"] = "SVN";
  CountryAlpha3["SolomonIslands"] = "SLB";
  CountryAlpha3["Somalia"] = "SOM";
  CountryAlpha3["SouthAfrica"] = "ZAF";
  CountryAlpha3["SouthSudan"] = "SSD";
  CountryAlpha3["Spain"] = "ESP";
  CountryAlpha3["SriLanka"] = "LKA";
  CountryAlpha3["Sudan"] = "SDN";
  CountryAlpha3["Suriname"] = "SUR";
  CountryAlpha3["Sweden"] = "SWE";
  CountryAlpha3["Switzerland"] = "CHE";
  CountryAlpha3["Syria"] = "SYR";
  CountryAlpha3["Taiwan"] = "TWN";
  CountryAlpha3["Tajikistan"] = "TJK";
  CountryAlpha3["Tanzania"] = "TZA";
  CountryAlpha3["Thailand"] = "THA";
  CountryAlpha3["Togo"] = "TGO";
  CountryAlpha3["Tonga"] = "TON";
  CountryAlpha3["TrinidadAndTobago"] = "TTO";
  CountryAlpha3["Tunisia"] = "TUN";
  CountryAlpha3["Turkey"] = "TUR";
  CountryAlpha3["Turkmenistan"] = "TKM";
  CountryAlpha3["Tuvalu"] = "TUV";
  CountryAlpha3["Uganda"] = "UGA";
  CountryAlpha3["Ukraine"] = "UKR";
  CountryAlpha3["UnitedArabEmirates"] = "ARE";
  CountryAlpha3["UnitedKingdom"] = "GBR";
  CountryAlpha3["UnitedStates"] = "USA";
  CountryAlpha3["Uruguay"] = "URY";
  CountryAlpha3["Uzbekistan"] = "UZB";
  CountryAlpha3["Vanuatu"] = "VUT";
  CountryAlpha3["VaticanCity"] = "VAT";
  CountryAlpha3["Venezuela"] = "VEN";
  CountryAlpha3["Vietnam"] = "VNM";
  CountryAlpha3["Yemen"] = "YEM";
  CountryAlpha3["Zambia"] = "ZMB";
  CountryAlpha3["Zimbabwe"] = "ZWE";
})(CountryAlpha3 = exports.CountryAlpha3 || (exports.CountryAlpha3 = {}));
