import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxSwitchModule } from '@infragistics/igniteui-angular';

import { SwitchComponent } from './components';

@NgModule({
  declarations: [SwitchComponent],
  imports: [CommonModule, IgxSwitchModule],
  exports: [SwitchComponent],
})
export class SwitchModule {}
