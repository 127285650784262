import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BadgeWidth } from '../../../badge';
import { BadgeSize, BadgeStatus, BadgeWeight } from '../../models';

@Component({
  selector: 'supy-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusBadgeComponent {
  @Input() status: BadgeStatus;
  @Input() width: BadgeWidth = 'auto';
  @Input() weight: BadgeWeight;
  @Input() text: string;
  @Input() size: BadgeSize = 'medium';

  getClasses(): string[] {
    const classes: string[] = [];
    const className = 'supy-status-badge--';

    if (this.status) {
      classes.push(`${className + this.status}`);
    }

    if (this.weight) {
      classes.push(`${className + this.weight}`);
    }

    if (this.size) {
      classes.push(`${className + this.size}`);
    }

    return classes;
  }
}
