import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';
import { Currency } from '@supy.api/dictionaries';

import { Destroyable, getPercentage, SkeletonObjectType } from '@supy/common';

import { DialogComponent, IDialogComponent } from '../../../../../dialog';
import { ChartColor, StackedBarChartD3ReturnData, StackedChartData } from '../stacked-bar-chart-d3';

@Component({
  selector: 'supy-expanded-stacked-bar-chart',
  templateUrl: './expanded-stacked-bar-chart.component.html',
  styleUrls: ['./expanded-stacked-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandedStackedBarChartComponent<T extends StackedChartData>
  extends Destroyable
  implements IDialogComponent
{
  @Input() readonly customTitle: string;
  @Input() readonly subTitle: string;
  @Input() readonly percentageOf: 'location' | 'supplier' | 'category' | 'item';
  @Input() isLoading: boolean;
  @Input() readonly currency: Currency;
  @Input() readonly canExport: boolean;
  @Input() readonly headerTemplate: TemplateRef<unknown>;
  @Input() readonly tooltipTemplate: TemplateRef<unknown>;
  @Input() readonly withNegativity: boolean;
  @Input() set data(data: T[]) {
    this.#data = this.shouldTrim ? data.slice(0, 60) : data;
    this.allData = data;
  }

  get data(): T[] {
    return this.#data;
  }

  @Input() readonly isHorizontal: boolean;
  @Input() readonly isBarClickable: boolean;
  @Input() readonly isLabelClickable: boolean;
  @Input() readonly fullBarHovering: boolean;
  @Input() readonly showSeparateLabels: boolean;
  @Input() readonly colors: string[] = ['#DDD7F1', '#B0A2DF', '#9A87D6', '#836DCD', '#785FC8', '#6D52C4', '#6144BF'];
  @Input() readonly colorShades: ChartColor;
  @Input() readonly xAxisShow: boolean = true;
  @Input() readonly yAxisShow: boolean = true;
  @Input() readonly shouldTrim: boolean = false;
  @Input() readonly maxBars: number;

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();

  @Output() readonly barClicked = new EventEmitter<StackedBarChartD3ReturnData>();
  @Output() readonly labelClicked = new EventEmitter<SkeletonObjectType>();
  @Output() readonly exportClicked = new EventEmitter<T[]>();

  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  protected readonly getPercentage = getPercentage;
  protected readonly svgWidth = window.innerWidth * 0.75;
  protected readonly svgHeight = window.innerHeight * 0.75;
  protected allData: T[];

  constructor() {
    super();
  }

  #data: T[];

  onBarClick(event: StackedBarChartD3ReturnData): void {
    this.barClicked.emit(event);
    this.closeDialog();
  }

  onLabelClick(event: SkeletonObjectType): void {
    this.labelClicked.emit(event);
    this.closeDialog();
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
