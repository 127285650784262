import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

import { IDestroyable } from './destroyable.interface';

const destroySubject = Symbol('destroySubject');

@Injectable()
export abstract class Destroyable implements IDestroyable, OnDestroy {
  private readonly [destroySubject] = new Subject<void>();
  readonly destroyed$ = this[destroySubject].asObservable();

  ngOnDestroy(): void {
    this[destroySubject].next();
    this[destroySubject].complete();
  }
}
