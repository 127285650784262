import { ConstructorLike } from './api-decorator';

/**
 * Creates a function that checks its argument against the given class
 * constructor to see if the argument is an instance of the class.
 * @param classConstructor The constructor of the class to check the value against.
 */
export const isInstanceOf =
  <T>(classConstructor: ConstructorLike<T>) =>
  (value: unknown): value is T =>
    value instanceof classConstructor;
