export enum PredefinedRangeBy {
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
  Quarter = 'quarter',
  Years = 'years',
}

export interface PredefinedRange {
  readonly range: number;
  readonly previousRange?: boolean;
  readonly by: PredefinedRangeBy;
  readonly customLabel?: string;
}

export const DEFAULT_DATE_RANGE_DISPLAY_MONTHS_COUNT = 2;
