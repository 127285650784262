import { DateRange } from '@infragistics/igniteui-angular';

import { PredefinedRange, PredefinedRangeBy } from './date-range.types';

export function getDefaultRange(): PredefinedRange[] {
  return [
    { range: 7, by: PredefinedRangeBy.Days },
    { range: 2, by: PredefinedRangeBy.Weeks },
    { range: 3, by: PredefinedRangeBy.Months },
    { range: 1, by: PredefinedRangeBy.Years },
  ];
}

export function getWeeksQuartersRange(): PredefinedRange[] {
  return [
    { range: 1, previousRange: true, by: PredefinedRangeBy.Weeks },
    { range: new Date().getDate(), by: PredefinedRangeBy.Days, customLabel: 'This Month' },
    { range: 1, previousRange: true, by: PredefinedRangeBy.Months, customLabel: 'Last Month' },
    { range: 1, by: PredefinedRangeBy.Quarter, customLabel: 'This Quarter' },
    { range: 1, previousRange: true, by: PredefinedRangeBy.Quarter, customLabel: 'Last Quarter' },
  ];
}

export function getLastMonths(offset = 1): DateRange {
  const today = new Date();
  const start = new Date(today.getFullYear(), today.getMonth() - offset, 1);
  const end = new Date();

  end.setDate(1);
  end.setHours(-1);
  end.setHours(23, 59, 59, 999);

  return { start, end };
}

export function getLastWeeks(offset = 1): DateRange {
  const daysOffset = 7 * offset;
  const start = getPreviousMonday(daysOffset);
  const end = getPreviousSunday(daysOffset);

  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);

  return { start, end };
}

function getPreviousMonday(daysOffset: number): Date {
  const date = new Date();
  const day = date.getDay();
  const prevMonday = new Date();

  if (date.getDay() == 0) {
    prevMonday.setDate(date.getDate() - daysOffset);
  } else {
    prevMonday.setDate(date.getDate() - (day - 1 + daysOffset));
  }

  return prevMonday;
}

function getPreviousSunday(daysOffset: number): Date {
  const prevMonday = getPreviousMonday(daysOffset);
  const prevSunday = new Date(prevMonday);

  prevSunday.setDate(prevMonday.getDate() + 6);

  return prevSunday;
}

export function getPreviousQuarters(quarterOffset = 0): DateRange {
  const today = new Date();
  const currentMonth = today.getMonth();
  const quarter = Math.ceil((currentMonth + 1) / 3);
  const calculatedQuarter = quarter - (quarterOffset - Math.floor(quarterOffset / 4) * 4);
  const targetQuarter = calculatedQuarter < 1 ? calculatedQuarter + 4 : calculatedQuarter;
  const calculatedYear = today.getFullYear() - Math.floor((quarterOffset - 1) / 4);
  const adjustedYear = quarter <= quarterOffset % 4 || !(quarterOffset % 4) ? calculatedYear - 1 : calculatedYear;
  const targetYear = adjustedYear > today.getFullYear() ? adjustedYear - 1 : adjustedYear;
  const end = new Date(targetYear, targetQuarter * 3, 0);
  const start = new Date(targetYear, end.getMonth() - 2, 1);

  return { start, end };
}
