export interface IQueryMetadata {
  readonly count: number;
  readonly total: number;
}

export interface IQueryResponse<T> {
  readonly data: T[];
  readonly metadata: IQueryMetadata;
}

export function mergeQueryResponses<T>(responses: IQueryResponse<T>[]): IQueryResponse<T> {
  if (!responses?.length) {
    return { data: [], metadata: { count: 0, total: 0 } };
  }

  return responses.reduce<IQueryResponse<T>>(
    (acc, cur) => {
      acc = {
        data: [...acc.data, ...cur.data],
        metadata: {
          ...acc.metadata,
          count: acc.metadata.count + cur.metadata.count,
        },
      };

      return acc;
    },
    { data: [], metadata: { count: 0, total: responses.at(0).metadata.total } },
  );
}
