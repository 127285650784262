import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxInputGroupModule } from '@infragistics/igniteui-angular';

import { InputErrorComponent, InputHintComponent, InputHintContainerComponent } from './components';
import { ErrorIfDirective } from './directives';
import { ErrorIfPipe } from './pipes';

@NgModule({
  declarations: [InputHintComponent, InputErrorComponent, InputHintContainerComponent, ErrorIfDirective, ErrorIfPipe],
  imports: [CommonModule, IgxInputGroupModule],
  exports: [InputHintComponent, InputErrorComponent, InputHintContainerComponent, ErrorIfDirective, ErrorIfPipe],
})
export class InputHintsModule {}
