import { Pipe, PipeTransform } from '@angular/core';
import { IANATimezone } from '@supy.api/dictionaries';

import { getDateInTimeZone } from '../helpers';

@Pipe({
  name: 'timezone',
})
export class TimezonePipe implements PipeTransform {
  transform(value: Date | string | number, ianaTimeZone: IANATimezone): Date {
    return getDateInTimeZone(new Date(value ?? null), ianaTimeZone);
  }
}
