import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxDatePickerModule, IgxInputGroupModule } from '@infragistics/igniteui-angular';

import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { InputHintsModule } from '../input-hints';
import { DateInputComponent } from './components';

@NgModule({
  declarations: [DateInputComponent],
  imports: [CommonModule, IgxDatePickerModule, IgxInputGroupModule, InputHintsModule, IconModule, ButtonModule],
  exports: [DateInputComponent],
})
export class DateInputModule {}
