import { RouterAction } from '@ngxs/router-plugin';
import { ActionType } from '@ngxs/store';

export function reduxDevToolsActionSanitizer(action: ActionType) {
  // Add other actions as needed if Redux Dev Tools starts crashing in the browser
  // Remove this `if` clause in case need to debug with help of Redux Dev Tools
  if (action.type.startsWith('[Router]')) {
    const { routerState, event, ...sanitizedAction } = action as unknown as RouterAction<unknown>;

    return sanitizedAction;
  }

  return action;
}

// `[Router]` actions above create a large chunk of state in the router property.
// Blank it out for Redux Dev Tools to reduce its memory footprint.
export function reduxDevToolsStateSanitizer(state: Record<string, unknown>) {
  return state?.router
    ? {
        ...state,
        router: {},
      }
    : state;
}
