import { AgGridModule } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';

import { SupyCommonModule } from '@supy/common';

import { AutocompleteModule } from '../autocomplete';
import { ButtonModule } from '../button';
import { CheckboxModule } from '../checkbox';
import { ComboboxModule } from '../combo-box';
import { CounterInputModule } from '../counter-input';
import { DropdownModule } from '../dropdown';
import { EditableOptionsModule } from '../editable-options';
import { EmptyComponent } from '../empty';
import { IconModule } from '../icon';
import { InputModule } from '../input';
import { InputWithButtonModule } from '../input-with-button';
import { PaginationModule } from '../pagination';
import { SelectModule } from '../select';
import { StatusBadgeModule } from '../status-badge';
import { SwitchModule } from '../switch';
import { TextareaModule } from '../textarea';
import { TimePickerModule } from '../time-picker';
import { TooltipModule } from '../tooltip';
import { DropdownTreeModule } from '../tree-view-dropdown';
import {
  AutocompleteCellEditorComponent,
  ButtonGroupCellEditorComponent,
  EditableOptionsCellEditorComponent,
  InputCellEditorComponent,
  InputCounterCellEditorComponent,
  SelectCellEditorComponent,
  SwitchCellEditorComponent,
  TimePickerCellEditorComponent,
} from './cell-editors';
import { MultiSelectCellEditorComponent } from './cell-editors/multi-select-cell-editor/multi-select-cell-editor.component';
import {
  ActionsCellRendererComponent,
  AutocompleteCellRendererComponent,
  ButtonGroupCellRendererComponent,
  CheckboxCellRendererComponent,
  EditableOptionsCellRendererComponent,
  IconStatusBadgeCellRendererComponent,
  InputCellRendererComponent,
  InputCounterCellRendererComponent,
  InputWithDropdownCellRendererComponent,
  SwitchCellRendererComponent,
  SwitchViewCellRendererComponent,
  TextWithIconCellRendererComponent,
  TimePickerCellRendererComponent,
} from './cell-renderers';
import { SelectCellRendererComponent } from './cell-renderers/select-cell-renderer';
import { HeaderWithIconComponent, SortableHeaderComponent } from './components';
import { GridPocComponent } from './grid-poc.component';
import { NoRowsOverlayComponent } from './no-row-overlay';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

@NgModule({
  declarations: [
    ActionsCellRendererComponent,
    AutocompleteCellEditorComponent,
    AutocompleteCellRendererComponent,
    ButtonGroupCellEditorComponent,
    ButtonGroupCellRendererComponent,
    CheckboxCellRendererComponent,
    EditableOptionsCellEditorComponent,
    EditableOptionsCellRendererComponent,
    GridPocComponent,
    HeaderWithIconComponent,
    SortableHeaderComponent,
    IconStatusBadgeCellRendererComponent,
    InputCellEditorComponent,
    SwitchCellRendererComponent,
    SwitchCellEditorComponent,
    SelectCellEditorComponent,
    TextWithIconCellRendererComponent,
    SwitchViewCellRendererComponent,
    TimePickerCellRendererComponent,
    TimePickerCellEditorComponent,
    InputCounterCellRendererComponent,
    InputCounterCellEditorComponent,
    InputWithDropdownCellRendererComponent,
    NoRowsOverlayComponent,
    MultiSelectCellEditorComponent,
    SelectCellRendererComponent,
    InputCellRendererComponent,
  ],
  imports: [
    AgGridModule,
    AutocompleteModule,
    ButtonModule,
    CheckboxModule,
    ComboboxModule,
    CommonModule,
    DropdownModule,
    EditableOptionsModule,
    IconModule,
    InputModule,
    PaginationModule,
    RouterLink,
    SelectModule,
    StatusBadgeModule,
    CheckboxModule,
    DropdownModule,
    SwitchModule,
    SupyCommonModule,
    TooltipModule,
    TimePickerModule,
    CounterInputModule,
    DropdownTreeModule,
    InputWithButtonModule,
    CdkConnectedOverlay,
    EmptyComponent,
    CdkOverlayOrigin,
    TextareaModule,
  ],
  exports: [GridPocComponent],
  bootstrap: [GridPocComponent],
})
export class GridPocModule {}
