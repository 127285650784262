import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { IntercomModule as SupyIntercomModule } from '@supy-io/ngx-intercom';

import { IntercomService } from './services';

@NgModule({
  imports: [CommonModule, SupyIntercomModule.forRoot({ updateOnRouterChange: true })],
  exports: [SupyIntercomModule],
})
export class IntercomModule {
  static forRoot(): ModuleWithProviders<IntercomModule> {
    return {
      ngModule: IntercomModule,
      providers: [IntercomService],
    };
  }
}
