import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'supy-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {
  protected onBackToHomeClick(): void {
    window.location.replace('/');
  }
}
