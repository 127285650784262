import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SupyCommonModule, UnleashModule } from '@supy/common';
import { ButtonModule, IconModule, ImageModule } from '@supy/components';

import { SideNavComponent } from './components';

@NgModule({
  declarations: [SideNavComponent],
  imports: [
    CommonModule,
    RouterModule,
    SupyCommonModule,
    UnleashModule,
    IconModule,
    ImageModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [SideNavComponent],
})
export class SideNavModule {}
