import { takeUntil } from 'rxjs';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { AutoPositionStrategy, OverlaySettings } from '@infragistics/igniteui-angular';
import { swingInTopBck, swingOutTopBck } from '@infragistics/igniteui-angular/animations';

import { Destroyable } from '@supy/common';

import { DropdownComponent } from '../dropdown';
import { MenuPosition, menuPositionSettings } from './menu-position';

@Component({
  selector: 'supy-dropdown-toggle',
  templateUrl: './dropdown-toggle.component.html',
  styleUrls: ['./dropdown-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownToggleComponent extends Destroyable implements OnInit, AfterViewInit {
  @Input() readonly dropdownRef: DropdownComponent;
  @Input() readonly elementRef: ElementRef;
  @Input() readonly position: MenuPosition = 'bottom-left';

  overlaySettings: OverlaySettings;

  ngOnInit(): void {
    this.setOverlaySettings();
  }

  setOverlaySettings(): void {
    const settings = menuPositionSettings[this.position];
    const positionStrategy = new AutoPositionStrategy({
      ...settings,
      openAnimation: swingInTopBck,
      closeAnimation: swingOutTopBck,
    });

    this.overlaySettings = { positionStrategy, closeOnEscape: true };
  }

  ngAfterViewInit(): void {
    if (this.dropdownRef && this.dropdownRef.menu) {
      this.dropdownRef.menu.closed.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.resetDropdownSelection();
      });
    }
  }

  private resetDropdownSelection(): void {
    this.dropdownRef.menu.resetActiveItem();
  }
}
