import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'supy-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {
  @Input() readonly backgroundImage: string;
  @Input() readonly backgroundSize?: string = 'contain';
  @Input() readonly backgroundPosition?: string = 'center';
}
