import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Currency } from '@supy.api/dictionaries';

import { StatisticsCard } from './statistics-card.interface';

@Component({
  selector: 'supy-statistics-card',
  templateUrl: './statistics-card.component.html',
  styleUrls: ['./statistics-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticsCardComponent<T> {
  @Input() readonly statisticCard: StatisticsCard<T>;
  @Input() readonly loading: boolean;

  @Input() set isActive(input: boolean) {
    this.#isActive = input;
  }

  get isActive(): boolean {
    return this.#isActive;
  }

  @Input() readonly currency?: Currency;

  #isActive: boolean;

  @Output() readonly cardClicked = new EventEmitter<StatisticsCard<T>>();
  @Output() readonly cardFilterCleared = new EventEmitter<StatisticsCard<T>>();

  @HostListener('click')
  onCardClick(): void {
    if (this.statisticCard.clickable) {
      if (this.statisticCard.clearable) {
        this.#isActive = !this.#isActive;

        if (this.#isActive) {
          this.cardClicked.emit(this.statisticCard);
        } else {
          this.cardFilterCleared.emit(this.statisticCard);
        }
      } else {
        this.cardClicked.emit(this.statisticCard);
      }
    }
  }
}
