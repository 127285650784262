import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

type ImageLoading = 'lazy' | 'eager';
type ImageSize = number | `${number}${'rem' | '%'}` | 'auto';
type ImageAspectRatio = `${number}/${number}` | 'auto';

@Component({
  selector: 'supy-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
  @Input() readonly src: string | SafeUrl;
  @Input() readonly alt?: string = '';
  @Input() readonly height?: ImageSize = 'auto';
  @Input() readonly width?: ImageSize = 'auto';
  @Input() readonly aspectRatio?: ImageAspectRatio = 'auto';
  @Input() readonly loading?: ImageLoading = 'lazy';
}
