import { finalize, takeUntil } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Navigation, Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { Destroyable } from '@supy/common';
import { CountdownDirective } from '@supy/components';

import { LoginEmailOtpRequest, LoginPhoneOtpRequest, LoginType, SendOtpRequest } from '../../core';
import { AuthService } from '../../services';
import { LoginError, LoginSuccess } from '../../store';

interface NavigationState {
  readonly type: LoginType;
  readonly otpValue: string;
}

@Component({
  selector: 'supy-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpComponent extends Destroyable {
  @ViewChild(CountdownDirective, { static: true }) readonly countdown: CountdownDirective;
  isLoading: boolean;
  readonly form: UntypedFormGroup;

  private readonly navigation: Navigation;
  readonly loginType: LoginType;
  readonly loginTypes = LoginType;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store,
  ) {
    super();
    this.navigation = this.router.getCurrentNavigation();
    this.loginType = this.navigation?.extras.state?.['type'] as LoginType;
    this.form = new UntypedFormGroup({
      code: new UntypedFormControl(null, [Validators.required, Validators.minLength(6)]),
    });
  }

  onSubmit(): void {
    const { code } = this.form.value as { code: string };

    if (this.form.valid) {
      this.signIn(code);
    }
  }

  signIn(code: string): void {
    if (this.isLoading) {
      return;
    }

    const state = this.navigation.extras.state as NavigationState;

    const body = { otp: code, [state.type]: state.otpValue } as unknown as LoginEmailOtpRequest | LoginPhoneOtpRequest;

    this.isLoading = true;

    this.authService
      .logIn(body)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.isLoading = false;
          this.cdr.detectChanges();
        }),
      )
      .subscribe({
        next: async () => {
          const { state } = this.navigation.extras;

          const returnUrl = (state?.returnUrl as string) ?? '/';

          this.store.dispatch(new LoginSuccess(this.loginType));

          await this.router.navigate([returnUrl], {
            queryParamsHandling: 'preserve',
          });
        },
        error: () => this.store.dispatch(new LoginError(this.loginType)),
      });
  }

  resendOtp(): void {
    const state = this.navigation.extras.state as NavigationState;

    const body = { [state.type]: state.otpValue } as unknown as SendOtpRequest;

    this.authService
      .sendOtp(body)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.countdown.reset());
  }
}
