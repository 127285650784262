import { get, isEqual } from 'lodash-es';

/**
 * Returns an array of rows that have been affected.
 *
 * @param originalList - The original list array.
 * @param updatedList - The updated list array.
 * @param rowIdentifier - the row identifier.
 * @param keys - specify keys to look into.
 * @returns An array of affected rows.
 */
export function getAffectedRows<T>({
  originalList,
  updatedList,
  rowIdentifier = 'id',
  keys = [],
}: AffectedRowsArgs<T>): T[] {
  const affectedRowsSet = new Set<T>();
  const updatedListMap = new Map<string, T>();

  updatedList.forEach(updatedItem => {
    updatedListMap.set(get(updatedItem, rowIdentifier) as string, updatedItem);
  });

  originalList.forEach(originalItem => {
    const updatedItem = updatedListMap.get(get(originalItem, rowIdentifier) as string);

    if (updatedItem) {
      if (keys.length) {
        keys.forEach(key => {
          if (!isEqual(get(originalItem, key), get(updatedItem, key))) {
            affectedRowsSet.add(updatedItem);
          }
        });
      } else {
        Object.keys(updatedItem).forEach(key => {
          if (
            get(originalItem, key) != null &&
            get(updatedItem, key) != null &&
            !isEqual(get(originalItem, key), get(updatedItem, key))
          ) {
            affectedRowsSet.add(updatedItem);
          }
        });
      }
    }
  });

  return Array.from(affectedRowsSet);
}

interface AffectedRowsArgs<T> {
  readonly originalList: T[];
  readonly keys?: Array<keyof T>;
  readonly rowIdentifier?: string;
  readonly updatedList: T[];
}
