<div class="supy-code-input">
  <code-input
    class="supy-code-input__input supy-code-input__input--{{ density }}"
    [code]="value"
    [initialFocusField]="0"
    [isCodeHidden]="isCodeHidden"
    [codeLength]="codeLength"
    (codeChanged)="onValueChange($event)"
    (codeCompleted)="onValueComplete($event)"
    (focusout)="onFocusOut($event)"
  >
  </code-input>
</div>
