import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IgxTooltipDirective } from '@infragistics/igniteui-angular';

@Component({
  selector: 'supy-tooltip-deprecated',
  templateUrl: './tooltip-deprecated.component.html',
  styleUrls: ['./tooltip-deprecated.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipDeprecatedComponent {
  @ViewChild(IgxTooltipDirective, { static: true })
  readonly tooltip: IgxTooltipDirective;

  @ViewChild('outlet', { static: true })
  readonly outlet: ElementRef<HTMLElement>;

  @Input() readonly hidden: boolean = false;
}
