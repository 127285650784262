<supy-select
  [name]="name"
  [placeholder]="placeholder"
  [value]="value"
  [clearable]="clearable"
  [loading]="loading"
  [density]="density"
  (selected)="selected.emit($event)"
  [disabled]="disabled"
>
  <supy-icon class="supy-language-select__icon" [size]="density" [name]="selectedLanguageFlag"></supy-icon>

  <supy-select-item *ngFor="let language of languages; trackBy: trackByValue" [value]="language.value"
    ><supy-icon *ngIf="language.icon" [name]="language.icon" [size]="density"></supy-icon>&nbsp;{{ language.label }}
  </supy-select-item>
</supy-select>
