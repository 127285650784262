import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';
import { Currency } from '@supy.api/dictionaries';

import { Destroyable, getPercentage, HexColor } from '@supy/common';

import { DialogComponent, IDialogComponent } from '../../../../../dialog';
import { DivergingChartData } from '../../core';

export interface ChartLegendItem {
  readonly label: string;
  readonly color: HexColor;
}

@Component({
  selector: 'supy-expanded-diverging-bar-chart',
  templateUrl: './expanded-diverging-bar-chart.component.html',
  styleUrls: ['./expanded-diverging-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandedDivergingBarChartComponent<T extends DivergingChartData>
  extends Destroyable
  implements IDialogComponent
{
  @Input() readonly customTitle: string;
  @Input() readonly subTitle: string;
  @Input() readonly percentageOf: 'location' | 'supplier' | 'category' | 'item';
  @Input() readonly isLoading: boolean;
  @Input() readonly currency: Currency;
  @Input() readonly canExport: boolean;
  @Input() readonly legendItems: ChartLegendItem[] = [];
  @Input() readonly colors: [HexColor, HexColor] = ['#009BCF', '#001A92'];
  @Input() readonly tooltipTemplate: TemplateRef<unknown>;
  @Input() set data(data: T[]) {
    this.#data = this.shouldTrim ? data.slice(0, 60) : data;
    this.allData = data;
  }

  get data(): T[] {
    return this.#data;
  }

  @Input() readonly isHorizontal: boolean;
  @Input() readonly isClickable: boolean;
  @Input() readonly fullBarHovering: boolean;
  @Input() readonly xAxisShow: boolean = true;
  @Input() readonly yAxisShow: boolean = true;
  @Input() readonly shouldTrim: boolean = false;
  @Input() readonly maxBars: number;

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();

  @Output() readonly barClicked = new EventEmitter<DivergingChartData>();
  @Output() readonly labelClicked = new EventEmitter<DivergingChartData>();
  @Output() readonly exportClicked = new EventEmitter<DivergingChartData[]>();

  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  protected readonly getPercentage = getPercentage;
  protected readonly svgWidth = window.innerWidth * 0.8;
  protected readonly svgHeight = window.innerHeight * 0.65;
  protected allData: T[];

  #data: T[];
  @Input() label = (d: DivergingChartData) => {
    const date = new Date(d.displayNames.date);

    return formatDate(date, 'dd/MM', 'en-GB');
  };

  constructor() {
    super();
  }

  onBarClick(event: DivergingChartData): void {
    this.barClicked.emit(event);
    this.closeDialog();
  }

  onLabelClick(event: DivergingChartData): void {
    this.labelClicked.emit(event);
    this.closeDialog();
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
