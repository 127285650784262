import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxListModule } from '@infragistics/igniteui-angular';

import { ListComponent, ListItemComponent } from './components';

@NgModule({
  declarations: [ListComponent, ListItemComponent],
  imports: [CommonModule, IgxListModule],
  exports: [ListComponent, ListItemComponent],
})
export class ListModule {}
