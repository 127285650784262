import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxDateRangePickerModule, IgxPickerActionsDirective } from '@infragistics/igniteui-angular';

import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { DateRangeComponent } from './components';

@NgModule({
  declarations: [DateRangeComponent],
  imports: [CommonModule, IgxDateRangePickerModule, IconModule, ButtonModule, IgxPickerActionsDirective],
  exports: [DateRangeComponent],
})
export class DateRangeModule {}
