<div class="supy-switch supy-switch--{{ size }}">
  <igx-switch
    [checked]="value"
    [disabled]="disabled"
    (change)="onValueChange($event)"
    (focusout)="onFocusOut($event)"
    [labelPosition]="labelPosition"
  >
    <ng-content></ng-content>
  </igx-switch>
</div>
