import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IgxTabsModule } from '@infragistics/igniteui-angular';

import { IconModule } from '../icon';
import { TabComponent, TabContentComponent, TabHeaderComponent, TabsComponent } from './components';

@NgModule({
  declarations: [TabsComponent, TabComponent, TabHeaderComponent, TabContentComponent],
  imports: [CommonModule, IgxTabsModule, RouterModule, IconModule],
  exports: [TabsComponent, TabComponent, TabHeaderComponent, TabContentComponent],
})
export class TabsModule {}
