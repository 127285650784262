import { PreferredType } from '../enums';
import { IdType, LocalizedData } from '../models';
import { ChannelState } from './channel.entity';
import { Supplier } from './supplier.entity';

export interface CreateSupplierBFFMetaData {
  readonly taxRegistrationNumber: string;
  readonly adHoc?: boolean;
  readonly warehouseId?: string;
}

export interface CreateSupplierBFFRequest {
  readonly name: LocalizedData | string;
  readonly displayName?: string;
  readonly country: IdType;
  readonly supplier?: IdType;
  readonly type?: CreateSupplierBFFType;
  readonly emails?: string[];
  readonly contactNumbers?: string[];
  readonly createChannels?: boolean;
  readonly comments?: string;
  readonly metadata: CreateSupplierBFFMetaData;
  readonly channels: SupplierChannelRequest[];
  readonly retailer?: IdType;
}

export interface SupplierChannelRequest {
  readonly id?: string;
  readonly branch: IdType;
  readonly paymentMethod?: PaymentMethod;
  readonly minimumOrderLimit?: number;
  readonly defaultHandlingFeePercentage?: number;
  readonly defaultTaxId?: string;
  readonly cutOffTime?: number;
  readonly creditPeriod?: SupplierCreditPeriod;
  readonly hideLpoPrices?: boolean;
  readonly hideLpoUnits?: boolean;
  readonly emails?: string[];
  readonly contactNumbers?: string[];
  readonly preferredType?: PreferredType;
  readonly state?: ChannelState;
}

export interface SupplierCreditPeriod {
  readonly type: CreditPeriodTypeEnum;
  readonly value?: number;
}

export interface CreateUpdateSupplierResponse extends Supplier {
  readonly invalidEmails?: string[];
}

export enum CreateSupplierBFFType {
  Supplier = 'supplier',
}

export enum PaymentMethod {
  Cash = 'cash',
  Cheque = 'cheque',
  Credit = 'credit',
}

export enum CreditPeriodTypeEnum {
  Net15 = 'net-15',
  Net30 = 'net-30',
  Net60 = 'net-60',
  MonthEnd = 'month-end',
  MonthEndPlus30 = 'month-end-plus-30',
  MonthEndPlus60 = 'month-end-plus-60',
  InvoiceDate = 'invoice-date',
  Custom = 'custom',
}
