<supy-dialog
  (dialogClosed)="onDialogClosed($event)"
  (dialogClosing)="onDialogClosing($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
  position="center"
  width="85vw"
  height="90vh"
  initialState="open"
>
  <supy-dialog-header class="expanded-diverging-bar-chart__dialog-header">
    <div class="expanded-diverging-bar-chart__header">
      <div>
        <h5 class="expanded-diverging-bar-chart__title">{{ customTitle }}</h5>
        <p class="expanded-diverging-bar-chart__subtitle" *ngIf="currency && !subTitle">In {{ currency }}</p>
        <p class="expanded-diverging-bar-chart__subtitle" *ngIf="subTitle">{{ subTitle }} in {{ currency }}</p>
      </div>
      <div class="expanded-diverging-bar-chart__header-icons">
        <div
          class="expanded-diverging-bar-chart__subheader__legend"
          [class.expanded-diverging-bar-chart__subheader__legend--vertical]="!isHorizontal"
        >
          <div class="expanded-diverging-bar-chart__subheader__legend-item" *ngFor="let legendItem of legendItems">
            <span
              class="expanded-diverging-bar-chart__subheader__legend-symbol"
              [style.background-color]="legendItem.color"
            ></span
            >{{ legendItem.label }}
          </div>
        </div>
        <supy-icon
          *ngIf="canExport"
          (click)="exportClicked.emit(allData)"
          name="document-download"
          color="primary"
        ></supy-icon>
        <supy-icon (click)="closeDialog()" name="close-circle" color="error"></supy-icon>
      </div>
    </div>
  </supy-dialog-header>
  <supy-dialog-content class="expanded-diverging-bar-chart__content">
    <ng-container [ngSwitch]="isHorizontal">
      <ng-container *ngSwitchCase="false">
        <supy-diverging-stacked-bar-vertical-chart
          class="expanded-diverging-bar-chart--vertical-chart"
          *ngIf="data.length"
          [data]="data"
          [isClickable]="isClickable"
          [svgHeight]="svgHeight"
          [svgWidth]="svgWidth"
          [colors]="colors"
          [showLabelTooltip]="true"
          [maxBars]="maxBars"
          [hasHorizontalScroll]="true"
          [label]="label"
          (barClick)="isClickable && onBarClick($event)"
          (labelClick)="isClickable && onLabelClick($event)"
        >
          <ng-template supyChartTooltip let-datum>
            <ng-container *ngIf="tooltipTemplate">
              <ng-container *ngTemplateOutlet="tooltipTemplate; context: { $implicit: datum }"></ng-container>
            </ng-container>
          </ng-template>
        </supy-diverging-stacked-bar-vertical-chart>
      </ng-container>
      <ng-container *ngSwitchCase="true">
        <supy-diverging-stacked-bar-chart
          *ngIf="data.length"
          [data]="data"
          [isClickable]="isClickable"
          [svgHeight]="svgHeight"
          [svgWidth]="svgWidth"
          [colors]="colors"
          [labelOffset]="30"
          [maxBars]="maxBars"
          [hasVerticalScroll]="true"
          (barClick)="isClickable && onBarClick($event)"
          (labelClick)="isClickable && onLabelClick($event)"
        >
          <ng-template supyChartTooltip let-datum>
            <ng-container *ngIf="tooltipTemplate">
              <ng-container *ngTemplateOutlet="tooltipTemplate; context: { $implicit: datum }"></ng-container>
            </ng-container>
          </ng-template>
        </supy-diverging-stacked-bar-chart>
      </ng-container>
    </ng-container>
  </supy-dialog-content>
</supy-dialog>
