import { Injectable } from '@angular/core';

import { LocalStorageService } from '@supy/common';

import { AuthUser } from '../../core';

const AUTH_USER_STORAGE_KEY = 'auth.user';

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService extends LocalStorageService {
  getAuthUser(): AuthUser | null {
    return this.getItem<AuthUser>(AUTH_USER_STORAGE_KEY);
  }

  setAuthUser(user: AuthUser): void {
    this.setItem(AUTH_USER_STORAGE_KEY, user);
  }

  removeAuthUser(): void {
    const keysToKeep = ['onboarding.'];

    Object.keys(this.storage).forEach(key => {
      if (!keysToKeep.some(keep => key.includes(keep))) {
        this.removeItem(key);
      }
    });
  }
}
