import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../button';
import { InputModule } from '../input';
import { InputWithButtonComponent } from './components';

@NgModule({
  declarations: [InputWithButtonComponent],
  imports: [CommonModule],
  exports: [InputWithButtonComponent, InputModule, ButtonModule],
})
export class InputWithButtonModule {}
