import { Retailer, ViewBranch } from './branch.entity';
import { OutletData } from './outlet.entity';
import { Role } from './roles.model';
import { Supplier } from './supplier.entity';
import { User } from './user.entity';

export class RetailerWithOutlets extends Retailer {
  outlets: OutletData[];
  centralKitchens: ViewBranch[];
}

export class UserWithBranches extends User {
  readonly retailers: RetailerWithOutlets[];
  readonly outletIds: string[];
  readonly branchIds: string[];
  readonly branches: ViewBranch[];
  readonly retailerIds: string[];
  readonly centralKitchenIds: string[];
  readonly centralKitchens: Supplier[];
  readonly roles: Role[];
  readonly 'roles.scope.type'?: never;
  readonly roleId?: string;

  constructor(args: Partial<UserWithBranches>) {
    super(args);
    this.centralKitchenIds = args.centralKitchenIds ?? [];
    this.centralKitchens = args.centralKitchens ?? [];
    this.retailers = args.retailers ?? [];
    this.outletIds = args.outletIds ?? [];
    this.branchIds = args.branchIds ?? [];
    this.branches = args.branches ?? [];
    this.retailerIds = args.retailerIds ?? [];
    this.roles = args.roles ?? [];
    this.roleId = args.roleId;
  }
}

export class UserWithRoleId extends UserWithBranches {
  readonly role: string;
}
