import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[supyFilterCellTemplate]',
})
export class FilterCellTemplateDirective {
  constructor(public template: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[supyGridCell]',
})
export class GridCellTemplateDirective {
  constructor(public template: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[supyGridCellEditor]',
})
export class GridCellEditorTemplateDirective {
  constructor(public template: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[supyGridCellHeader]',
})
export class GridCellHeaderTemplateDirective {
  constructor(public template: TemplateRef<unknown>) {}
}
