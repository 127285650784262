import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

type CodeInputDensity = 'small' | 'medium' | 'large';

@Component({
  selector: 'supy-code-input',
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeInputComponent implements ControlValueAccessor {
  @Input() readonly isCodeHidden?: boolean = false;
  @Input() readonly codeLength: number;
  @Input() readonly density: CodeInputDensity = 'medium';

  @Input() value: string;

  @Output() readonly focusOut = new EventEmitter<FocusEvent>();
  @Output() readonly valueChange = new EventEmitter<string>();
  @Output() readonly valueComplete = new EventEmitter<string>();

  onChange: (value: string) => void;

  onTouched: () => void;

  touched = false;

  disabled = false;

  constructor(
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  onValueChange(code: string): void {
    if (!this.disabled) {
      this.value = code;
      this.valueChange.emit(this.value);

      this.onChange?.(this.value);
    }
  }

  onValueComplete(code: string): void {
    if (!this.disabled) {
      this.valueComplete.emit(code);
    }
  }

  onFocusOut(e: FocusEvent): void {
    this.focusOut.emit(e);
    this.markAsTouched();
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
      this.emitTouchStatusChanged();
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
