<supy-dialog
  (dialogClosed)="onDialogClosed($event)"
  (dialogClosing)="onDialogClosing($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
  position="center"
  width="85vw"
  height="90vh"
  initialState="open"
>
  <supy-dialog-header class="expanded-purchased-value-breakdown__dialog-header">
    <div class="expanded-purchased-value-breakdown__header">
      <div>
        <h5 class="expanded-purchased-value-breakdown__title">{{ customTitle }}</h5>
        <p class="expanded-purchased-value-breakdown__subtitle" *ngIf="currency && !subTitle">in {{ currency }}</p>
        <p class="expanded-purchased-value-breakdown__subtitle" *ngIf="subTitle">{{ subTitle }} in {{ currency }}</p>
      </div>
      <div class="expanded-purchased-value-breakdown__header-icons">
        <ng-container *ngIf="headerTemplate">
          <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
        </ng-container>
        <supy-icon
          *ngIf="canExport"
          (click)="exportClicked.emit(allData)"
          name="document-download"
          color="primary"
        ></supy-icon>
        <supy-icon (click)="closeDialog()" name="close-circle" color="error"></supy-icon>
      </div>
    </div>
  </supy-dialog-header>
  <supy-dialog-content class="expanded-purchased-value-breakdown__content">
    <supy-loading-overlay *ngIf="isLoading"></supy-loading-overlay>
    <supy-stacked-bar-chart-d3
      *ngIf="data.length"
      [data]="data"
      [horizontal]="isHorizontal"
      [paddingInner]="0.2"
      [svgHeight]="svgHeight"
      [svgWidth]="svgWidth"
      [isBarClickable]="isBarClickable"
      [isLabelClickable]="isLabelClickable"
      [fullBarHovering]="fullBarHovering"
      [xAxisShow]="xAxisShow"
      [yAxisShow]="yAxisShow"
      [showTotals]="!shouldTrim"
      [colors]="colors"
      [colorShades]="colorShades"
      [hasVerticalScroll]="isHorizontal"
      [hasHorizontalScroll]="!isHorizontal"
      [maxBars]="maxBars"
      [withNegativity]="withNegativity"
      [showSeparateLabels]="showSeparateLabels"
      (barClick)="isBarClickable && onBarClick($event)"
      (labelClick)="isLabelClickable && onLabelClick($event)"
    >
      <ng-template supyChartTooltip let-datum>
        <ng-container *ngIf="tooltipTemplate; else defaultTooltipTemplate">
          <ng-container *ngTemplateOutlet="tooltipTemplate; context: { $implicit: datum }"></ng-container>
        </ng-container>
        <ng-template #defaultTooltipTemplate>
          <h5 class="expanded-purchased-value-breakdown__tooltip-title">
            {{ datum.title }}: {{ datum.value | number: '1.2-2' }}
          </h5>
          <p class="expanded-purchased-value-breakdown__tooltip-total">
            % of {{ percentageOf }} total: {{ getPercentage(datum.data.total, datum.value) | number: '1.2-2' }}%
          </p>
          <p class="expanded-purchased-value-breakdown__tooltip-total" *ngIf="shouldTrim">
            total: {{ datum.data.total | number: '1.2-2' }}
          </p>
        </ng-template>
      </ng-template>
    </supy-stacked-bar-chart-d3>
  </supy-dialog-content>
</supy-dialog>
