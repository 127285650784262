import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
  CodeInputModule,
  CountdownModule,
  IconModule,
  ImageModule,
  InputWithButtonModule,
  IntercomModule,
} from '@supy/components';
import { LayoutsModule } from '@supy/layouts';

import { AuthenticationComponent } from './authentication';
import { LoginWithEmailComponent, LoginWithPhoneComponent } from './login';
import { LogoutComponent } from './logout';
import { OtpComponent } from './otp';

@NgModule({
  declarations: [
    AuthenticationComponent,
    LogoutComponent,
    OtpComponent,
    LoginWithPhoneComponent,
    LoginWithEmailComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    InputWithButtonModule,
    CodeInputModule,
    IconModule,
    CountdownModule,
    IntercomModule,
    ImageModule,
    LayoutsModule,
  ],
})
export class AuthenticationComponentsModule {}
