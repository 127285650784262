import { first } from 'rxjs';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, ICellEditorParams } from '@ag-grid-community/core';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, inject, ViewChild } from '@angular/core';

import { TimePickerComponent } from '../../../time-picker';

export interface TimePickerCellEditorContext {
  readonly disabled?: boolean;
}

@Component({
  selector: 'supy-time-picker-cell-editor',
  template: `
    <div class="supy-time-picker-cell-editor__cell" (click)="$event.stopPropagation()">
      <supy-time-picker
        #focusableComponent
        [value]="value"
        [disabled]="context?.disabled"
        (valueChanged)="valueChangeHandler($event)"
        mode="dropdown"
        [elementRef]="documentElementRef"
      ></supy-time-picker>
    </div>
  `,
  styleUrls: ['./time-picker-cell-editor.component.scss'],
})
export class TimePickerCellEditorComponent implements ICellEditorAngularComp {
  @ViewChild('focusableComponent') private readonly focusableComponent: TimePickerComponent<Date>;

  protected context: TimePickerCellEditorContext;
  protected params: ICellEditorParams<unknown, number, TimePickerCellEditorContext>;
  protected value: number;

  private readonly document = inject(DOCUMENT);

  get documentElementRef(): ElementRef<HTMLElement> {
    return {
      nativeElement: this.document.body,
    };
  }

  agInit(params: ICellEditorParams<unknown, number, TimePickerCellEditorContext>): void {
    this.context = params.context;
    this.value = params.value;
    this.params = params;
  }

  getValue() {
    return this.value;
  }

  protected valueChangeHandler(value: number): void {
    this.value = value;
  }

  afterGuiAttached(_?: IAfterGuiAttachedParams): void {
    this.onStartEditing();
  }

  private onStartEditing(): void {
    if (this.focusableComponent) {
      this.focusableComponent?.open();

      this.focusableComponent.timePicker.closed.pipe(first()).subscribe(() => {
        this.params.stopEditing();
      });
    }
  }

  refresh(): boolean {
    return true;
  }
}
