import { takeUntil } from 'rxjs';
import { Injectable, Optional, Type, ViewContainerRef } from '@angular/core';

import { IDialogComponent } from '../interfaces';

@Injectable()
export class DialogService {
  constructor(@Optional() private readonly viewContainerRef?: ViewContainerRef) {}

  openDialog<TDialog extends IDialogComponent>(
    component: Type<TDialog>,
    inputs: Partial<TDialog> = {},
    viewContainerRef?: ViewContainerRef,
  ): TDialog {
    const viewContainer = this.viewContainerRef ?? viewContainerRef;
    const componentRef = viewContainer.createComponent(component);

    const { instance, changeDetectorRef } = componentRef;

    instance.dialogClosed.pipe(takeUntil(instance.destroyed$)).subscribe(() => {
      const index = viewContainer.indexOf(componentRef.hostView);

      viewContainer.get(index).destroy();
    });

    Object.assign(instance, inputs);

    changeDetectorRef.detectChanges();

    instance.openDialog();

    return instance;
  }

  closeDialog<TDialog extends IDialogComponent>(instance: TDialog): void {
    instance.closeDialog();
  }
}
