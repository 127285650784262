import { Pipe, PipeTransform } from '@angular/core';

import { getNestedValue } from '../helpers';

@Pipe({
  name: 'match',
})
export class MatchPipe implements PipeTransform {
  transform<T extends object>(values: T[], key: string, search: string): T[] {
    const regex = new RegExp(search, 'i');

    return values.filter(el => regex.test(getNestedValue<T, string>(el, key)));
  }
}
