import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IChangeCheckboxEventArgs, LabelPosition } from '@infragistics/igniteui-angular';

export type SwitchSize = 'normal' | 'small';

@Component({
  selector: 'supy-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss', './switch-normal.component.scss', './switch-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent implements ControlValueAccessor {
  constructor(private readonly cdr: ChangeDetectorRef) {}

  @Input() readonly type: string;
  @Input() readonly size: SwitchSize = 'normal';
  @Input() @HostBinding('attr.name') readonly name: string;
  @Input() readonly labelPosition: LabelPosition = 'after';
  @Input() set value(checked: boolean) {
    this._value = checked;
    this.cdr.detectChanges();
  }

  get value(): boolean {
    return this._value;
  }

  @Input() set disabled(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Output() readonly valueChange = new EventEmitter<boolean>();

  onChange: (value: boolean) => void;

  onTouched: () => void;

  touched = false;
  private _value: boolean;
  private _disabled = false;

  onValueChange(event: IChangeCheckboxEventArgs): void {
    if (!this.disabled) {
      this._value = event.checked;
      this.valueChange.emit(this.value);

      this.onChange?.(this.value);
    }
  }

  onFocusOut(e: FocusEvent): void {
    this.markAsTouched();
  }

  writeValue(value: boolean): void {
    this._value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: (value: boolean) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
