import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingBarModule as AngularLoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { LoadingBarComponent } from './components';

@NgModule({
  declarations: [LoadingBarComponent],
  imports: [CommonModule, LoadingBarRouterModule, AngularLoadingBarModule],
  exports: [LoadingBarComponent],
})
export class LoadingBarModule {}
