import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxDialogModule, IgxToggleModule } from '@infragistics/igniteui-angular';

import { ButtonModule } from '../button';
import { CheckboxModule } from '../checkbox';
import { IconModule } from '../icon';
import {
  ConfirmDialogComponent,
  ConfirmDialogWithIconComponent,
  DialogCloseComponent,
  DialogComponent,
  DialogContentComponent,
  DialogFooterComponent,
  DialogHeaderComponent,
} from './components';

@NgModule({
  declarations: [
    DialogComponent,
    DialogContentComponent,
    DialogFooterComponent,
    DialogHeaderComponent,
    DialogCloseComponent,
    ConfirmDialogComponent,
    ConfirmDialogWithIconComponent,
  ],
  imports: [CommonModule, IgxDialogModule, ButtonModule, IgxToggleModule, IconModule, CheckboxModule],
  exports: [
    DialogComponent,
    DialogContentComponent,
    DialogFooterComponent,
    DialogHeaderComponent,
    DialogCloseComponent,
    ConfirmDialogComponent,
  ],
})
export class DialogModule {}
