import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../icon';
import { TabsModule } from '../tabs';
import { TabViewSelectComponent } from './components';

@NgModule({
  declarations: [TabViewSelectComponent],
  imports: [CommonModule, TabsModule, IconModule],
  exports: [TabViewSelectComponent],
})
export class TabViewSelectModule {}
