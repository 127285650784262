import { finalize, takeUntil } from 'rxjs';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ChangeDetectorRef, Directive } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Navigation, Router } from '@angular/router';

import { Destroyable, ReadyState, readyState } from '@supy/common';

import { AuthParams } from '../../config';
import { LoginType, SendOtpRequest } from '../../core';
import { AuthService } from '../../services';

@Directive()
export abstract class LoginComponent extends Destroyable {
  abstract readonly type: LoginType;
  abstract readonly form: UntypedFormGroup;
  abstract readonly navigation: Navigation;

  protected abstract readonly authService: AuthService;
  protected abstract readonly router: Router;
  protected abstract readonly route: ActivatedRoute;
  protected abstract readonly cdr: ChangeDetectorRef;

  protected readonly authParams: AuthParams;

  readonly state = new ReadyState();

  onSubmit(): void {
    const { input } = this.form.value as { input: string };

    if (this.form.valid) {
      this.signIn(input);
    }
  }

  signIn(input: string): void {
    const body = { [this.type]: input } as Record<LoginType, string> as SendOtpRequest;

    this.authService
      .sendOtp(body)
      .pipe(
        takeUntil(this.destroyed$),
        readyState(this.state),
        finalize(() => {
          this.cdr.markForCheck();
        }),
      )
      .subscribe({
        next: async () => {
          await this.redirectToOtp(input);
        },
        error: async (e: HttpErrorResponse) => {
          if ((e.status as HttpStatusCode) === HttpStatusCode.Conflict) {
            await this.redirectToOtp(input);
          } else {
            throw e;
          }
        },
      });
  }

  private async redirectToOtp(value: string) {
    const { state } = this.navigation?.extras ?? {};

    await this.router.navigate(['../../otp'], {
      relativeTo: this.route,
      queryParamsHandling: 'preserve',
      state: {
        ...state,
        otpValue: value,
        type: this.type,
        returnUrl: this.authParams.returnUrl,
      },
    });
  }
}
