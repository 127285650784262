<div class="supy-confirm-dialog">
  <supy-dialog
    position="center"
    [width]="width"
    initialState="open"
    [closeOnEscape]="false"
    (dialogClosed)="onDialogClosed($event)"
    (dialogClosing)="onDialogClosing($event)"
    (dialogOpened)="onDialogOpened($event)"
    (dialogOpening)="onDialogOpening($event)"
  >
    <supy-dialog-header backgroundImage="assets/images/supy-dialog-header-2.svg"></supy-dialog-header>
    <supy-dialog-content>
      <ng-container *ngIf="title">
        <h3 class="supy-confirm-dialog__content">{{ title }}</h3>
      </ng-container>
      <ng-container *ngIf="!title">
        <ng-content></ng-content>
      </ng-container>
    </supy-dialog-content>
    <supy-dialog-footer>
      <supy-button (buttonClick)="onCloseClick()" name="close-dialog" [color]="closeButtonColor">{{
        closeText
      }}</supy-button>
    </supy-dialog-footer>
  </supy-dialog>
</div>
