import { firstValueFrom } from 'rxjs';
import { IMutableContext } from 'unleash-proxy-client';
import { ActivatedRouteSnapshot } from '@angular/router';

import { UnleashService } from '../unleash.service';
import { UnleashGuardContext, UnleashGuardRouteData } from '../unleash-route';

export abstract class BaseUnleashGuard {
  protected abstract unleashService: UnleashService;

  async updateContext(context: IMutableContext): Promise<void> {
    await this.unleashService.updateContext({ ...context });
  }

  async activate(toggles: string[]): Promise<boolean> {
    const enabledToggles = await firstValueFrom(this.unleashService.enabledTogglesChanges$);

    return toggles.some(toggle => enabledToggles.includes(toggle));
  }

  protected getContext(route: ActivatedRouteSnapshot): UnleashGuardContext {
    const { unleashContext } = route.data as UnleashGuardRouteData;

    if (!unleashContext?.toggles.length) {
      throw new Error(`Toggles array cannot be empty in route data`);
    }

    return unleashContext;
  }
}
