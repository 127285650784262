import { SpanOptions } from '@opentelemetry/api';

import { AnyFunction, spanContextProvider, wrap } from './trace.utils';

export function Span(name?: string, options?: SpanOptions): MethodDecorator {
  return (<T extends AnyFunction>(
    target: object,
    property: string | symbol,
    descriptor: TypedPropertyDescriptor<T>,
  ): TypedPropertyDescriptor<T> => {
    const original = (target as Record<string | symbol, T>)[property];

    return {
      ...descriptor,
      value: wrap(original, spanContextProvider(name ?? original.name, options)),
    };
  }) as MethodDecorator;
}
