import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';
import { Currency } from '@supy.api/dictionaries';

import { Destroyable, HexColor } from '@supy/common';

import { DialogComponent, IDialogComponent } from '../../../../../dialog';
import { BubbleChartData } from '../bubble-chart';
import { ChartLegendItem } from '../expanded-diverging-bar-chart';

@Component({
  selector: 'supy-expanded-bubble-chart',
  templateUrl: './expanded-bubble-chart.component.html',
  styleUrls: ['./expanded-bubble-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandedBubbleChartComponent<T extends BubbleChartData> extends Destroyable implements IDialogComponent {
  @Input() readonly customTitle: string;
  @Input() readonly subTitle: string;
  @Input() readonly currency: Currency;
  @Input() readonly data: T[];
  @Input() readonly tooltipTemplate: TemplateRef<unknown>;
  @Input() readonly legendItems: ChartLegendItem[] = [];
  @Input() readonly canExport: boolean;
  @Input() readonly keys: string[] = [];
  @Input() readonly maxBars: number;
  @Input() readonly colorsMap: Map<string, HexColor> = new Map();

  @Output() readonly exportClicked = new EventEmitter<void>();
  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();

  @ViewChild(DialogComponent, { static: true }) private readonly dialog: DialogComponent;

  protected readonly svgWidth = window.innerWidth * 0.8;
  protected readonly svgHeight = window.innerHeight * 0.65;
  @Input() readonly categoryFn = (d: BubbleChartData) => d.categoryName;

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
