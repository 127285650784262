import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'supyPrecision',
})
export class PrecisionPipe implements PipeTransform {
  transform(value: string | number, precision = 3, thousandsWithComma = false): string {
    const numberWithPrecision = Number(value).toFixed(precision);

    return thousandsWithComma ? numberWithCommas(numberWithPrecision) : numberWithPrecision;
  }
}

function numberWithCommas(x: number | string) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}
