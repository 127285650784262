<ng-container *ngIf="src; else igxAvatar">
  <div
    class="supy-avatar supy-avatar--{{ size }} supy-avatar--{{ color }} supy-avatar__image-wrapper"
    [class.supy-avatar--square]="!roundShape"
    [class.supy-avatar--circle]="roundShape"
  >
    <img [src]="src" crossorigin="anonymous" alt="avatar {{ initials | uppercase }}" class="supy-avatar__image" />
  </div>
</ng-container>
<ng-template #igxAvatar>
  <igx-avatar
    class="supy-avatar supy-avatar--{{ size }} supy-avatar--{{ color }}"
    [class.supy-avatar--square]="!roundShape"
    [initials]="initials | uppercase"
    [src]="src"
    [icon]="icon"
    [shape]="shape"
  >
    <ng-content></ng-content>
  </igx-avatar>
</ng-template>
