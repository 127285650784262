<supy-input
  class="supy-input-search"
  suffix="search"
  [suffixColor]="suffixColor"
  type="search"
  [value]="value"
  [name]="name"
  [placeholder]="placeholder"
  (valueChange)="onValueChange($event)"
></supy-input>
