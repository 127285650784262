import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[supyGridDetail]',
})
export class GridDetailTemplateDirective {
  constructor(readonly template: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[supyGridRowSelector]',
})
export class GridRowSelectorTemplateDirective {
  constructor(readonly template: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[supyGridContextMenu]',
})
export class GridContextMenuTemplateDirective {
  constructor(readonly template: TemplateRef<unknown>) {}
}
