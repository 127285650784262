import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function sheetLink(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;
    const regex = /\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/;

    if (!value) {
      return null;
    }

    if (regex.test(value)) {
      return null;
    }

    return { sheetLink: true };
  };
}
