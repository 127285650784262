import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const allowedSpecialChars = `.,()'"_+\\-–—*/%&`;
const allowedForEnglish = `[\\w\\s${allowedSpecialChars}]`;
const arabicLetters = `[\\u0600-\\u06FF]`;
const greekLetters = `[\\u0370-\\u03FF\\u1f00-\\u1fff]`;

const languagesRegexMap: Record<string, RegExp> = {
  en: new RegExp(`^${allowedForEnglish}+$`),
  ar: new RegExp(`^${allowedForEnglish}*${arabicLetters}(${arabicLetters}|${allowedForEnglish})*$`),
  gr: new RegExp(`^${allowedForEnglish}*${greekLetters}(${greekLetters}|${allowedForEnglish})*$`),
};

export type AvailableLanguage = 'en' | 'ar' | 'gr';

export function en(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;

    if (!value) {
      return null;
    }

    //FIXME: move Greek into separate localised field
    if (languagesRegexMap['en'].test(value) || languagesRegexMap['gr'].test(value)) {
      return null;
    }

    return { en: true };
  };
}

export function ar(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;

    if (!value?.length) {
      return null;
    }

    if (languagesRegexMap['ar'].test(value)) {
      return null;
    }

    return { ar: true };
  };
}

export function gr(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;

    if (!value) {
      return null;
    }

    if (languagesRegexMap['gr'].test(value)) {
      return null;
    }

    return { gr: true };
  };
}

export function isValidLanguage(value: string, language: AvailableLanguage): boolean {
  return languagesRegexMap[language].test(value);
}
