import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  AutoPositionStrategy,
  DateRange,
  IgxDateRangePickerComponent,
  OverlaySettings,
} from '@infragistics/igniteui-angular';
import { swingInTopFwd } from '@infragistics/igniteui-angular/animations';
import { IBaseCancelableBrowserEventArgs } from '@infragistics/igniteui-angular/lib/core/utils';

import { InputDensity } from '../../../input';
import { getDefaultRange, getLastMonths, getLastWeeks, getPreviousQuarters } from './date-range.helpers';
import { DEFAULT_DATE_RANGE_DISPLAY_MONTHS_COUNT, PredefinedRange, PredefinedRangeBy } from './date-range.types';

@Component({
  selector: 'supy-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeComponent implements AfterViewInit, ControlValueAccessor {
  @ViewChild('datePicker', { static: true }) readonly datePicker: IgxDateRangePickerComponent;

  @Output() readonly valueChanged = new EventEmitter<DateRange>();
  @Output() readonly closed = new EventEmitter<DateRange>();
  @Input() readonly label: string = 'Date';
  @Input() readonly hideOutsideDays: boolean = false;
  @Input() readonly inputFormat: string = 'dd.MM.yy';
  @Input() readonly displayFormat: string = 'dd.MM.yy';
  @Input() readonly placeholder: string = 'Date';
  @Input() @HostBinding('attr.name') readonly name: string;
  @Input() readonly predefinedRanges: PredefinedRange[] = getDefaultRange();
  @Input() readonly displayMonthsCount: number = DEFAULT_DATE_RANGE_DISPLAY_MONTHS_COUNT;

  @Input() readonly density: InputDensity = 'small';
  @Input() readonly variant: 'border' | 'flat' = 'border';

  @Input() value: DateRange;
  onChange: (value: DateRange) => void;

  onTouched: () => void;

  readonly PredefinedRangeBy = PredefinedRangeBy;

  overlaySettings: OverlaySettings;

  touched = false;

  @Input() disabled = false;

  #value: DateRange;
  #doneClicked: boolean;

  get isCollapsed(): boolean {
    return this.datePicker?.collapsed;
  }

  constructor(
    readonly element: ElementRef,
    private readonly cdr: ChangeDetectorRef,
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    this.overlaySettings = {
      outlet: this.element,
      positionStrategy: new AutoPositionStrategy({
        openAnimation: swingInTopFwd,
      }),
    };
  }

  onSelectRange(predefinedRange: PredefinedRange): void {
    let today: Date = new Date();

    let start: Date;

    switch (predefinedRange.by) {
      case PredefinedRangeBy.Days:
        start = new Date(new Date().setDate(today.getDate() - predefinedRange.range + 1));
        break;
      case PredefinedRangeBy.Weeks:
        if (predefinedRange.previousRange) {
          const range = getLastWeeks(predefinedRange.range);

          today = range.end as Date;
          start = range.start as Date;
        } else {
          start = new Date(new Date().setDate(today.getDate() - predefinedRange.range * 7 + 1));
        }
        break;

      case PredefinedRangeBy.Months:
        if (predefinedRange.previousRange) {
          const range = getLastMonths(predefinedRange.range);

          today = range.end as Date;
          start = range.start as Date;
        } else {
          start = new Date(new Date().setMonth(today.getMonth() - predefinedRange.range));
        }
        break;
      case PredefinedRangeBy.Quarter:
        {
          const range = getPreviousQuarters(predefinedRange.previousRange ? 1 : 0);

          start = range.start as Date;

          if (today > range.end) {
            today = range.end as Date;
          }
        }
        break;
      case PredefinedRangeBy.Years:
        start = new Date(new Date().setFullYear(today.getFullYear() - predefinedRange.range));
        break;
    }

    this.writeValue({ start, end: today });

    // Wait for value to update correctly
    setTimeout(() => this.onDone(), 500);
  }

  onValueChange(event: DateRange): void {
    // prepare value to submit but do nothing until user clicks "Done"
    this.#value = {
      start: event?.start ? new Date(new Date(event.start).setHours(0, 0, 0, 0)) : null,
      end: event?.end ? new Date(new Date(event.end).setHours(23, 59, 59, 999)) : null,
    };
  }

  onClosingPicker(event: IBaseCancelableBrowserEventArgs) {
    if (!this.#doneClicked && !event.event) {
      // We keep picker open until user clicks "Done"
      event.cancel = true;
    }
  }

  onDone(): void {
    // We emit changes only when user clicks Done button
    this.#doneClicked = true;
    this.datePicker.close();
    this.closed.emit(this.#value);

    this.onChange?.(this.#value);
    this.valueChanged.emit(this.#value);
    this.markAsTouched();
    this.#doneClicked = false;
  }

  writeValue(value: DateRange): void {
    this.value = value;

    if (!value) {
      this.datePicker.writeValue(value);
    }

    setTimeout(() => {
      this.cdr.markForCheck();
    }, 0);
  }

  registerOnChange(onChange: (value: DateRange) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
      this.emitTouchStatusChanged();
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.cdr.markForCheck();
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
