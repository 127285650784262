<igx-combo
  class="supy-combo-box {{ variant }} {{ density }} supy-input--compact"
  [attr.data-name]="name"
  [class.supy-combo-box--small]="smallSize"
  [class.supy-combo-box--disabled]="disabled"
  [class.selected]="!!value?.length"
  [class.supy-combo-box--write-only]="!clearable"
  [data]="list"
  [type]="type"
  [disabled]="disabled"
  [displayKey]="displayKey"
  [valueKey]="valueKey"
  [overlaySettings]="overlaySettings"
  [placeholder]="placeholder"
  [searchPlaceholder]="searchPlaceholder"
  [filterable]="filterable"
  [itemsMaxHeight]="comboBoxMaxHeight"
  [itemHeight]="itemHeight"
  [itemsWidth]="itemWidth"
  [allowCustomValues]="allowCustomValues"
  [ngModel]="value"
  (opening)="onOpening($event)"
  (closing)="onClosing($event)"
  (searchInputUpdate)="onSearchInputUpdate($event)"
  (selectionChanging)="onSelectionChanging($event)"
>
  <div>
    <ng-template *ngIf="multiple && list?.length" igxComboHeader>
      <label [class.supy-combo-box__item--selected]="selectedAll" class="supy-combo-box__header">
        <div class="supy-combo-box__select-all" *ngIf="selectAllAllowed">
          <supy-checkbox
            class="supy-combo-box__select-all--checkbox"
            (checkedChange)="onSelectAll($event)"
            [checked]="selectedAll"
            name="selectAll"
          >
          </supy-checkbox>
          <span class="supy-combo-box__select-all--text">{{ selectAllText }}</span>
        </div>
      </label>
    </ng-template>
    <ng-template igxComboItem let-display let-key="valueKey" let-index="index">
      <ng-container *ngIf="itemDirective?.template; else item">
        <ng-container
          *ngTemplateOutlet="
            itemDirective.template;
            context: { $implicit: display, key: valueKey, display: displayKey, index: this.index }
          "
        ></ng-container>
      </ng-container>
      <ng-template #item>
        <div class="supy-combo-box__item">
          <supy-icon *ngIf="iconKey" [name]="display[iconKey]" size="small"></supy-icon>
          <div class="supy-combo-box__item-content">
            <span
              class="supy-combo-box__title-wrapper"
              [class.supy-combo-box__marquee]="display[titleKey]?.length > 50 && enableMarqueeEffect"
            >
              <span class="supy-combo-box__title">{{ display | nestedValue: titleKey }}</span>
            </span>
            <span class="supy-combo-box__subtitle" *ngIf="subtitleKey">
              {{ display | nestedValue: subtitleKey }}
              <span class="supy-combo-box__subtitle" *ngIf="display | nestedValue: pipeKey">
                | {{ display | nestedValue: pipeKey }}
              </span>
            </span>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </div>
  <ng-template igxComboToggleIcon let-collapsed>
    <supy-icon name="arrow-circle-down" size="small"></supy-icon>
  </ng-template>
  <ng-template igxComboClearIcon>
    <supy-icon *ngIf="clearable" name="close-circle" size="small" [color]="disabled ? null : 'error'"></supy-icon>
  </ng-template>
  <ng-template igxComboFooter>
    <supy-loading-overlay *ngIf="isLoading; else footerTemplate" color="primary"></supy-loading-overlay>
    <ng-template #footerTemplate>
      <div *ngIf="footerDirective?.template" class="supy-combo-box__footer">
        <ng-container *ngTemplateOutlet="footerDirective.template"></ng-container>
      </div>
    </ng-template>
  </ng-template>
  <ng-template *ngIf="emptyDirective?.template" igxComboEmpty>
    <ng-container>
      <ng-container *ngTemplateOutlet="emptyDirective.template"></ng-container>
    </ng-container>
  </ng-template>
</igx-combo>
