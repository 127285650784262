<supy-loading-bar></supy-loading-bar>
<aside class="supy-aside">
  <supy-side-nav></supy-side-nav>
</aside>
<div class="supy-main-wrapper">
  <main id="main" class="supy-main" cdkScrollable>
    <header class="supy-header">
      <ng-content select="[header]"></ng-content>
    </header>
    <ng-content select="[content]"></ng-content>
  </main>
</div>
