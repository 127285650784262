"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AfricaTimezone = void 0;
var AfricaTimezone;
(function (AfricaTimezone) {
  AfricaTimezone["Abidjan"] = "Africa/Abidjan";
  AfricaTimezone["Accra"] = "Africa/Accra";
  AfricaTimezone["AddisAbaba"] = "Africa/Addis_Ababa";
  AfricaTimezone["Algiers"] = "Africa/Algiers";
  AfricaTimezone["Asmara"] = "Africa/Asmara";
  AfricaTimezone["Bamako"] = "Africa/Bamako";
  AfricaTimezone["Bangui"] = "Africa/Bangui";
  AfricaTimezone["Banjul"] = "Africa/Banjul";
  AfricaTimezone["Bissau"] = "Africa/Bissau";
  AfricaTimezone["Blantyre"] = "Africa/Blantyre";
  AfricaTimezone["Brazzaville"] = "Africa/Brazzaville";
  AfricaTimezone["Bujumbura"] = "Africa/Bujumbura";
  AfricaTimezone["Cairo"] = "Africa/Cairo";
  AfricaTimezone["Casablanca"] = "Africa/Casablanca";
  AfricaTimezone["Ceuta"] = "Africa/Ceuta";
  AfricaTimezone["Conakry"] = "Africa/Conakry";
  AfricaTimezone["Dakar"] = "Africa/Dakar";
  AfricaTimezone["DarEsSalaam"] = "Africa/Dar_es_Salaam";
  AfricaTimezone["Djibouti"] = "Africa/Djibouti";
  AfricaTimezone["Douala"] = "Africa/Douala";
  AfricaTimezone["ElAaiun"] = "Africa/El_Aaiun";
  AfricaTimezone["Freetown"] = "Africa/Freetown";
  AfricaTimezone["Gaborone"] = "Africa/Gaborone";
  AfricaTimezone["Harare"] = "Africa/Harare";
  AfricaTimezone["Johannesburg"] = "Africa/Johannesburg";
  AfricaTimezone["Juba"] = "Africa/Juba";
  AfricaTimezone["Kampala"] = "Africa/Kampala";
  AfricaTimezone["Khartoum"] = "Africa/Khartoum";
  AfricaTimezone["Kigali"] = "Africa/Kigali";
  AfricaTimezone["Kinshasa"] = "Africa/Kinshasa";
  AfricaTimezone["Lagos"] = "Africa/Lagos";
  AfricaTimezone["Libreville"] = "Africa/Libreville";
  AfricaTimezone["Lome"] = "Africa/Lome";
  AfricaTimezone["Luanda"] = "Africa/Luanda";
  AfricaTimezone["Lubumbashi"] = "Africa/Lubumbashi";
  AfricaTimezone["Lusaka"] = "Africa/Lusaka";
  AfricaTimezone["Malabo"] = "Africa/Malabo";
  AfricaTimezone["Maputo"] = "Africa/Maputo";
  AfricaTimezone["Maseru"] = "Africa/Maseru";
  AfricaTimezone["Mbabane"] = "Africa/Mbabane";
  AfricaTimezone["Mogadishu"] = "Africa/Mogadishu";
  AfricaTimezone["Monrovia"] = "Africa/Monrovia";
  AfricaTimezone["Nairobi"] = "Africa/Nairobi";
  AfricaTimezone["Ndjamena"] = "Africa/Ndjamena";
  AfricaTimezone["Niamey"] = "Africa/Niamey";
  AfricaTimezone["Nouakchott"] = "Africa/Nouakchott";
  AfricaTimezone["Ouagadougou"] = "Africa/Ouagadougou";
  AfricaTimezone["PortoNovo"] = "Africa/Porto-Novo";
  AfricaTimezone["SaoTome"] = "Africa/Sao_Tome";
  AfricaTimezone["Tripoli"] = "Africa/Tripoli";
  AfricaTimezone["Tunis"] = "Africa/Tunis";
  AfricaTimezone["Windhoek"] = "Africa/Windhoek";
})(AfricaTimezone = exports.AfricaTimezone || (exports.AfricaTimezone = {}));
