import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function time24h(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string | Date;

    if (!value) {
      return null;
    }

    if (value instanceof Date) {
      return null;
    }

    if (typeof value === 'string') {
      const regex = /^(([0-1]?[0-9])|(2[0-3]))(:[0-5][0-9]){1,2}$/;

      if (regex.test(value)) {
        return null;
      }
    }

    return { time: true };
  };
}
