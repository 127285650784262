import { HorizontalAlignment, PositionSettings, VerticalAlignment } from '@infragistics/igniteui-angular';

export type MenuPosition = 'top-left' | 'top' | 'top-right' | 'bottom-left' | 'bottom' | 'bottom-right';

export const menuPositionSettings: Record<MenuPosition, PositionSettings> = {
  'top-left': {
    horizontalDirection: HorizontalAlignment.Left,
    horizontalStartPoint: HorizontalAlignment.Right,
    verticalStartPoint: VerticalAlignment.Top,
    openAnimation: null,
    closeAnimation: null,
  },
  top: {
    horizontalDirection: HorizontalAlignment.Center,
    horizontalStartPoint: HorizontalAlignment.Center,
    verticalStartPoint: VerticalAlignment.Top,
    openAnimation: null,
    closeAnimation: null,
  },
  'top-right': {
    horizontalDirection: HorizontalAlignment.Right,
    horizontalStartPoint: HorizontalAlignment.Left,
    verticalStartPoint: VerticalAlignment.Top,
    openAnimation: null,
    closeAnimation: null,
  },
  'bottom-left': {
    horizontalDirection: HorizontalAlignment.Left,
    horizontalStartPoint: HorizontalAlignment.Right,
    verticalStartPoint: VerticalAlignment.Bottom,
    verticalDirection: VerticalAlignment.Bottom,
    openAnimation: null,
    closeAnimation: null,
  },
  bottom: {
    horizontalDirection: HorizontalAlignment.Center,
    horizontalStartPoint: HorizontalAlignment.Center,
    verticalStartPoint: VerticalAlignment.Bottom,
    verticalDirection: VerticalAlignment.Bottom,
    openAnimation: null,
    closeAnimation: null,
  },
  'bottom-right': {
    horizontalDirection: HorizontalAlignment.Right,
    horizontalStartPoint: HorizontalAlignment.Left,
    verticalStartPoint: VerticalAlignment.Bottom,
    verticalDirection: VerticalAlignment.Bottom,
    openAnimation: null,
    closeAnimation: null,
  },
} as const;
