import * as d3 from 'd3';
import { ScaleOrdinal } from 'd3';
import invert from 'invert-color';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { Destroyable } from '@supy/common';

import { ChartTooltipDirective, TooltipConfig } from '../../../chart-core';
import { TreemapColor, TreemapInput } from '../../treemap.interfaces';

export class TreemapTilingMethod {
  binary = d3.treemapBinary;
  squarify = d3.treemapSquarify;
  sliceDice = d3.treemapSliceDice;
  slice = d3.treemapSlice;
  dice = d3.treemapDice;
}

const TILING_METHOD = new TreemapTilingMethod();
const MIN_TOOLTIP_WIDTH = 300;

@Component({
  selector: 'supy-treemap',
  templateUrl: './treemap.component.html',
  styleUrls: ['./treemap.component.scss'],
})
export class TreemapComponent<T extends TreemapInput = TreemapInput>
  extends Destroyable
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() readonly data: T;
  @Input() set colors(colors: TreemapColor[]) {
    this.#colorsMap = new Map<string, string>(colors.map(({ key, value }) => [key, value]));
  }

  @Input() readonly isDisabled: boolean;
  @Input() readonly isClickable: boolean;
  @Input() readonly tilingMethod: keyof TreemapTilingMethod = 'squarify';
  @Input() readonly sectionsMap: Map<string, string>;
  @Input() @HostBinding('style.height.px') readonly svgHeight: number;
  @Input() @HostBinding('style.width.px') readonly svgWidth: number;

  @ContentChild(ChartTooltipDirective)
  protected readonly tooltipTemplate: ChartTooltipDirective;

  protected readonly tooltipConfig: TooltipConfig<T> = {
    data: null,
    positioning: {},
  };

  private readonly hostElement: HTMLElement; // Native element hosting the SVG container
  private rootDiv: d3.Selection<HTMLDivElement, unknown, null, unknown>; // Top level DIV element
  private treemap: d3.TreemapLayout<TreemapInput>;

  /**
   * D3 color provider that is used to map data to colors dynamically
   *
   * @param colorScale colors scale method
   */
  private colorScale: ScaleOrdinal<string, unknown>;
  private width: number;
  private height: number;
  private readonly format = d3.format(',d');
  private readonly margin = { top: 0, right: 0, bottom: 0, left: 0 };
  #colorsMap: Map<string, string> = new Map();
  constructor(
    private readonly elRef: ElementRef<HTMLElement>,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
    this.hostElement = this.elRef.nativeElement;
    this.createChart();
  }

  ngOnInit(): void {
    this.createChart();
  }

  ngAfterViewInit(): void {
    this.createChart();
  }

  ngOnChanges(_: SimpleChanges): void {
    this.createChart();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.removeExistingChartFromParent();
  }

  @HostListener('scroll')
  protected onScroll(): void {
    if (this.tooltipConfig.data) {
      this.tooltipConfig.data = null;
      this.cdr.detectChanges();
    }
  }

  private createChart(): void {
    // FIXME: render new data without removing existing root element from DOM
    this.removeExistingChartFromParent();

    if (!this.data) {
      return;
    }

    this.setScales();

    this.rootDiv = d3
      .select(this.hostElement)
      .append('div')
      .style('position', 'relative')
      .style('width', `${this.width + this.margin.left + this.margin.right}px`)
      .style('height', `${this.height + this.margin.top + this.margin.bottom}px`)
      .style('left', `${this.margin.left}px`)
      .style('top', `${this.margin.top}px`);

    this.setDomains();
    this.renderData();

    this.addBarEventListeners();
  }

  private removeExistingChartFromParent(): void {
    // !!!!Caution!!!
    // Make sure not to do;
    //     d3.select('div').remove();
    // That will clear all other DIV elements in the DOM
    d3.select(this.hostElement).select('div').remove();
  }

  private setScales(): void {
    this.setSizes();

    this.colorScale = d3.scaleOrdinal().range(
      d3.schemeCategory10.map(c => {
        const color = d3.rgb(c);

        color.opacity = 0.6;

        return color;
      }),
    ) /* 10 RGB colors */;
  }

  private setSizes(): void {
    const definedWidth = this.svgWidth && Number(this.svgWidth);
    const definedHeight = this.svgHeight && Number(this.svgHeight);

    this.width =
      (definedWidth ?? +this.hostElement.getBoundingClientRect().width) - this.margin.left - this.margin.right;
    this.height =
      definedHeight ?? +this.hostElement.getBoundingClientRect().height - this.margin.bottom - this.margin.top;
  }

  private setDomains(): void {
    this.treemap = d3
      .treemap<TreemapInput>()
      .size([this.width, this.height])
      .padding(1)
      .round(true)
      .tile(TILING_METHOD[this.tilingMethod]);
  }

  private renderData(): void {
    const data = this.filterChildren(this.data);
    const root = d3
      .hierarchy(data, (d: TreemapInput) => d.children)
      .sum(d => d.total)
      .sort((a, b) => b.height - a.height || b.value - a.value);

    const tree = this.treemap(root);

    const nodes = this.rootDiv
      .datum(root)
      .selectAll('.node')
      .data(tree.leaves())
      .enter()
      .append('div')
      .classed('node', true)
      .classed('node-flex', true)
      .attr('title', d => `${d.data.name}\n${this.format(d.value)}`)
      .style('left', d => `${d.x0}px`)
      .style('top', d => `${d.y0}px`)
      .style('width', d => `${Math.max(0, d.x1 - d.x0 - 1)}px`)
      .style('height', d => `${Math.max(0, d.y1 - d.y0 - 1)}px`)
      .style('background', d => {
        while (d.depth > 1) {
          d = d.parent;
        }

        let color: string;

        if (this.#colorsMap.size) {
          color = this.#colorsMap.get(d.data.name);
        }

        return color ?? (this.colorScale(d.data.name) as string);
      });

    nodes
      .append('div')
      .attr('class', 'node-label')
      .text(d => d.data.name)
      .style('color', d => {
        while (d.depth > 1) {
          d = d.parent;
        }

        let color: string;

        if (this.#colorsMap.size) {
          color = this.#colorsMap.get(d.data.name);
          color = (color && invert(color, true)) || null;
        }

        return color ?? invert(this.colorScale(d.data.name) as string, true);
      });
  }

  private addBarEventListeners(): void {
    const isDisabled = this.isDisabled;
    const tooltipConfig = this.tooltipConfig;
    const cdr = this.cdr;

    d3.select(this.hostElement)
      .selectAll('.node')
      .on('mouseover', function (event: MouseEvent, d: { data: T }) {
        if (isDisabled) {
          return;
        }

        const hoveredElement = this as SVGRectElement;

        hoveredElement.style.strokeWidth = '2px';
        hoveredElement.style.stroke = '#413E4C';

        tooltipConfig.data = d.data;

        const sizes = hoveredElement.getBoundingClientRect();
        const windowSizes = document.body.getBoundingClientRect();

        const haveSpaceOnRight = windowSizes.right - event.pageX > MIN_TOOLTIP_WIDTH;

        // TODO: move to separate positioning service for easier reuse
        if (haveSpaceOnRight) {
          // TODO: improve positioning,
          //  investigate Angular CDK Overlays how to make it work with <rect> elements correctly
          tooltipConfig.positioning = {
            ['bottom.px']: windowSizes.bottom - sizes.top + 20,
            ['left.px']: sizes.left,
          };
        } else {
          tooltipConfig.positioning = {
            ['bottom.px']: windowSizes.bottom - sizes.top + 20,
            ['right.px']: windowSizes.right - sizes.right,
          };
        }

        cdr.detectChanges();
      })
      .on('mouseleave', function () {
        if (isDisabled) {
          return;
        }

        const hoveredElement = this as SVGRectElement;

        hoveredElement.style.strokeWidth = '';
        hoveredElement.style.stroke = '';

        tooltipConfig.data = null;
        cdr.detectChanges();
      });
  }

  private filterChildren(data: TreemapInput): TreemapInput {
    return {
      ...data,
      children: data.children
        ?.filter(child => child.total > 0 || child.children?.length)
        .map(child => this.filterChildren(child)),
    };
  }
}
