import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconStatusBadgeComponent, StatusBadgeComponent } from './components';

@NgModule({
  declarations: [StatusBadgeComponent, IconStatusBadgeComponent],
  imports: [CommonModule],
  exports: [StatusBadgeComponent, IconStatusBadgeComponent],
})
export class StatusBadgeModule {}
