import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { UserWithBranches } from '@supy/common';

@Component({
  selector: 'supy-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent {
  @Input() readonly user: UserWithBranches;
}
