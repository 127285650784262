<div class="supy-card">
  <supy-loading-overlay *ngIf="isLoading"></supy-loading-overlay>

  <igx-card>
    <igx-card-header>
      <ng-content select="[header]"></ng-content>
    </igx-card-header>

    <igx-card-content>
      <ng-content select="[content]"></ng-content>
    </igx-card-content>

    <igx-card-actions>
      <ng-content select="[actions]"></ng-content>
    </igx-card-actions>
  </igx-card>
</div>
