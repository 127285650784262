import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function date(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as Date;

    if (!value) {
      return null;
    }

    if (value instanceof Date) {
      return null;
    }

    return { date: true };
  };
}
