"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.notNullOrUndefined = void 0;
var notNullOrUndefined = function (_a) {
  var value = _a[1];
  return value !== undefined && value !== null;
};
exports.notNullOrUndefined = notNullOrUndefined;
