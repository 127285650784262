import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[supyPrefix]',
})
export class PrefixTemplateDirective {
  constructor(public template: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[supySuffix]',
})
export class SuffixTemplateDirective {
  constructor(public template: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[supyAddedContent]',
})
export class AddedContentTemplateDirective {
  constructor(public template: TemplateRef<unknown>) {}
}
