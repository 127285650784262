import { map, Observable, ReplaySubject, startWith } from 'rxjs';
import { EVENTS, IMutableContext, UnleashClient } from 'unleash-proxy-client';
import { Inject, Injectable, OnDestroy } from '@angular/core';

import { UNLEASH_CLIENT } from './unleash.injector';

@Injectable({
  providedIn: 'root',
})
export class UnleashService implements OnDestroy {
  private static initialized = false;
  readonly #statusChanges$ = new ReplaySubject<void>(1);

  constructor(@Inject(UNLEASH_CLIENT) private readonly unleash: UnleashClient) {
    this.client.on(EVENTS.UPDATE, () => this.#statusChanges$.next());
  }

  get client(): UnleashClient {
    return this.unleash;
  }

  get enabledTogglesChanges$(): Observable<string[]> {
    return this.#statusChanges$.pipe(
      map(() => this.enabledToggles),
      startWith(this.enabledToggles),
    );
  }

  get enabledToggles(): string[] {
    return this.client
      .getAllToggles()
      .filter(({ enabled }) => Boolean(enabled))
      .map(({ name }) => name);
  }

  async start(): Promise<void> {
    if (UnleashService.initialized) {
      return;
    }

    await this.client.start();
    UnleashService.initialized = true;
  }

  async updateContext(context: IMutableContext): Promise<void> {
    await this.client.updateContext({
      ...context,
      properties: { ...this.client.getContext().properties, ...context.properties },
    });
  }

  ngOnDestroy(): void {
    this.unleash.stop();
  }
}
