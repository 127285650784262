<div class="supy-otp">
  <div class="supy-otp__form">
    <a
      class="supy-anchor supy-anchor--primary flex align-items-center"
      [routerLink]="['../login']"
      queryParamsHandling="preserve"
    >
      <supy-icon name="left-caret"></supy-icon>
      <span i18n="@@common.actions.back">Back</span>
    </a>
    <div class="supy-otp__section-title">
      <h1 class="supy-otp__title" i18n="@@login.confirmOtp">Confirm OTP</h1>
      <p class="supy-otp__subtitle">
        <span *ngIf="loginType === loginTypes.Email" i18n="@@login.otpThroughEmail"
          >Enter the OTP Code sent to your email address</span
        >
        <span *ngIf="loginType === loginTypes.Phone" i18n="@@login.otpViaSms"
          >Enter the OTP code sent via SMS by Verigator.</span
        >
      </p>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form" class="supy-otp__form-content">
      <div class="supy-otp__inputs">
        <div class="supy-otp__input">
          <supy-code-input
            class="supy-otp__code"
            [codeLength]="6"
            formControlName="code"
            (valueComplete)="onSubmit()"
          ></supy-code-input>
        </div>
      </div>
      <div>
        <p class="supy-otp__subtitle" i18n="@@login.otpNotReceived">Did not receive the code?</p>
        <div class="flex justify-content-space-between align-items-center supy-otp__subtitle">
          <span #counterDom="supyCountdown" [supyCountdown]="60" i18n="@@login.otpCountdown"
            >Time Remaining {{ counterDom.counter }}</span
          >
          <supy-button
            (buttonClick)="resendOtp()"
            [disabled]="counterDom.countdown > 0"
            color="secondary"
            variant="flat"
            density="small"
            name="resend"
            i18n="@@common.actions.resend"
            >Resend</supy-button
          >
        </div>
      </div>
    </form>
  </div>
</div>
