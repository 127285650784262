import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';

import { ImageCropperOutput } from '../image-cropper';

@Component({
  selector: 'supy-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageUploaderComponent<T> implements ControlValueAccessor, OnInit {
  #value: T;

  @Input() readonly useCropper: boolean = false;
  @Input() readonly isReadonly: boolean = false;
  @Input() readonly initialUrl: string;
  @Output() readonly imageCropped = new EventEmitter<Blob>();
  @Output() readonly imageRemoved = new EventEmitter<void>();
  @ViewChild('fileInput') readonly input: ElementRef<HTMLInputElement>;
  cropperImageEvent: Event;
  cropperImageBlob: Blob;
  cropperImageLoaded: boolean;
  imageUrl: string;
  touched = false;
  acceptedFormats = ['image/png', 'image/jpeg', 'image/bmp', 'image/ico', 'image/webp'];

  onChange: (value: Blob) => void;

  onTouched: () => void;

  constructor(
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.imageUrl = this.initialUrl ?? undefined;
  }

  onRemoveImage(): void {
    this.cropperImageEvent = undefined;
    this.cropperImageBlob = undefined;
    this.imageUrl = undefined;
    this.cropperImageLoaded = false;
    this.input.nativeElement.value = '';
    this.onChange(null);
    this.control.control.removeValidators(Validators.required);
    this.control.control.updateValueAndValidity();
    this.imageRemoved.emit();
  }

  onSaveImage(unCroppedImage?: Blob): void {
    const blob = unCroppedImage ?? this.cropperImageBlob;
    const formattedBlob = blob.slice(0, blob.size, blob.type);

    this.imageUrl = URL.createObjectURL(formattedBlob);

    this.onChange(formattedBlob);

    if (!unCroppedImage) {
      this.imageCropped.emit(formattedBlob);
      this.cropperImageEvent = undefined;
    }
  }

  onImageInvalid(): void {
    this.onRemoveImage();
  }

  onFileChange(event: Event): void {
    const files = (event.target as HTMLInputElement).files;

    const image = files[0] as unknown as Blob;
    const format = image.type;
    const isImage = this.acceptedFormats.includes(format);

    this.markAsTouched();
    this.control.control.addValidators(Validators.required);
    this.control.control.updateValueAndValidity();

    if (!isImage) {
      return this.onChange(image);
    }

    if (this.useCropper) {
      this.cropperImageEvent = event;

      return;
    }
    this.onSaveImage(image);
  }

  onImageCropped(image: ImageCropperOutput): void {
    this.cropperImageBlob = image as Blob;
  }

  onImageLoaded(): void {
    this.cropperImageLoaded = true;
  }

  writeValue(value: T): void {
    this.#value = value;
  }

  registerOnChange(onChange: (value: Blob) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
      this.emitTouchStatusChanged();
    }
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
