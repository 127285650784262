import { NgModule } from '@angular/core';
import { Tracer } from '@opentelemetry/sdk-trace-base';

import { TraceService } from './trace.service';

@NgModule({
  providers: [
    {
      provide: Tracer,
      useFactory: (traceService: TraceService) => traceService.getTracer(),
      deps: [TraceService],
    },
  ],
})
export class OpenTelemetryTracingModule {}
