import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { IconType } from '../../../icon';
import { SnackbarOptions, SnackbarVariant } from '../../models';
import { SnackbarComponent } from './snackbar.component';

const DEFAULT_SNACKBAR_DURATION = 5000;

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  readonly #snackBar = inject(MatSnackBar);

  open(message: string, options: SnackbarOptions = {}): void {
    this.#snackBar.openFromComponent(SnackbarComponent, {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      data: {
        message,
        icon: this.getIcon(options.variant),
        variant: options.variant,
      },
      duration: DEFAULT_SNACKBAR_DURATION,
      panelClass: ['supy-snackbar'],
    });
  }

  success(message: string): void {
    this.open(message, { variant: 'success' });
  }

  error(message: string): void {
    this.open(message, { variant: 'error' });
  }

  warn(message: string): void {
    this.open(message, { variant: 'warn' });
  }

  private getIcon(variant?: SnackbarVariant): IconType {
    switch (variant) {
      case 'error':
        return 'minus-circle';
      case 'warn':
        return 'exclamation-hex';
      case 'success':
        return 'check-circle';
      default:
        return 'info';
    }
  }
}
