import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type AvatarSize = 'tiny' | 'xsmall' | 'small' | 'medium' | 'large' | 'huge';
type AvatarColor = 'primary' | 'default' | 'warn';
@Component({
  selector: 'supy-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input() readonly size: AvatarSize = 'medium';
  @Input() set initials(value: string) {
    if (!value) {
      return;
    }

    const [first, last] = value.split(' ').map(x => x.trim());

    if (first && last) {
      this._initials = `${first[0]}${last[0]}`;
    } else {
      this._initials = value.slice(0, 2);
    }
  }

  get initials(): string {
    return this._initials;
  }

  @Input() readonly icon?: string;
  @Input() readonly src?: string;
  @Input() readonly roundShape: boolean = true;
  @Input() readonly color: AvatarColor = 'primary';

  get shape() {
    return this.roundShape ? 'circle' : 'square';
  }

  private _initials: string;
}
