import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IgxAutocompleteModule, IgxDropDownModule } from '@infragistics/igniteui-angular';

import { DropdownModule } from '../dropdown';
import { IconModule } from '../icon';
import { InputModule } from '../input';
import { LoaderModule } from '../loader';
import { AutocompleteComponent } from './components';
import { AutocompleteDirective } from './directives';

@NgModule({
  declarations: [AutocompleteComponent, AutocompleteDirective],
  imports: [
    CommonModule,
    RouterModule,
    IgxAutocompleteModule,
    InputModule,
    DropdownModule,
    IconModule,
    IgxDropDownModule,
    LoaderModule,
  ],
  exports: [AutocompleteComponent, AutocompleteDirective],
})
export class AutocompleteModule {}
