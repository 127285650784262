import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

import { IconColor, IconType } from '../../../icon';
import { ConfirmDialogComponent } from '../confirm-dialog';
import { DialogWidth } from '../dialog';

@Component({
  selector: 'supy-confirm-dialog-with-icon',
  templateUrl: './confirm-dialog-with-icon.component.html',
  styleUrls: ['./confirm-dialog-with-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogWithIconComponent extends ConfirmDialogComponent {
  @Input() readonly icon: IconType;
  @Input() readonly iconColor?: IconColor;
  @Input() readonly width: DialogWidth = '40vw';
  @Input() readonly additionalContent: TemplateRef<unknown>;
  @Input() readonly closeOnEscape: boolean = true;
  @Input() readonly closeOnOutsideClick: boolean = false;
}
