import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IChangeCheckboxEventArgs, IgxCheckboxComponent } from '@infragistics/igniteui-angular';

@Component({
  selector: 'supy-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor {
  @ViewChild(IgxCheckboxComponent) readonly igxCheckbox!: IgxCheckboxComponent;
  @Input() @HostBinding('attr.name') readonly name: string;
  @Input() readonly readonly: boolean;
  @Input() set checked(value: boolean) {
    this.#checked = value;
  }

  get checked(): boolean {
    return this.#checked;
  }

  @Input() disabled?: boolean;
  @Input() indeterminate: boolean;

  @Output() readonly checkedChange = new EventEmitter<boolean>();

  onChange: (value: boolean) => void;

  onTouched: () => void;

  touched = false;
  #checked: boolean;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  onValueChange(event: IChangeCheckboxEventArgs): void {
    if (!this.disabled) {
      this.#checked = event.checked;
      this.checkedChange.emit(this.checked);
      this.onChange?.(this.checked);
      this.markAsTouched();
      this.cdr.markForCheck();
    }
  }

  writeValue(value: boolean): void {
    // - When use a list of checkboxes sometimes igx return value as an array of selected items
    // If we filter those array values everything works good
    // - When using checkbox inside grid writeValue is being called even without
    // using reactive forms with the checkbox itself with different type of value
    if (typeof value !== 'boolean') {
      return;
    }

    this.#checked = value;
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: (value: boolean) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
      this.emitTouchStatusChanged();
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;

    this.cdr.markForCheck();
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
