import { ConnectionPositionPair } from '@angular/cdk/overlay';

export const ARROW_SIZE = 20;
export const ARROW_OFFSET = 10;
export const PANEL_OFFSET = ARROW_SIZE / 2;

export type PopoverPosition =
  | 'top-start'
  | 'top-center'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-center'
  | 'bottom-end'
  | 'start-top'
  | 'start-center'
  | 'start-bottom'
  | 'end-top'
  | 'end-center'
  | 'end-bottom';

const TOP_START_POSITION: ConnectionPositionPair = {
  overlayX: 'start',
  overlayY: 'bottom',
  originX: 'start',
  originY: 'top',
  panelClass: ['bottom-start'],
  offsetX: isRTL() ? ARROW_OFFSET : -1 * ARROW_OFFSET,
  offsetY: -1 * PANEL_OFFSET,
};

const TOP_CENTER_POSITION: ConnectionPositionPair = {
  overlayX: 'center',
  overlayY: 'bottom',
  originX: 'center',
  originY: 'top',
  panelClass: ['bottom-center'],
  offsetY: -1 * PANEL_OFFSET,
};

const TOP_END_POSITION: ConnectionPositionPair = {
  overlayX: 'end',
  overlayY: 'bottom',
  originX: 'end',
  originY: 'top',
  panelClass: ['bottom-end'],
  offsetX: isRTL() ? -1 * ARROW_OFFSET : ARROW_OFFSET,
  offsetY: -1 * PANEL_OFFSET,
};

const BOTTOM_CENTER_POSITION: ConnectionPositionPair = {
  overlayX: 'center',
  overlayY: 'top',
  originX: 'center',
  originY: 'bottom',
  panelClass: ['top-center'],
  offsetY: PANEL_OFFSET,
};

const BOTTOM_START_POSITION: ConnectionPositionPair = {
  overlayX: 'start',
  overlayY: 'top',
  originX: 'start',
  originY: 'bottom',
  panelClass: ['top-start'],
  offsetX: isRTL() ? ARROW_OFFSET : -1 * ARROW_OFFSET,
  offsetY: PANEL_OFFSET,
};

const BOTTOM_END_POSITION: ConnectionPositionPair = {
  overlayX: 'end',
  overlayY: 'top',
  originX: 'end',
  originY: 'bottom',
  panelClass: ['top-end'],
  offsetX: isRTL() ? -1 * ARROW_OFFSET : ARROW_OFFSET,
  offsetY: PANEL_OFFSET,
};

const START_TOP_POSITION: ConnectionPositionPair = {
  overlayX: 'end',
  overlayY: 'top',
  originX: 'start',
  originY: 'top',
  panelClass: ['end-side-top'],
  offsetX: isRTL() ? PANEL_OFFSET : -1 * PANEL_OFFSET,
  offsetY: -1 * PANEL_OFFSET,
};

const START_CENTER_POSITION: ConnectionPositionPair = {
  overlayX: 'end',
  overlayY: 'center',
  originX: 'start',
  originY: 'center',
  panelClass: ['end-side-center'],
  offsetX: isRTL() ? PANEL_OFFSET : -1 * PANEL_OFFSET,
};

const START_BOTTOM_POSITION: ConnectionPositionPair = {
  overlayX: 'end',
  overlayY: 'bottom',
  originX: 'start',
  originY: 'bottom',
  panelClass: ['end-side-bottom'],
  offsetX: isRTL() ? PANEL_OFFSET : -1 * PANEL_OFFSET,
};

const END_TOP_POSITION: ConnectionPositionPair = {
  overlayX: 'start',
  overlayY: 'top',
  originX: 'end',
  originY: 'top',
  panelClass: ['start-side-top'],
  offsetX: isRTL() ? -1 * PANEL_OFFSET : PANEL_OFFSET,
};

const END_CENTER_POSITION: ConnectionPositionPair = {
  overlayX: 'start',
  overlayY: 'center',
  originX: 'end',
  originY: 'center',
  panelClass: ['start-side-center'],
  offsetX: isRTL() ? -1 * PANEL_OFFSET : PANEL_OFFSET,
};

const END_BOTTOM_POSITION: ConnectionPositionPair = {
  overlayX: 'start',
  overlayY: 'bottom',
  originX: 'end',
  originY: 'bottom',
  panelClass: ['start-side-bottom'],
  offsetX: isRTL() ? -1 * PANEL_OFFSET : PANEL_OFFSET,
};

// preferred positions, in order of priority
export const POPOVER_POSITIONS: ConnectionPositionPair[] = [
  TOP_START_POSITION,
  TOP_CENTER_POSITION,
  TOP_END_POSITION,
  BOTTOM_CENTER_POSITION,
  BOTTOM_START_POSITION,
  BOTTOM_END_POSITION,
  START_TOP_POSITION,
  START_CENTER_POSITION,
  START_BOTTOM_POSITION,
  END_TOP_POSITION,
  END_CENTER_POSITION,
  END_BOTTOM_POSITION,
];

export const popoverPositionSettings: Record<PopoverPosition, ConnectionPositionPair> = {
  'top-start': TOP_START_POSITION,
  'top-center': TOP_CENTER_POSITION,
  'top-end': TOP_END_POSITION,
  'bottom-center': BOTTOM_CENTER_POSITION,
  'bottom-start': BOTTOM_START_POSITION,
  'bottom-end': BOTTOM_END_POSITION,
  'start-top': START_TOP_POSITION,
  'start-center': START_CENTER_POSITION,
  'start-bottom': START_BOTTOM_POSITION,
  'end-top': END_TOP_POSITION,
  'end-center': END_CENTER_POSITION,
  'end-bottom': END_BOTTOM_POSITION,
} as const;

function isRTL(): boolean {
  const dir = localStorage?.getItem('dir');

  return dir === 'rtl';
}
