import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HammerModule } from '@angular/platform-browser';
import { IgxSliderModule } from '@infragistics/igniteui-angular';

import { SliderComponent } from './components';

@NgModule({
  declarations: [SliderComponent],
  imports: [CommonModule, IgxSliderModule, ReactiveFormsModule, HammerModule],
  exports: [SliderComponent],
})
export class SliderModule {}
