import '@angular/localize/init';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IgxComboModule, IgxIconModule } from '@infragistics/igniteui-angular';

import { SupyCommonModule } from '@supy/common';

import { AvatarModule } from '../avatar';
import { CheckboxModule } from '../checkbox';
import { IconModule } from '../icon';
import { ListModule } from '../list';
import { LoaderModule } from '../loader';
import { LoadingOverlayModule } from '../loading-overlay';
import { ComboboxComponent, ComboEmptyDirective, ComboFooterDirective, ComboItemDirective } from './components';

@NgModule({
  declarations: [ComboboxComponent, ComboEmptyDirective, ComboFooterDirective, ComboItemDirective],
  imports: [
    CommonModule,
    SupyCommonModule,
    IgxComboModule,
    IconModule,
    IgxIconModule,
    AvatarModule,
    ListModule,
    CheckboxModule,
    FormsModule,
    LoaderModule,
    LoadingOverlayModule,
  ],
  exports: [ComboboxComponent, ComboEmptyDirective, ComboFooterDirective, ComboItemDirective],
})
export class ComboboxModule {}
