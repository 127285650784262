import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'supy-select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectItemComponent<T> {
  @Input() value: T;
  @Input() disabled: boolean;

  @ViewChild('template', { static: true })
  readonly template: TemplateRef<T>;
}
