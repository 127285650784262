import { ImageCropperModule } from 'ngx-image-cropper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxInputGroupModule } from '@infragistics/igniteui-angular';

import { AvatarModule } from '../avatar';
import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { InputHintsModule } from '../input-hints';
import { LoaderModule } from '../loader';
import { SliderModule } from '../slider';
import { ImageCropperComponent, ImageUploaderComponent, MultipleImageUploaderComponent } from './components';

@NgModule({
  declarations: [ImageUploaderComponent, ImageCropperComponent, MultipleImageUploaderComponent],
  imports: [
    CommonModule,
    IconModule,
    ButtonModule,
    AvatarModule,
    SliderModule,
    ImageCropperModule,
    InputHintsModule,
    LoaderModule,
    IgxInputGroupModule,
  ],
  exports: [ImageUploaderComponent, MultipleImageUploaderComponent],
})
export class ImageUploaderModule {}
