import { LocalizedData, LocalizedSkeletonObjectType, SkeletonObjectType } from '../models';
import { OutletData } from './outlet.entity';
import { BaseRetailer, RetailerId } from './retailer.entity';
import { SimpleUser } from './user.entity';

export enum BranchState {
  active = 'active',
  deleted = 'deleted',
}

export enum BranchType {
  branch = 'branch',
  centralKitchen = 'central-kitchen',
}

export type BranchId = string;

export class StorageLocation {
  readonly id: string;
  readonly name: LocalizedData;
  readonly default: boolean;

  constructor(args: Partial<StorageLocation>) {
    this.id = args.id;
    this.name = args.name;
    this.default = args.default;
  }
}

export class BranchStorageLocations {
  readonly id: string;
  readonly name: string;
  readonly children: LocalizedSkeletonObjectType[];

  constructor(args: Partial<BranchStorageLocations>) {
    this.id = args.id;
    this.name = args.name;
    this.children = args.children;
  }
}

export class BaseBranch {
  readonly id: BranchId;
  readonly name: string;
  readonly phone: string;
  readonly email: string;
  readonly address: string;
  readonly logoUrl?: string;
  readonly city: string;
  readonly country: string;
  readonly state: BranchState;
  readonly storageLocations?: StorageLocation[];
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly countryId: string;
  readonly type?: BranchType;
  readonly 'outlet.id'?: string;
  readonly 'outlet.name.en'?: string;

  constructor(args: Partial<BaseBranch>) {
    this.id = args.id ?? '';
    this.name = args.name ?? '';
    this.logoUrl = args.logoUrl;
    this.email = args.email ?? '';
    this.phone = args.phone ?? '';
    this.address = args.address ?? '';
    this.city = args.city ?? '';
    this.country = args.country ?? '';
    this.type = args.type ?? BranchType.branch;
    this.state = args.state ?? BranchState.active;
    this.storageLocations = args.storageLocations ?? [];
    this.createdAt = args.createdAt ?? new Date();
    this.updatedAt = args.updatedAt ?? new Date();
  }
}

export class Branch extends BaseBranch {
  readonly outlet?: OutletData;
  readonly users: SimpleUser[];
  readonly retailer: RetailerId;

  constructor(args: Partial<Branch>) {
    super(args);

    this.outlet = args.outlet;
    this.users = args.users;
    this.retailer = args.retailer;
  }
}

//FIXME: move to retailer.entity, while investigating circ dep resulting in 'missing Module.Retailer' error - 24.04.2023
export class Retailer extends BaseRetailer {
  readonly branchesList?: ViewBranch[];

  constructor(args: Partial<Retailer>) {
    super(args);
    this.branchesList = args.branchesList;
  }
}

export class ViewBranch extends BaseBranch {
  readonly retailer: Retailer;
  readonly retailerId: string;
  readonly outlet?: OutletData;

  constructor(args: Partial<ViewBranch>) {
    super(args);

    this.retailer = args.retailer;
    this.retailerId = args.retailerId;
  }
}

export type SimpleBranch = Pick<Branch, 'id' | 'name' | 'address' | 'country' | 'logoUrl' | 'retailer'>;

export interface RelatedBranch extends SkeletonObjectType {
  readonly active: boolean;
  readonly disabled?: boolean;
  readonly message?: string;
}
