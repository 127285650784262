import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { AvailableLanguage, isValidLanguage } from '../../../languages';

export function name(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;

    if (!value) {
      return null;
    }

    const languages: AvailableLanguage[] = ['en', 'ar', 'gr'];

    if (languages.some(language => isValidLanguage(value, language))) {
      return null;
    }

    return { name: true };
  };
}
