import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minDate(date: Date): ValidatorFn {
  const customValue = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as Date;

    if (!value || value >= customValue) {
      return null;
    }

    return { minDate: true };
  };
}
