import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxIconModule } from '@infragistics/igniteui-angular';

import { IconComponent, IconSetComponent } from './components';
import { IconService } from './services';

@NgModule({
  declarations: [IconComponent, IconSetComponent],
  providers: [IconService],
  imports: [CommonModule, IgxIconModule],
  exports: [IconComponent, IconSetComponent],
})
export class IconModule {}
