import { LoginType } from '../../core';

export class LoginSuccess {
  static type = '[Auth] LoginSuccess';

  constructor(public loginType: LoginType) {}
}

export class LoginError {
  static type = '[Auth] LoginError';

  constructor(public loginType: LoginType) {}
}

export class Logout {
  static type = '[Auth] Logout';
}
