import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

type ImageFormat = 'png' | 'jpeg' | 'bmp' | 'webp' | 'ico';
export function image(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const blob = control.value as Blob;

    if (!(blob instanceof Blob)) {
      return null;
    }

    const [type, ext] = blob.type.split('/');

    if (type !== 'image' && !isValidExtension(ext)) {
      return { image: true };
    }

    return null;
  };
}

function isValidExtension(ext: string): ext is ImageFormat {
  return ['png', 'jpeg', 'bmp', 'webp', 'ico'].includes(ext);
}
