"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AsiaTimezone = void 0;
var AsiaTimezone;
(function (AsiaTimezone) {
  AsiaTimezone["Almaty"] = "Asia/Almaty";
  AsiaTimezone["Amman"] = "Asia/Amman";
  AsiaTimezone["Anadyr"] = "Asia/Anadyr";
  AsiaTimezone["Aqtau"] = "Asia/Aqtau";
  AsiaTimezone["Aqtobe"] = "Asia/Aqtobe";
  AsiaTimezone["Ashgabat"] = "Asia/Ashgabat";
  AsiaTimezone["Atyrau"] = "Asia/Atyrau";
  AsiaTimezone["Baghdad"] = "Asia/Baghdad";
  AsiaTimezone["Bahrain"] = "Asia/Bahrain";
  AsiaTimezone["Baku"] = "Asia/Baku";
  AsiaTimezone["Bangkok"] = "Asia/Bangkok";
  AsiaTimezone["Barnaul"] = "Asia/Barnaul";
  AsiaTimezone["Beirut"] = "Asia/Beirut";
  AsiaTimezone["Bishkek"] = "Asia/Bishkek";
  AsiaTimezone["Brunei"] = "Asia/Brunei";
  AsiaTimezone["Chita"] = "Asia/Chita";
  AsiaTimezone["Choibalsan"] = "Asia/Choibalsan";
  AsiaTimezone["Colombo"] = "Asia/Colombo";
  AsiaTimezone["Damascus"] = "Asia/Damascus";
  AsiaTimezone["Dhaka"] = "Asia/Dhaka";
  AsiaTimezone["Dili"] = "Asia/Dili";
  AsiaTimezone["Dubai"] = "Asia/Dubai";
  AsiaTimezone["Dushanbe"] = "Asia/Dushanbe";
  AsiaTimezone["Famagusta"] = "Asia/Famagusta";
  AsiaTimezone["Gaza"] = "Asia/Gaza";
  AsiaTimezone["Hebron"] = "Asia/Hebron";
  AsiaTimezone["HoChiMinh"] = "Asia/Ho_Chi_Minh";
  AsiaTimezone["HongKong"] = "Asia/Hong_Kong";
  AsiaTimezone["Hovd"] = "Asia/Hovd";
  AsiaTimezone["Irkutsk"] = "Asia/Irkutsk";
  AsiaTimezone["Jakarta"] = "Asia/Jakarta";
  AsiaTimezone["Jayapura"] = "Asia/Jayapura";
  AsiaTimezone["Jerusalem"] = "Asia/Jerusalem";
  AsiaTimezone["Kabul"] = "Asia/Kabul";
  AsiaTimezone["Kamchatka"] = "Asia/Kamchatka";
  AsiaTimezone["Karachi"] = "Asia/Karachi";
  AsiaTimezone["Kathmandu"] = "Asia/Kathmandu";
  AsiaTimezone["Khandyga"] = "Asia/Khandyga";
  AsiaTimezone["Kolkata"] = "Asia/Kolkata";
  AsiaTimezone["Krasnoyarsk"] = "Asia/Krasnoyarsk";
  AsiaTimezone["KualaLumpur"] = "Asia/Kuala_Lumpur";
  AsiaTimezone["Kuching"] = "Asia/Kuching";
  AsiaTimezone["Kuwait"] = "Asia/Kuwait";
  AsiaTimezone["Macau"] = "Asia/Macau";
  AsiaTimezone["Magadan"] = "Asia/Magadan";
  AsiaTimezone["Makassar"] = "Asia/Makassar";
  AsiaTimezone["Manila"] = "Asia/Manila";
  AsiaTimezone["Muscat"] = "Asia/Muscat";
  AsiaTimezone["Nicosia"] = "Asia/Nicosia";
  AsiaTimezone["Novokuznetsk"] = "Asia/Novokuznetsk";
  AsiaTimezone["Novosibirsk"] = "Asia/Novosibirsk";
  AsiaTimezone["Omsk"] = "Asia/Omsk";
  AsiaTimezone["Oral"] = "Asia/Oral";
  AsiaTimezone["PhnomPenh"] = "Asia/Phnom_Penh";
  AsiaTimezone["Pontianak"] = "Asia/Pontianak";
  AsiaTimezone["Pyongyang"] = "Asia/Pyongyang";
  AsiaTimezone["Qatar"] = "Asia/Qatar";
  AsiaTimezone["Qostanay"] = "Asia/Qostanay";
  AsiaTimezone["Qyzylorda"] = "Asia/Qyzylorda";
  AsiaTimezone["Riyadh"] = "Asia/Riyadh";
  AsiaTimezone["Sakhalin"] = "Asia/Sakhalin";
  AsiaTimezone["Samarkand"] = "Asia/Samarkand";
  AsiaTimezone["Seoul"] = "Asia/Seoul";
  AsiaTimezone["Shanghai"] = "Asia/Shanghai";
  AsiaTimezone["Singapore"] = "Asia/Singapore";
  AsiaTimezone["Srednekolymsk"] = "Asia/Srednekolymsk";
  AsiaTimezone["Taipei"] = "Asia/Taipei";
  AsiaTimezone["Tashkent"] = "Asia/Tashkent";
  AsiaTimezone["Tbilisi"] = "Asia/Tbilisi";
  AsiaTimezone["Tehran"] = "Asia/Tehran";
  AsiaTimezone["Thimphu"] = "Asia/Thimphu";
  AsiaTimezone["Tokyo"] = "Asia/Tokyo";
  AsiaTimezone["Tomsk"] = "Asia/Tomsk";
  AsiaTimezone["Ulaanbaatar"] = "Asia/Ulaanbaatar";
  AsiaTimezone["Urumqi"] = "Asia/Urumqi";
  AsiaTimezone["UstNera"] = "Asia/Ust-Nera";
  AsiaTimezone["Vientiane"] = "Asia/Vientiane";
  AsiaTimezone["Vladivostok"] = "Asia/Vladivostok";
  AsiaTimezone["Yakutsk"] = "Asia/Yakutsk";
  AsiaTimezone["Yangon"] = "Asia/Yangon";
  AsiaTimezone["Yekaterinburg"] = "Asia/Yekaterinburg";
  AsiaTimezone["Yerevan"] = "Asia/Yerevan";
})(AsiaTimezone = exports.AsiaTimezone || (exports.AsiaTimezone = {}));
