export enum MimeType {
  jpeg = 'image/jpeg',
  png = 'image/png',
  webp = 'image/webp',
  pdf = 'application/pdf',
  csv = 'text/csv',
  txt = 'text/plain',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt = 'application/vnd.ms-powerpoint',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  zip = 'application/zip',
}

export type MimeInverted = {
  [key in MimeType]: string;
};

export const mimeInverted = Object.assign(
  {},
  ...Object.entries(MimeType).map(([key, value]) => ({ [value]: key })),
) as MimeInverted;
