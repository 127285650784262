import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { IgxDropDownItemComponent } from '@infragistics/igniteui-angular';

@Component({
  selector: 'supy-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownItemComponent {
  @Input() readonly disabled: boolean;
  @Input() readonly routerLink: string | string[];
  @Input() readonly class: string;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() readonly click = new EventEmitter<MouseEvent | PointerEvent>();
  @ViewChild(IgxDropDownItemComponent, { static: true })
  readonly item: IgxDropDownItemComponent;

  @ViewChild('template', { static: true })
  readonly template: TemplateRef<unknown>;
}
