import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, ICellEditorParams } from '@ag-grid-community/core';
import { Component, ViewChild } from '@angular/core';

import { SelectComponent } from '../../../select';

export interface SelectCellEditorContext<TData = unknown, TValue = unknown> {
  readonly disabled?: (rowData: TData) => boolean;
  readonly value?: (rowData: TData) => TValue;
  readonly options: TValue[] | ((rowData: TData) => TValue[] | undefined);
  readonly displayFn?: (option: TValue) => string;
  readonly searchEnabled?: boolean;
  readonly placeholder?: string;
}

@Component({
  selector: 'supy-select-cell-editor',
  template: `
    <supy-select
      #focusableComponent
      class="supy-select-editor-cell"
      [placeholder]="context?.placeholder ?? 'Select'"
      name="select"
      [disabled]="context?.disabled?.(rowData)"
      [searchEnabled]="context?.searchEnabled"
      [value]="context?.value?.(rowData)"
      (selected)="valueChangeHandler($event)"
      (closed)="params.stopEditing()"
    >
      <supy-select-item *ngFor="let option of options" [value]="option">
        {{ context?.displayFn?.(option) ?? option }}
      </supy-select-item>
    </supy-select>
  `,
  styleUrls: ['./select-cell-editor.component.scss'],
})
export class SelectCellEditorComponent<
  TData = unknown,
  TValue = unknown,
  TContext extends SelectCellEditorContext<TData, TValue> = { options: [] },
> implements ICellEditorAngularComp
{
  private value: TValue;
  protected rowData: TData;
  protected params: ICellEditorParams<TData, TValue, TContext>;
  protected context: TContext;

  @ViewChild('focusableComponent') private readonly focusableComponent: SelectComponent<TValue>;
  get options() {
    return (Array.isArray(this.context.options) ? this.context.options : this.context.options(this.rowData)) ?? [];
  }

  agInit(params: ICellEditorParams<TData, TValue, TContext>): void {
    this.params = params;
    this.context = params.context;
    this.rowData = this.getRowData(params);
    this.value = params.value;
  }

  refresh(_: ICellEditorParams<TData>): boolean {
    return true;
  }

  getValue(): TValue {
    return this.value;
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

  isCancelBeforeStart(): boolean {
    return false;
  }

  afterGuiAttached(_?: IAfterGuiAttachedParams): void {
    this.onStartEditing();
  }

  protected valueChangeHandler(value: TValue): void {
    if (value) {
      this.value = value;
      this.params.stopEditing();
      this.params.api.refreshCells();
    }
  }

  private onStartEditing(): void {
    if (this.focusableComponent) {
      this.focusableComponent.open();
    }
  }

  private getRowData(params: ICellEditorParams<TData>): TData {
    return params.data;
  }
}
