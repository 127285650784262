import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarLabel,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

import { ButtonModule } from '../../../button';
import { IconModule, IconType } from '../../../icon';
import { SnackbarVariant } from '../../models';

export interface SnackbarData {
  readonly message: string;
  readonly actionText: string;
  readonly icon?: IconType;
  readonly variant?: SnackbarVariant;
}

@Component({
  selector: 'supy-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  standalone: true,
  imports: [ButtonModule, MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction, IconModule, NgClass],
})
export class SnackbarComponent {
  protected readonly snackBarRef = inject(MatSnackBarRef);
  protected readonly snackBarData: SnackbarData = inject(MAT_SNACK_BAR_DATA) as SnackbarData;
}
