import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[supyComboEmpty]',
})
export class ComboEmptyDirective {
  constructor(public template: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[supyComboFooter]',
})
export class ComboFooterDirective {
  constructor(public template: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[supyComboItem]',
})
export class ComboItemDirective {
  constructor(public template: TemplateRef<unknown>) {}
}
