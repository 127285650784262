import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function maxFileSize(maxKBSize: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const blob = control.value as Blob;

    if (!(blob instanceof Blob)) {
      return null;
    }

    const sizeInKB = blob.size / 1024;

    if (sizeInKB > maxKBSize) {
      return { maxSize: true };
    }

    return null;
  };
}
