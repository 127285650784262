"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CountryCode = void 0;
var CountryCode;
(function (CountryCode) {
  CountryCode["Afghanistan"] = "+93";
  CountryCode["Albania"] = "+355";
  CountryCode["Algeria"] = "+213";
  CountryCode["Andorra"] = "+376";
  CountryCode["Angola"] = "+244";
  CountryCode["AntiguaAndBarbuda"] = "+1";
  CountryCode["Argentina"] = "+54";
  CountryCode["Armenia"] = "+374";
  CountryCode["Australia"] = "+61";
  CountryCode["Austria"] = "+43";
  CountryCode["Azerbaijan"] = "+994";
  CountryCode["Bahamas"] = "+1";
  CountryCode["Bahrain"] = "+973";
  CountryCode["Bangladesh"] = "+880";
  CountryCode["Barbados"] = "+1";
  CountryCode["Belarus"] = "+375";
  CountryCode["Belgium"] = "+32";
  CountryCode["Belize"] = "+501";
  CountryCode["Benin"] = "+229";
  CountryCode["Bhutan"] = "+975";
  CountryCode["Bolivia"] = "+591";
  CountryCode["BosniaAndHerzegovina"] = "+387";
  CountryCode["Botswana"] = "+267";
  CountryCode["Brazil"] = "+55";
  CountryCode["Brunei"] = "+673";
  CountryCode["Bulgaria"] = "+359";
  CountryCode["BurkinaFaso"] = "+226";
  CountryCode["Burundi"] = "+257";
  CountryCode["CaboVerde"] = "+238";
  CountryCode["Cambodia"] = "+855";
  CountryCode["Cameroon"] = "+237";
  CountryCode["Canada"] = "+1";
  CountryCode["CentralAfricanRepublic"] = "+236";
  CountryCode["Chad"] = "+235";
  CountryCode["Chile"] = "+56";
  CountryCode["China"] = "+86";
  CountryCode["Colombia"] = "+57";
  CountryCode["Comoros"] = "+269";
  CountryCode["Congo"] = "+242";
  CountryCode["CostaRica"] = "+506";
  CountryCode["Croatia"] = "+385";
  CountryCode["Cuba"] = "+53";
  CountryCode["Cyprus"] = "+357";
  CountryCode["CzechRepublic"] = "+420";
  CountryCode["Denmark"] = "+45";
  CountryCode["Djibouti"] = "+253";
  CountryCode["Dominica"] = "+1";
  CountryCode["DominicanRepublic"] = "+1";
  CountryCode["EastTimor"] = "+670";
  CountryCode["Ecuador"] = "+593";
  CountryCode["Egypt"] = "+20";
  CountryCode["ElSalvador"] = "+503";
  CountryCode["EquatorialGuinea"] = "+240";
  CountryCode["Eritrea"] = "+291";
  CountryCode["Estonia"] = "+372";
  CountryCode["Eswatini"] = "+268";
  CountryCode["Ethiopia"] = "+251";
  CountryCode["Fiji"] = "+679";
  CountryCode["Finland"] = "+358";
  CountryCode["France"] = "+33";
  CountryCode["Gabon"] = "+241";
  CountryCode["Gambia"] = "+220";
  CountryCode["Georgia"] = "+995";
  CountryCode["Germany"] = "+49";
  CountryCode["Ghana"] = "+233";
  CountryCode["Greece"] = "+30";
  CountryCode["Grenada"] = "+1";
  CountryCode["Guatemala"] = "+502";
  CountryCode["Guinea"] = "+224";
  CountryCode["GuineaBissau"] = "+245";
  CountryCode["Guyana"] = "+592";
  CountryCode["Haiti"] = "+509";
  CountryCode["Honduras"] = "+504";
  CountryCode["Hungary"] = "+36";
  CountryCode["Iceland"] = "+354";
  CountryCode["India"] = "+91";
  CountryCode["Indonesia"] = "+62";
  CountryCode["Iran"] = "+98";
  CountryCode["Iraq"] = "+964";
  CountryCode["Ireland"] = "+353";
  CountryCode["Israel"] = "+972";
  CountryCode["Italy"] = "+39";
  CountryCode["Jamaica"] = "+1";
  CountryCode["Japan"] = "+81";
  CountryCode["Jordan"] = "+962";
  CountryCode["Kazakhstan"] = "+7";
  CountryCode["Kenya"] = "+254";
  CountryCode["Kiribati"] = "+686";
  CountryCode["KoreaNorth"] = "+850";
  CountryCode["KoreaSouth"] = "+82";
  CountryCode["Kuwait"] = "+965";
  CountryCode["Kyrgyzstan"] = "+996";
  CountryCode["Laos"] = "+856";
  CountryCode["Latvia"] = "+371";
  CountryCode["Lebanon"] = "+961";
  CountryCode["Lesotho"] = "+266";
  CountryCode["Liberia"] = "+231";
  CountryCode["Libya"] = "+218";
  CountryCode["Liechtenstein"] = "+423";
  CountryCode["Lithuania"] = "+370";
  CountryCode["Luxembourg"] = "+352";
  CountryCode["Madagascar"] = "+261";
  CountryCode["Malawi"] = "+265";
  CountryCode["Malaysia"] = "+60";
  CountryCode["Maldives"] = "+960";
  CountryCode["Mali"] = "+223";
  CountryCode["Malta"] = "+356";
  CountryCode["MarshallIslands"] = "+692";
  CountryCode["Mauritania"] = "+222";
  CountryCode["Mauritius"] = "+230";
  CountryCode["Mexico"] = "+52";
  CountryCode["Micronesia"] = "+691";
  CountryCode["Moldova"] = "+373";
  CountryCode["Monaco"] = "+377";
  CountryCode["Mongolia"] = "+976";
  CountryCode["Montenegro"] = "+382";
  CountryCode["Morocco"] = "+212";
  CountryCode["Mozambique"] = "+258";
  CountryCode["Myanmar"] = "+95";
  CountryCode["Namibia"] = "+264";
  CountryCode["Nauru"] = "+674";
  CountryCode["Nepal"] = "+977";
  CountryCode["Netherlands"] = "+31";
  CountryCode["NewZealand"] = "+64";
  CountryCode["Nicaragua"] = "+505";
  CountryCode["Niger"] = "+227";
  CountryCode["Nigeria"] = "+234";
  CountryCode["NorthMacedonia"] = "+389";
  CountryCode["Norway"] = "+47";
  CountryCode["Oman"] = "+968";
  CountryCode["Pakistan"] = "+92";
  CountryCode["Palau"] = "+680";
  CountryCode["Panama"] = "+507";
  CountryCode["PapuaNewGuinea"] = "+675";
  CountryCode["Paraguay"] = "+595";
  CountryCode["Peru"] = "+51";
  CountryCode["Philippines"] = "+63";
  CountryCode["Poland"] = "+48";
  CountryCode["Portugal"] = "+351";
  CountryCode["Qatar"] = "+974";
  CountryCode["Romania"] = "+40";
  CountryCode["Russia"] = "+7";
  CountryCode["Rwanda"] = "+250";
  CountryCode["SaintKittsAndNevis"] = "+1";
  CountryCode["SaintLucia"] = "+1";
  CountryCode["SaintVincentAndTheGrenadines"] = "+1";
  CountryCode["Samoa"] = "+685";
  CountryCode["SanMarino"] = "+378";
  CountryCode["SaoTomeAndPrincipe"] = "+239";
  CountryCode["SaudiArabia"] = "+966";
  CountryCode["Senegal"] = "+221";
  CountryCode["Serbia"] = "+381";
  CountryCode["Seychelles"] = "+248";
  CountryCode["SierraLeone"] = "+232";
  CountryCode["Singapore"] = "+65";
  CountryCode["Slovakia"] = "+421";
  CountryCode["Slovenia"] = "+386";
  CountryCode["SolomonIslands"] = "+677";
  CountryCode["Somalia"] = "+252";
  CountryCode["SouthAfrica"] = "+27";
  CountryCode["SouthSudan"] = "+211";
  CountryCode["Spain"] = "+34";
  CountryCode["SriLanka"] = "+94";
  CountryCode["Sudan"] = "+249";
  CountryCode["Suriname"] = "+597";
  CountryCode["Sweden"] = "+46";
  CountryCode["Switzerland"] = "+41";
  CountryCode["Syria"] = "+963";
  CountryCode["Taiwan"] = "+886";
  CountryCode["Tajikistan"] = "+992";
  CountryCode["Tanzania"] = "+255";
  CountryCode["Thailand"] = "+66";
  CountryCode["Togo"] = "+228";
  CountryCode["Tonga"] = "+676";
  CountryCode["TrinidadAndTobago"] = "+1";
  CountryCode["Tunisia"] = "+216";
  CountryCode["Turkey"] = "+90";
  CountryCode["Turkmenistan"] = "+993";
  CountryCode["Tuvalu"] = "+688";
  CountryCode["Uganda"] = "+256";
  CountryCode["Ukraine"] = "+380";
  CountryCode["UnitedArabEmirates"] = "+971";
  CountryCode["UnitedKingdom"] = "+44";
  CountryCode["UnitedStates"] = "+1";
  CountryCode["Uruguay"] = "+598";
  CountryCode["Uzbekistan"] = "+998";
  CountryCode["Vanuatu"] = "+678";
  CountryCode["VaticanCity"] = "+39";
  CountryCode["Venezuela"] = "+58";
  CountryCode["Vietnam"] = "+84";
  CountryCode["Yemen"] = "+967";
  CountryCode["Zambia"] = "+260";
  CountryCode["Zimbabwe"] = "+263";
})(CountryCode = exports.CountryCode || (exports.CountryCode = {}));
