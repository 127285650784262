export interface BaseOtpRequest {
  readonly otp: string;
}

export interface LoginEmailOtpRequest extends BaseOtpRequest {
  readonly email: string;
}

export interface LoginPhoneOtpRequest extends BaseOtpRequest {
  readonly phone: string;
}

export type LoginRequest = LoginEmailOtpRequest | LoginPhoneOtpRequest;

export interface SendEmailOtpRequest {
  readonly email: string;
}

export interface SendPhoneOtpRequest {
  readonly phone: string;
}

export type SendOtpRequest = SendEmailOtpRequest | SendPhoneOtpRequest;

export interface RefreshTokenRequest {
  readonly refreshToken: string;
}

export interface RefreshTokenResponse {
  readonly accessToken: string;
  readonly refreshToken: string;
}

export enum LoginType {
  Email = 'email',
  Phone = 'phone',
}
