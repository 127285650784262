<ng-container
  *supyContext="{ topItems: topItems(), bottomItems: bottomItems(), selectedGroup: selectedGroup() } as sideNavContext"
>
  @if (sideNavContext.topItems?.length || sideNavContext.bottomItems?.length) {
    <div class="supy-side-nav">
      <button
        class="supy-side-nav__burger"
        name="burger-switch"
        (click)="sideNavContext.selectedGroup && onHandle(null, reversedMenuState())"
      >
        <supy-icon class="supy-side-nav__icon" name="menu-burger" size="medium"></supy-icon>
      </button>

      <div class="supy-side-nav__items">
        <div class="supy-side-nav__items-top">
          @for (item of sideNavContext.topItems; track item.key) {
            <ng-container [ngTemplateOutlet]="itemTmpl" [ngTemplateOutletContext]="{ item }"> </ng-container>
          }
        </div>
        <div class="supy-side-nav__items-bottom">
          @for (item of sideNavContext.bottomItems; track item.key) {
            <ng-container [ngTemplateOutlet]="itemTmpl" [ngTemplateOutletContext]="{ item }"> </ng-container>
          }
          @if (versionHash) {
            <p class="supy-side-nav__version-hash">v. {{ versionHash }}</p>
          }
        </div>
      </div>
    </div>

    @if (subMenuState() === 'out') {
      <div class="supy-side-nav-overlay" (click)="onHandle(null, 'in')"></div>
    }
    <div class="supy-side-nav-sub" [ngClass]="['supy-side-nav-sub--' + subMenuState()]">
      <supy-image class="supy-side-nav-sub__logo" src="/assets/images/supy-logo-wide.svg"></supy-image>

      @if (
        !sideNavContext.selectedGroup?.quickActionOff &&
        (sideNavContext.selectedGroup?.quickAction || sideNavContext.selectedGroup?.quickActionUrl)
      ) {
        <a
          class="supy-side-nav-sub__quick-action"
          (click)="onSubActionClick(sideNavContext.selectedGroup)"
          [name]="sideNavContext.selectedGroup?.quickActionTitleAlt"
          [title]="sideNavContext.selectedGroup?.quickActionTitleAlt"
          [routerLink]="sideNavContext.selectedGroup?.quickActionUrl"
          [queryParams]="sideNavContext.selectedGroup?.quickActionQueryParams"
          queryParamsHandling="merge"
        >
          <supy-icon
            [name]="
              sideNavContext.selectedGroup?.underMaintenance
                ? 'exclamation-hex'
                : ($any(sideNavContext.selectedGroup?.quickActionIcon) ?? 'plus-flat')
            "
            [color]="sideNavContext.selectedGroup?.underMaintenance ? 'warn' : null"
            size="small"
          ></supy-icon>
          {{ sideNavContext.selectedGroup?.quickActionTitle }}
        </a>
      }
      <div class="supy-side-nav-sub__item-wrapper">
        @for (item of sideNavContext.selectedGroup?.children; track item.key) {
          <a
            (click)="item.type === 'action' ? item.callback() : onHandle(null, 'in')"
            class="supy-side-nav-sub__item"
            [routerLink]="item.url"
            [class.active]="item.active"
            [name]="item.title"
            [title]="item.titleAlt ?? ''"
          >
            @if (item.icon) {
              <supy-icon
                class="supy-side-nav__icon"
                [name]="item.underMaintenance ? 'exclamation-hex' : $any(item.icon)"
                [color]="item.underMaintenance ? 'warn' : item.active ? 'primary' : null"
                size="medium"
              ></supy-icon>
            }
            {{ item.title }}
          </a>

          @for (subItem of item?.children; track subItem.key) {
            <div class="supy-side-nav-sub__item-tree">
              <div class="supy-side-nav-sub__item-tree-line"></div>
              <a
                (click)="subItem.type === 'action' ? item.callback() : onHandle(null, 'in')"
                class="supy-side-nav-sub__item-tree-item"
                [routerLink]="subItem.url"
                [class.active]="subItem.active"
                [name]="subItem.title"
                [title]="subItem.titleAlt ?? ''"
              >
                {{ subItem.title }}
              </a>
            </div>
          }
        }
      </div>
    </div>
  }
</ng-container>

<ng-template #itemTmpl let-item="item">
  @if (item.url) {
    <a
      (click)="item.type === 'action' ? item.callback() : onHandle(null, 'in')"
      class="supy-side-nav__item"
      [routerLink]="item.url"
      [class.active]="item.active"
      [name]="item.title"
      [title]="item.titleAlt ?? ''"
    >
      <supy-icon
        class="supy-side-nav__icon"
        [name]="item.underMaintenance ? 'exclamation-hex' : item.active ? (item.iconActive ?? item.icon) : item.icon"
        [color]="item.underMaintenance ? 'warn' : item.active ? 'primary' : null"
        size="medium"
      ></supy-icon>
      {{ item.title }}
    </a>
  }

  @if (!item.url) {
    <button
      (click)="item.type === 'action' ? item.callback() : this.onHandleAsync(item)"
      class="supy-side-nav__item"
      [class.active]="item.active"
      [class.highlighted]="isSubMenuOpened() && selectedGroup() && !item.active && item.key === selectedGroup().key"
      [name]="item.title"
      [title]="item.titleAlt ?? ''"
    >
      <supy-icon
        class="supy-side-nav__icon"
        [name]="item.underMaintenance ? 'exclamation-hex' : item.active ? (item.iconActive ?? item.icon) : item.icon"
        [color]="item.underMaintenance ? 'warn' : null"
        size="medium"
      ></supy-icon>
      {{ item.title }}
    </button>
  }
</ng-template>
