import { IdType, LocalizedData } from '../models';

export type RetailerId = string;

export enum RetailerState {
  active = 'active',
  deleted = 'deleted',
}

export class RetailerMetadata {
  readonly color?: string;
}

export class BaseRetailer {
  readonly id: RetailerId;
  readonly name: string;
  readonly branches: number;
  readonly logoUrl?: string;
  readonly state: RetailerState;
  readonly metadata: RetailerMetadata;
  readonly country?: IdType;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(args: Partial<BaseRetailer>) {
    this.id = args.id ?? '';
    this.name = args.name ?? '';
    this.logoUrl = args.logoUrl;
    this.metadata = args.metadata ?? {};
    this.state = args.state ?? RetailerState.active;
    this.createdAt = args.createdAt ?? new Date();
    this.updatedAt = args.updatedAt ?? new Date();
  }
}

interface SheetPackaging {
  readonly quantity: number;
  readonly unitName?: string;
  readonly nameSimulator: string;
  readonly usedAsPiece?: boolean;
}

interface SheetSupplierItem {
  readonly itemName: LocalizedData;
  readonly itemCode?: string;
  readonly invoiceUnit: string;
  readonly price: number;
  readonly supplierName: string;
  readonly isPriority?: boolean;
}

export class SheetRetailerItem {
  readonly name: LocalizedData;
  readonly category?: {
    readonly parentName: string;
    readonly childName: string;
  };

  readonly baseUnit: string;
  readonly basePackaging: SheetPackaging;
  readonly secondPackaging: SheetPackaging;
  readonly supplierItem: SheetSupplierItem;
  readonly costCenter?: string;
}
