<igx-toast [autoHide]="false" [positionSettings]="positionSettings" class="supy-version-check__toast">
  <div>
    <p>New application version found.</p>
    <p>
      Please, save your work and
      <span class="supy-version-check__refresh" (click)="refreshApp()">refresh the page</span>
    </p>
    <p><small>Note: This will refresh all other open tabs.</small></p>
  </div>
</igx-toast>
