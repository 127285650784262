"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CountryAlpha2 = void 0;
var CountryAlpha2;
(function (CountryAlpha2) {
  CountryAlpha2["Afghanistan"] = "AF";
  CountryAlpha2["Albania"] = "AL";
  CountryAlpha2["Algeria"] = "DZ";
  CountryAlpha2["Andorra"] = "AD";
  CountryAlpha2["Angola"] = "AO";
  CountryAlpha2["AntiguaAndBarbuda"] = "AG";
  CountryAlpha2["Argentina"] = "AR";
  CountryAlpha2["Armenia"] = "AM";
  CountryAlpha2["Australia"] = "AU";
  CountryAlpha2["Austria"] = "AT";
  CountryAlpha2["Azerbaijan"] = "AZ";
  CountryAlpha2["Bahamas"] = "BS";
  CountryAlpha2["Bahrain"] = "BH";
  CountryAlpha2["Bangladesh"] = "BD";
  CountryAlpha2["Barbados"] = "BB";
  CountryAlpha2["Belarus"] = "BY";
  CountryAlpha2["Belgium"] = "BE";
  CountryAlpha2["Belize"] = "BZ";
  CountryAlpha2["Benin"] = "BJ";
  CountryAlpha2["Bhutan"] = "BT";
  CountryAlpha2["Bolivia"] = "BO";
  CountryAlpha2["BosniaAndHerzegovina"] = "BA";
  CountryAlpha2["Botswana"] = "BW";
  CountryAlpha2["Brazil"] = "BR";
  CountryAlpha2["Brunei"] = "BN";
  CountryAlpha2["Bulgaria"] = "BG";
  CountryAlpha2["BurkinaFaso"] = "BF";
  CountryAlpha2["Burundi"] = "BI";
  CountryAlpha2["CaboVerde"] = "CV";
  CountryAlpha2["Cambodia"] = "KH";
  CountryAlpha2["Cameroon"] = "CM";
  CountryAlpha2["Canada"] = "CA";
  CountryAlpha2["CentralAfricanRepublic"] = "CF";
  CountryAlpha2["Chad"] = "TD";
  CountryAlpha2["Chile"] = "CL";
  CountryAlpha2["China"] = "CN";
  CountryAlpha2["Colombia"] = "CO";
  CountryAlpha2["Comoros"] = "KM";
  CountryAlpha2["Congo"] = "CG";
  CountryAlpha2["CostaRica"] = "CR";
  CountryAlpha2["Croatia"] = "HR";
  CountryAlpha2["Cuba"] = "CU";
  CountryAlpha2["Cyprus"] = "CY";
  CountryAlpha2["CzechRepublic"] = "CZ";
  CountryAlpha2["Denmark"] = "DK";
  CountryAlpha2["Djibouti"] = "DJ";
  CountryAlpha2["Dominica"] = "DM";
  CountryAlpha2["DominicanRepublic"] = "DO";
  CountryAlpha2["EastTimor"] = "TL";
  CountryAlpha2["Ecuador"] = "EC";
  CountryAlpha2["Egypt"] = "EG";
  CountryAlpha2["ElSalvador"] = "SV";
  CountryAlpha2["EquatorialGuinea"] = "GQ";
  CountryAlpha2["Eritrea"] = "ER";
  CountryAlpha2["Estonia"] = "EE";
  CountryAlpha2["Eswatini"] = "SZ";
  CountryAlpha2["Ethiopia"] = "ET";
  CountryAlpha2["Fiji"] = "FJ";
  CountryAlpha2["Finland"] = "FI";
  CountryAlpha2["France"] = "FR";
  CountryAlpha2["Gabon"] = "GA";
  CountryAlpha2["Gambia"] = "GM";
  CountryAlpha2["Georgia"] = "GE";
  CountryAlpha2["Germany"] = "DE";
  CountryAlpha2["Ghana"] = "GH";
  CountryAlpha2["Greece"] = "GR";
  CountryAlpha2["Grenada"] = "GD";
  CountryAlpha2["Guatemala"] = "GT";
  CountryAlpha2["Guinea"] = "GN";
  CountryAlpha2["GuineaBissau"] = "GW";
  CountryAlpha2["Guyana"] = "GY";
  CountryAlpha2["Haiti"] = "HT";
  CountryAlpha2["Honduras"] = "HN";
  CountryAlpha2["Hungary"] = "HU";
  CountryAlpha2["Iceland"] = "IS";
  CountryAlpha2["India"] = "IN";
  CountryAlpha2["Indonesia"] = "ID";
  CountryAlpha2["Iran"] = "IR";
  CountryAlpha2["Iraq"] = "IQ";
  CountryAlpha2["Ireland"] = "IE";
  CountryAlpha2["Israel"] = "IL";
  CountryAlpha2["Italy"] = "IT";
  CountryAlpha2["Jamaica"] = "JM";
  CountryAlpha2["Japan"] = "JP";
  CountryAlpha2["Jordan"] = "JO";
  CountryAlpha2["Kazakhstan"] = "KZ";
  CountryAlpha2["Kenya"] = "KE";
  CountryAlpha2["Kiribati"] = "KI";
  CountryAlpha2["KoreaNorth"] = "KP";
  CountryAlpha2["KoreaSouth"] = "KR";
  CountryAlpha2["Kosovo"] = "XK";
  CountryAlpha2["Kuwait"] = "KW";
  CountryAlpha2["Kyrgyzstan"] = "KG";
  CountryAlpha2["Laos"] = "LA";
  CountryAlpha2["Latvia"] = "LV";
  CountryAlpha2["Lebanon"] = "LB";
  CountryAlpha2["Lesotho"] = "LS";
  CountryAlpha2["Liberia"] = "LR";
  CountryAlpha2["Libya"] = "LY";
  CountryAlpha2["Liechtenstein"] = "LI";
  CountryAlpha2["Lithuania"] = "LT";
  CountryAlpha2["Luxembourg"] = "LU";
  CountryAlpha2["Madagascar"] = "MG";
  CountryAlpha2["Malawi"] = "MW";
  CountryAlpha2["Malaysia"] = "MY";
  CountryAlpha2["Maldives"] = "MV";
  CountryAlpha2["Mali"] = "ML";
  CountryAlpha2["Malta"] = "MT";
  CountryAlpha2["MarshallIslands"] = "MH";
  CountryAlpha2["Mauritania"] = "MR";
  CountryAlpha2["Mauritius"] = "MU";
  CountryAlpha2["Mexico"] = "MX";
  CountryAlpha2["Micronesia"] = "FM";
  CountryAlpha2["Moldova"] = "MD";
  CountryAlpha2["Monaco"] = "MC";
  CountryAlpha2["Mongolia"] = "MN";
  CountryAlpha2["Montenegro"] = "ME";
  CountryAlpha2["Morocco"] = "MA";
  CountryAlpha2["Mozambique"] = "MZ";
  CountryAlpha2["Myanmar"] = "MM";
  CountryAlpha2["Namibia"] = "NA";
  CountryAlpha2["Nauru"] = "NR";
  CountryAlpha2["Nepal"] = "NP";
  CountryAlpha2["Netherlands"] = "NL";
  CountryAlpha2["NewZealand"] = "NZ";
  CountryAlpha2["Nicaragua"] = "NI";
  CountryAlpha2["Niger"] = "NE";
  CountryAlpha2["Nigeria"] = "NG";
  CountryAlpha2["NorthMacedonia"] = "MK";
  CountryAlpha2["Norway"] = "NO";
  CountryAlpha2["Oman"] = "OM";
  CountryAlpha2["Pakistan"] = "PK";
  CountryAlpha2["Palau"] = "PW";
  CountryAlpha2["Panama"] = "PA";
  CountryAlpha2["PapuaNewGuinea"] = "PG";
  CountryAlpha2["Paraguay"] = "PY";
  CountryAlpha2["Peru"] = "PE";
  CountryAlpha2["Philippines"] = "PH";
  CountryAlpha2["Poland"] = "PL";
  CountryAlpha2["Portugal"] = "PT";
  CountryAlpha2["Qatar"] = "QA";
  CountryAlpha2["Romania"] = "RO";
  CountryAlpha2["Russia"] = "RU";
  CountryAlpha2["Rwanda"] = "RW";
  CountryAlpha2["SaintKittsAndNevis"] = "KN";
  CountryAlpha2["SaintLucia"] = "LC";
  CountryAlpha2["SaintVincentAndTheGrenadines"] = "VC";
  CountryAlpha2["Samoa"] = "WS";
  CountryAlpha2["SanMarino"] = "SM";
  CountryAlpha2["SaoTomeAndPrincipe"] = "ST";
  CountryAlpha2["SaudiArabia"] = "SA";
  CountryAlpha2["Senegal"] = "SN";
  CountryAlpha2["Serbia"] = "RS";
  CountryAlpha2["Seychelles"] = "SC";
  CountryAlpha2["SierraLeone"] = "SL";
  CountryAlpha2["Singapore"] = "SG";
  CountryAlpha2["Slovakia"] = "SK";
  CountryAlpha2["Slovenia"] = "SI";
  CountryAlpha2["SolomonIslands"] = "SB";
  CountryAlpha2["Somalia"] = "SO";
  CountryAlpha2["SouthAfrica"] = "ZA";
  CountryAlpha2["SouthSudan"] = "SS";
  CountryAlpha2["Spain"] = "ES";
  CountryAlpha2["SriLanka"] = "LK";
  CountryAlpha2["Sudan"] = "SD";
  CountryAlpha2["Suriname"] = "SR";
  CountryAlpha2["Sweden"] = "SE";
  CountryAlpha2["Switzerland"] = "CH";
  CountryAlpha2["Syria"] = "SY";
  CountryAlpha2["Taiwan"] = "TW";
  CountryAlpha2["Tajikistan"] = "TJ";
  CountryAlpha2["Tanzania"] = "TZ";
  CountryAlpha2["Thailand"] = "TH";
  CountryAlpha2["Togo"] = "TG";
  CountryAlpha2["Tonga"] = "TO";
  CountryAlpha2["TrinidadAndTobago"] = "TT";
  CountryAlpha2["Tunisia"] = "TN";
  CountryAlpha2["Turkey"] = "TR";
  CountryAlpha2["Turkmenistan"] = "TM";
  CountryAlpha2["Tuvalu"] = "TV";
  CountryAlpha2["Uganda"] = "UG";
  CountryAlpha2["Ukraine"] = "UA";
  CountryAlpha2["UnitedArabEmirates"] = "AE";
  CountryAlpha2["UnitedKingdom"] = "GB";
  CountryAlpha2["UnitedStates"] = "US";
  CountryAlpha2["Uruguay"] = "UY";
  CountryAlpha2["Uzbekistan"] = "UZ";
  CountryAlpha2["Vanuatu"] = "VU";
  CountryAlpha2["VaticanCity"] = "VA";
  CountryAlpha2["Venezuela"] = "VE";
  CountryAlpha2["Vietnam"] = "VN";
  CountryAlpha2["Yemen"] = "YE";
  CountryAlpha2["Zambia"] = "ZM";
  CountryAlpha2["Zimbabwe"] = "ZW";
})(CountryAlpha2 = exports.CountryAlpha2 || (exports.CountryAlpha2 = {}));
