import { Pipe, PipeTransform } from '@angular/core';

import { getNestedValue } from '../helpers';

@Pipe({
  name: 'nestedValue',
})
export class NestedValuePipe implements PipeTransform {
  transform<T extends object>(obj: T, path: string): string {
    return getNestedValue<T, string>(obj, path);
  }
}
