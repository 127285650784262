import { ToggleFeature } from './toggle-features';

export function hasUnleashAccess(
  togglesToCheckAgainst: string[],
  togglesToCheckFor?: ToggleFeature | ToggleFeature[],
): boolean {
  if (!togglesToCheckFor) {
    return true;
  }

  const togglesToCheckForList = Array.isArray(togglesToCheckFor) ? togglesToCheckFor : [togglesToCheckFor];
  const togglesToCheckAgainstList = Array.isArray(togglesToCheckAgainst)
    ? togglesToCheckAgainst
    : [togglesToCheckAgainst];

  return togglesToCheckForList.some(toggle => togglesToCheckAgainstList.includes(toggle));
}
