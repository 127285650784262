<div class="supy-aside-layout">
  <aside class="supy-aside-layout__aside">
    <div class="supy-aside-layout__meta">
      <div class="supy-aside-layout__logo">
        <supy-image src="/assets/images/supy-logo-square.svg"></supy-image>
      </div>
      <p>Your Back Of House Spending, Controlled</p>
    </div>
    <!--googleoff: all-->
    <small class="supy-aside-layout__version-hash" data-nosnippet>v. {{ versionHash }}</small>
    <!--googleon: all-->
  </aside>
  <div class="supy-aside-layout__content">
    <ng-content></ng-content>
  </div>
</div>
