import { ConnectedPositioningStrategy, PositionSettings } from '@infragistics/igniteui-angular';
import { Size } from '@infragistics/igniteui-angular/lib/services/overlay/utilities';

export class ComboboxPositionStrategy extends ConnectedPositioningStrategy {
  constructor(settings: PositionSettings) {
    super(settings);
  }

  override position(
    contentElement: HTMLElement,
    size: Size,
    document?: Document,
    initialCall?: boolean,
    target?: HTMLElement,
  ): void {
    const comboBox: HTMLElement = target.closest('.igx-combo');

    super.position(contentElement, size, document, initialCall, comboBox);
  }
}
