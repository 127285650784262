import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { InputModule } from '../input';
import { CounterInputComponent } from './components';

@NgModule({
  declarations: [CounterInputComponent],
  imports: [CommonModule, InputModule, ButtonModule, IconModule],
  exports: [CounterInputComponent],
})
export class CounterInputModule {}
