import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxAvatarModule } from '@infragistics/igniteui-angular';

import { AvatarComponent } from './components';

@NgModule({
  declarations: [AvatarComponent],
  imports: [CommonModule, IgxAvatarModule],
  exports: [AvatarComponent],
})
export class AvatarModule {}
