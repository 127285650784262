import { Language } from '@supy.api/dictionaries';

export class UserSettings {
  readonly chat?: boolean;
  readonly order?: boolean;
  readonly showApproxPrice?: boolean;
  readonly sendNewOrderEmail?: boolean;
  readonly viewCategory?: boolean;
  readonly preferredLanguage?: Language;

  constructor(args?: Partial<UserSettings>) {
    this.chat = args?.chat;
    this.order = args?.order;
    this.showApproxPrice = args?.showApproxPrice;
    this.sendNewOrderEmail = args?.sendNewOrderEmail;
    this.viewCategory = args?.viewCategory;
    this.preferredLanguage = args?.preferredLanguage;
  }
}
