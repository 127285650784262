import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxTimePickerModule } from '@infragistics/igniteui-angular';

import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { InputHintsModule } from '../input-hints';
import { TimePickerComponent } from './components';

@NgModule({
  declarations: [TimePickerComponent],
  imports: [CommonModule, IconModule, ButtonModule, InputHintsModule, IgxTimePickerModule],
  exports: [TimePickerComponent, InputHintsModule],
})
export class TimePickerModule {}
