import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IANATimezone } from '@supy.api/dictionaries';

import { BaseActivity } from '../../core';

@Component({
  selector: 'supy-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityLogComponent<T extends BaseActivity = BaseActivity> {
  @Input({ required: true }) readonly activities: T[];
  @Input() readonly ianaTimeZone: IANATimezone;
  @Input() readonly dateActionFirst: boolean;
}
