import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { IgxDropDownModule, IgxToggleModule } from '@infragistics/igniteui-angular';

import { IconModule } from '../icon';
import { DropdownComponent, DropdownItemComponent, DropdownToggleComponent } from './components';

@NgModule({
  declarations: [DropdownComponent, DropdownItemComponent, DropdownToggleComponent],
  imports: [
    CommonModule,
    RouterModule,
    IgxDropDownModule,
    IgxToggleModule,
    IconModule,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
  ],
  exports: [DropdownComponent, DropdownItemComponent, DropdownToggleComponent],
})
export class DropdownModule {}
