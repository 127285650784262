import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function customRegex(pattern: RegExp | string, flags?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;

    if (!value) {
      return null;
    }

    const regex = new RegExp(pattern, flags);

    if (!regex.test(value)) {
      return { customRegex: true };
    }

    return null;
  };
}
