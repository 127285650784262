<div class="supy-color-picker">
  <div #picker></div>
  <igx-input-group>
    <input type="hidden" igxInput />
    <igx-hint class="supy-input__hint-placeholder">
      <supy-input-hint-container>
        <ng-content select="supy-input-error"></ng-content>
        <ng-content select="supy-input-hint"></ng-content>
      </supy-input-hint-container>
    </igx-hint>
  </igx-input-group>
</div>
