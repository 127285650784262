"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AtlanticTimezone = void 0;
var AtlanticTimezone;
(function (AtlanticTimezone) {
  AtlanticTimezone["Azores"] = "Atlantic/Azores";
  AtlanticTimezone["Bermuda"] = "Atlantic/Bermuda";
  AtlanticTimezone["Canary"] = "Atlantic/Canary";
  AtlanticTimezone["Cape_Verde"] = "Atlantic/Cape_Verde";
  AtlanticTimezone["Faroe"] = "Atlantic/Faroe";
  AtlanticTimezone["Madeira"] = "Atlantic/Madeira";
  AtlanticTimezone["South_Georgia"] = "Atlantic/South_Georgia";
  AtlanticTimezone["Stanley"] = "Atlantic/Stanley";
})(AtlanticTimezone = exports.AtlanticTimezone || (exports.AtlanticTimezone = {}));
