/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
import { millisToHrTime } from '@opentelemetry/core';
/**
 * An internal opaque interface that the MetricReader receives as
 * MetricProducer. It acts as the storage key to the internal metric stream
 * state for each MetricReader.
 */
var MetricCollector = /** @class */function () {
  function MetricCollector(_sharedState, _metricReader) {
    this._sharedState = _sharedState;
    this._metricReader = _metricReader;
  }
  MetricCollector.prototype.collect = function (options) {
    return __awaiter(this, void 0, void 0, function () {
      var collectionTime, scopeMetrics, errors, meterCollectionPromises;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            collectionTime = millisToHrTime(Date.now());
            scopeMetrics = [];
            errors = [];
            meterCollectionPromises = Array.from(this._sharedState.meterSharedStates.values()).map(function (meterSharedState) {
              return __awaiter(_this, void 0, void 0, function () {
                var current;
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      return [4 /*yield*/, meterSharedState.collect(this, collectionTime, options)];
                    case 1:
                      current = _a.sent();
                      // only add scope metrics if available
                      if ((current === null || current === void 0 ? void 0 : current.scopeMetrics) != null) {
                        scopeMetrics.push(current.scopeMetrics);
                      }
                      // only add errors if available
                      if ((current === null || current === void 0 ? void 0 : current.errors) != null) {
                        errors.push.apply(errors, __spreadArray([], __read(current.errors), false));
                      }
                      return [2 /*return*/];
                  }
                });
              });
            });
            return [4 /*yield*/, Promise.all(meterCollectionPromises)];
          case 1:
            _a.sent();
            return [2 /*return*/, {
              resourceMetrics: {
                resource: this._sharedState.resource,
                scopeMetrics: scopeMetrics
              },
              errors: errors
            }];
        }
      });
    });
  };
  /**
   * Delegates for MetricReader.forceFlush.
   */
  MetricCollector.prototype.forceFlush = function (options) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this._metricReader.forceFlush(options)];
          case 1:
            _a.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  /**
   * Delegates for MetricReader.shutdown.
   */
  MetricCollector.prototype.shutdown = function (options) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this._metricReader.shutdown(options)];
          case 1:
            _a.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  MetricCollector.prototype.selectAggregationTemporality = function (instrumentType) {
    return this._metricReader.selectAggregationTemporality(instrumentType);
  };
  MetricCollector.prototype.selectAggregation = function (instrumentType) {
    return this._metricReader.selectAggregation(instrumentType);
  };
  return MetricCollector;
}();
export { MetricCollector };
