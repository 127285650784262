import { Route } from '@angular/router';

import { ToggleFeature } from './toggle-features';

export enum UnleashContextScope {
  User = 'userId',
  Retailer = 'retailerId',
  Branch = 'branchId',
  Id = 'id',
}

export enum UnleashContextExtractFrom {
  Params = 'params',
  Query = 'query',
  Data = 'data',
}

export interface BaseUnleashContext {
  readonly scope?: UnleashContextScope;
  readonly extractContextFrom?: UnleashContextExtractFrom;
}

export interface UnleashResolverContext extends BaseUnleashContext {}

export interface UnleashGuardContext extends BaseUnleashContext {
  readonly toggles: ToggleFeature[];
}

export interface UnleashContextData<T> {
  readonly unleashContext?: T;
}

export type UnleashResolverRouteData = UnleashContextData<UnleashResolverContext>;
export type UnleashGuardRouteData = UnleashContextData<UnleashGuardContext>;

export interface UnleashRoute extends Route {
  readonly data?: UnleashResolverRouteData | UnleashGuardRouteData | Record<string, string | boolean | object>;
}
