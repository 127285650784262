import { useAnimation } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  AutoPositionStrategy,
  DatePartDeltas,
  IgxTimePickerComponent,
  OverlaySettings,
  PickerInteractionMode,
} from '@infragistics/igniteui-angular';
import { swingInTopBck, swingOutTopBck } from '@infragistics/igniteui-angular/animations';

import { MenuPosition, menuPositionSettings } from '../../../dropdown';
import { IconType } from '../../../icon';
import { InputDensity } from '../../../input';

type TimePickerFormat = 'hh:mm' | 'hh:mm tt';

@Component({
  selector: 'supy-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePickerComponent<T extends Date> implements OnInit, ControlValueAccessor {
  @Input() readonly min?: T;
  @Input() readonly max?: T;
  @Input() readonly placeholder?: string;
  @Input() @HostBinding('attr.name') readonly name: string;
  @Input() readonly format?: TimePickerFormat = 'hh:mm tt';
  @Input() readonly mode?: PickerInteractionMode = PickerInteractionMode.Dialog;
  @Input() readonly customToggleIcon?: IconType;
  @Input() readonly position: MenuPosition = 'bottom-right';
  @Input() readonly elementRef: ElementRef;
  @Input() set value(value: number) {
    if (!value) {
      this.#value = null;
      this.#dateValue = null;

      return;
    }
    this.writeValue(value);
  }

  get value(): number {
    return this.#value;
  }

  @Input() set disabled(disabled: boolean) {
    this.#disabled = disabled;
  }

  get disabled(): boolean {
    return this.#disabled;
  }

  @Input() readonly density: InputDensity = 'small';

  @Input() readonly itemsDelta?: Pick<DatePartDeltas, 'hours' | 'minutes' | 'seconds'> = {
    hours: 1,
    minutes: 1,
    seconds: 1,
  };

  @Output() readonly valueChanged = new EventEmitter<number>();

  @ViewChild('outlet', { static: true })
  readonly overlayOutlet: ElementRef;

  @ViewChild(IgxTimePickerComponent, { static: true })
  readonly timePicker: IgxTimePickerComponent;

  #dateValue: T;
  #value: number;
  #disabled = false;

  #overlaySettings: OverlaySettings;

  private onChange: (value: number) => void;
  private onTouched: () => void;
  private touched = false;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Optional() @Inject(NgControl) private readonly control?: NgControl,
  ) {
    if (control) {
      this.control.valueAccessor = this;
    }
  }

  get dateValue(): T {
    return this.#dateValue;
  }

  get overlaySettings(): OverlaySettings {
    return this.#overlaySettings;
  }

  async ngOnInit(): Promise<void> {
    if (this.#value === 0) {
      this.#dateValue = new Date() as T;
      this.#dateValue.setHours(0, 0, 0, 0);
    }

    const settings = menuPositionSettings[this.position];

    const positionStrategy = new AutoPositionStrategy({
      ...settings,
      openAnimation: useAnimation(swingInTopBck, { params: { duration: '500ms' } }),
      closeAnimation: useAnimation(swingOutTopBck, { params: { duration: '250ms' } }),
    });

    this.#overlaySettings = {
      outlet: this.elementRef ?? this.overlayOutlet,
      positionStrategy,
    } as const;

    await Promise.resolve().then(() => {
      this.cdr.markForCheck();
    });
  }

  onValueChange(value: string | Date): void {
    if (this.disabled) {
      return;
    }

    if (!value) {
      this.#value = null;
    } else {
      const now = value as T;

      now.setSeconds(0);

      const then = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

      this.#dateValue = now;
      this.#value = now.getTime() - then.getTime();
    }

    this.valueChanged.emit(this.#value);
    this.onChange?.(this.value);
    this.cdr.markForCheck();
  }

  onValueClear(): void {
    if (this.disabled) {
      return;
    }

    this.#dateValue = null;
    this.#value = null;
    this.timePicker.clear();

    this.cdr.markForCheck();
  }

  onFocusOut(): void {
    this.markAsTouched();
  }

  writeValue(value: number): void {
    this.#value = value;

    if (value) {
      this.#dateValue = new Date() as T;
      this.#dateValue.setHours(0, 0, 0, value);
    }
  }

  registerOnChange(onChange: (value: number) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.#disabled = disabled;
  }

  open(): void {
    this.timePicker.open();
  }

  private markAsTouched(): void {
    if (this.touched) {
      return;
    }

    this.touched = true;
    this.onTouched?.();
    this.emitTouchStatusChanged();
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
