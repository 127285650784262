import { InjectionToken, ValueProvider } from '@angular/core';

/**
 * Common Donut Chart options, they might be extended based on use case.
 */
export interface DonutChartOptions {
  /**
   * Whether the slices can be selected.
   * @default {false}
   */
  readonly allowSliceSelection: boolean;
  /**
   * The current Legend object's orientation.
   * @default {right}
   */
  readonly legendOrientation: 'left' | 'right' | 'top' | 'bottom';
  /**
   * The palette of brushes to use for the slices.
   */
  readonly brushes: string[];
}

export const DONUT_CHART_DEFAULT_OPTIONS: DonutChartOptions = {
  allowSliceSelection: false,
  legendOrientation: 'right',
  brushes: ['#6144BF', '#F1CB30', '#C95040', '#589CD1', '#70C697', '#8E7AD1', '#B0A2DF', '#D2CAED'],
};

export const DONUT_CHART_OPTIONS = new InjectionToken<DonutChartOptions>('Default config for donut chart', {
  factory: () => DONUT_CHART_DEFAULT_OPTIONS,
});

export const donutChartOptionsProvider: (options: Partial<DonutChartOptions>) => ValueProvider = (
  options: Partial<DonutChartOptions>,
) => ({
  provide: DONUT_CHART_OPTIONS,
  useValue: { ...DONUT_CHART_DEFAULT_OPTIONS, ...options },
});
