import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxCheckboxModule } from '@infragistics/igniteui-angular';

import { CheckboxComponent } from './components';

@NgModule({
  declarations: [CheckboxComponent],
  imports: [CommonModule, IgxCheckboxModule],
  exports: [CheckboxComponent],
})
export class CheckboxModule {}
