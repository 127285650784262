import { debounceTime, merge, takeUntil } from 'rxjs';
import { Directive, Inject, Input, OnInit, Optional, TemplateRef, ViewContainerRef } from '@angular/core';
import { ControlContainer, FormGroupDirective, FormGroupName, NgControl, NgForm } from '@angular/forms';

import { Destroyable } from '@supy/common';

const DEBOUNCE_LISTENER = 50;

export interface ErrorIfContext<T> {
  $implicit: T;
  supyErrorIf: T;
  supyErrorIfForm: FormGroupDirective;
}

@Directive({
  selector: '[supyErrorIf]',
  exportAs: 'errorIf',
})
export class ErrorIfDirective<T> extends Destroyable implements OnInit {
  static ngTemplateGuard_supyErrorIf: 'binding';

  @Input() readonly supyErrorIf: string;
  @Input() readonly supyErrorIfForm: FormGroupDirective;

  static ngTemplateContextGuard<T>(
    _dir: ErrorIfDirective<T>,
    _context: ErrorIfContext<boolean>,
  ): _context is ErrorIfContext<boolean> {
    return true;
  }

  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly templateRef: TemplateRef<T>,
    @Inject(NgControl)
    private readonly control: NgControl,
    @Optional()
    @Inject(ControlContainer)
    private readonly form?: NgForm | null,
  ) {
    super();
  }

  ngOnInit(): void {
    merge(this.rootFormGroup.ngSubmit, this.control.statusChanges, this.control.valueChanges)
      .pipe(debounceTime(DEBOUNCE_LISTENER), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.insertErrorTemplate();
      });
  }

  get rootFormGroup(): FormGroupDirective {
    if (this.supyErrorIfForm instanceof FormGroupDirective) {
      return this.supyErrorIfForm;
    }

    if (this.form instanceof FormGroupDirective) {
      return this.form;
    }

    if (this.form instanceof FormGroupName) {
      return this.form.formDirective as FormGroupDirective;
    }

    return this.supyErrorIfForm ?? this.form;
  }

  get hasError(): boolean {
    const submitted = this.form.submitted || (this.form.formDirective as FormGroupDirective).submitted;
    const touched = this.control.touched;
    const invalid = this.control.hasError(this.supyErrorIf);

    return invalid && (submitted || touched);
  }

  get insertError(): boolean {
    return !this.viewContainer.length;
  }

  private insertErrorTemplate(): void {
    if (this.hasError) {
      if (this.insertError) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      this.viewContainer.clear();
    }
  }
}
