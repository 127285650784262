"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IndianTimezone = void 0;
var IndianTimezone;
(function (IndianTimezone) {
  IndianTimezone["Chagos"] = "Indian/Chagos";
  IndianTimezone["Maldives"] = "Indian/Maldives";
  IndianTimezone["Mauritius"] = "Indian/Mauritius";
})(IndianTimezone = exports.IndianTimezone || (exports.IndianTimezone = {}));
