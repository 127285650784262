<div class="supy-404">
  <div class="supy-404__content">
    <div class="supy-404__header">
      <h1 class="supy-404__title">404</h1>
    </div>
    <h2 class="supy-404__subtitle" i18n="@@404.title">Looks like you're lost!</h2>
    <h4 class="supy-404__message" i18n="@@404.message">
      The page you're looking for doesn't exist, let's take you back home.
    </h4>
    <div class="supy-404__action">
      <supy-button i18n="@@404.back" color="secondary" width="full" height="full" (buttonClick)="onBackToHomeClick()"
        >Back to Home</supy-button
      >
    </div>
  </div>
</div>
