import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

export interface SupyContextContext<T> {
  $implicit: T;
  supyContext: T;
}

@Directive({
  selector: '[supyContext]',
  exportAs: 'ctx',
})
export class SupyContextDirective<T> {
  static ngTemplateGuard_supyContext: 'binding';

  viewRef?: EmbeddedViewRef<SupyContextContext<T>>;

  static ngTemplateContextGuard<T>(
    _dir: SupyContextDirective<T>,
    _context: SupyContextContext<T>,
  ): _context is SupyContextContext<T> {
    return true;
  }

  @Input() set supyContext(context: T) {
    if (!this.viewRef) {
      this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef, {
        $implicit: context,
        supyContext: context,
      });
    } else {
      this.viewRef.context.$implicit = context;
      this.viewRef.context.supyContext = context;
    }
  }

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<SupyContextContext<T>>,
  ) {}
}
