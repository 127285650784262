import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IgxTooltipModule } from '@infragistics/igniteui-angular';

import { TooltipDeprecatedComponent } from './components';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TooltipDeprecatedDirective } from './directives';

@NgModule({
  declarations: [TooltipDeprecatedComponent, TooltipDeprecatedDirective, TooltipComponent],
  imports: [CommonModule, IgxTooltipModule, MatTooltipModule],
  exports: [TooltipDeprecatedComponent, TooltipDeprecatedDirective, TooltipComponent],
})
export class TooltipModule {}
