"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Currency = void 0;
var Currency;
(function (Currency) {
  Currency["AfghanAfghani"] = "AFN";
  Currency["AlbanianLek"] = "ALL";
  Currency["ArmenianDram"] = "AMD";
  Currency["NetherlandsAntilleanGuilder"] = "ANG";
  Currency["AngolanKwanza"] = "AOA";
  Currency["ArgentinePeso"] = "ARS";
  Currency["AustralianDollar"] = "AUD";
  Currency["ArubanFlorin"] = "AWG";
  Currency["AzerbaijaniManat"] = "AZN";
  Currency["BosniaAndHerzegovinaConvertibleMark"] = "BAM";
  Currency["BarbadianDollar"] = "BBD";
  Currency["BangladeshiTaka"] = "BDT";
  Currency["BulgarianLev"] = "BGN";
  Currency["BahrainiDinar"] = "BHD";
  Currency["BurundianFranc"] = "BIF";
  Currency["BermudianDollar"] = "BMD";
  Currency["BruneiDollar"] = "BND";
  Currency["BolivianBoliviano"] = "BOB";
  Currency["BrazilianReal"] = "BRL";
  Currency["BahamianDollar"] = "BSD";
  Currency["BhutaneseNgultrum"] = "BTN";
  Currency["BotswanaPula"] = "BWP";
  Currency["BelarusianRuble"] = "BYN";
  Currency["BelizeDollar"] = "BZD";
  Currency["CanadianDollar"] = "CAD";
  Currency["CongoleseFranc"] = "CDF";
  Currency["SwissFranc"] = "CHF";
  Currency["ChileanPeso"] = "CLP";
  Currency["ChineseYuan"] = "CNY";
  Currency["ColombianPeso"] = "COP";
  Currency["CostaRicanColon"] = "CRC";
  Currency["CubanConvertiblePeso"] = "CUC";
  Currency["CubanPeso"] = "CUP";
  Currency["CapeVerdeanEscudo"] = "CVE";
  Currency["CzechKoruna"] = "CZK";
  Currency["DjiboutianFranc"] = "DJF";
  Currency["DanishKrone"] = "DKK";
  Currency["DominicanPeso"] = "DOP";
  Currency["AlgerianDinar"] = "DZD";
  Currency["EgyptianPound"] = "EGP";
  Currency["EritreanNakfa"] = "ERN";
  Currency["EthiopianBirr"] = "ETB";
  Currency["Euro"] = "EUR";
  Currency["FijianDollar"] = "FJD";
  Currency["FalklandIslandsPound"] = "FKP";
  Currency["PoundSterling"] = "GBP";
  Currency["GeorgianLari"] = "GEL";
  Currency["GuernseyPound"] = "GGP";
  Currency["GhanaianCedi"] = "GHS";
  Currency["GibraltarPound"] = "GIP";
  Currency["GambianDalasi"] = "GMD";
  Currency["GuineanFranc"] = "GNF";
  Currency["GuatemalanQuetzal"] = "GTQ";
  Currency["GuyaneseDollar"] = "GYD";
  Currency["HongKongDollar"] = "HKD";
  Currency["HonduranLempira"] = "HNL";
  Currency["CroatianKuna"] = "HRK";
  Currency["HaitianGourde"] = "HTG";
  Currency["HungarianForint"] = "HUF";
  Currency["IndonesianRupiah"] = "IDR";
  Currency["IsraeliNewShekel"] = "ILS";
  Currency["ManxPound"] = "IMP";
  Currency["IndianRupee"] = "INR";
  Currency["IraqiDinar"] = "IQD";
  Currency["IranianRial"] = "IRR";
  Currency["IcelandicKrona"] = "ISK";
  Currency["JerseyPound"] = "JEP";
  Currency["JamaicanDollar"] = "JMD";
  Currency["JordanianDinar"] = "JOD";
  Currency["JapaneseYen"] = "JPY";
  Currency["KenyanShilling"] = "KES";
  Currency["KyrgyzstaniSom"] = "KGS";
  Currency["CambodianRiel"] = "KHR";
  Currency["ComorianFranc"] = "KMF";
  Currency["NorthKoreanWon"] = "KPW";
  Currency["SouthKoreanWon"] = "KRW";
  Currency["KuwaitiDinar"] = "KWD";
  Currency["CaymanIslandsDollar"] = "KYD";
  Currency["KazakhstaniTenge"] = "KZT";
  Currency["LaotianKip"] = "LAK";
  Currency["LebanesePound"] = "LBP";
  Currency["SriLankanRupee"] = "LKR";
  Currency["LiberianDollar"] = "LRD";
  Currency["LesothoLoti"] = "LSL";
  Currency["LibyanDinar"] = "LYD";
  Currency["MoroccanDirham"] = "MAD";
  Currency["MoldovanLeu"] = "MDL";
  Currency["MalagasyAriary"] = "MGA";
  Currency["MacedonianDenar"] = "MKD";
  Currency["BurmeseKyat"] = "MMK";
  Currency["MongolianTogrog"] = "MNT";
  Currency["MacanesePataca"] = "MOP";
  Currency["MauritanianOuguiya"] = "MRU";
  Currency["MauritianRupee"] = "MUR";
  Currency["MaldivianRufiyaa"] = "MVR";
  Currency["MalawianKwacha"] = "MWK";
  Currency["MexicanPeso"] = "MXN";
  Currency["MalaysianRinggit"] = "MYR";
  Currency["MozambicanMetical"] = "MZN";
  Currency["NamibianDollar"] = "NAD";
  Currency["NigerianNaira"] = "NGN";
  Currency["NicaraguanCordoba"] = "NIO";
  Currency["NorwegianKrone"] = "NOK";
  Currency["NepaleseRupee"] = "NPR";
  Currency["NewZealandDollar"] = "NZD";
  Currency["OmaniRial"] = "OMR";
  Currency["PanamanianBalboa"] = "PAB";
  Currency["PeruvianSol"] = "PEN";
  Currency["PapuaNewGuineanKina"] = "PGK";
  Currency["PhilippinePeso"] = "PHP";
  Currency["PakistaniRupee"] = "PKR";
  Currency["PolishZloty"] = "PLN";
  Currency["ParaguayanGuarani"] = "PYG";
  Currency["QatariRiyal"] = "QAR";
  Currency["RomanianLeu"] = "RON";
  Currency["SerbianDinar"] = "RSD";
  Currency["RussianRuble"] = "RUB";
  Currency["RwandanFranc"] = "RWF";
  Currency["SaudiRiyal"] = "SAR";
  Currency["SolomonIslandsDollar"] = "SBD";
  Currency["SeychelloisRupee"] = "SCR";
  Currency["SudanesePound"] = "SDG";
  Currency["SwedishKrona"] = "SEK";
  Currency["SingaporeDollar"] = "SGD";
  Currency["SaintHelenaPound"] = "SHP";
  Currency["SierraLeoneanLeone"] = "SLL";
  Currency["SomaliShilling"] = "SOS";
  Currency["SurinameseDollar"] = "SRD";
  Currency["SaoTomeAndPrincipeDobra"] = "STN";
  Currency["SalvadoranColon"] = "SVC";
  Currency["SyrianPound"] = "SYP";
  Currency["SwaziLilangeni"] = "SZL";
  Currency["ThaiBaht"] = "THB";
  Currency["TajikistaniSomoni"] = "TJS";
  Currency["TurkmenistanManat"] = "TMT";
  Currency["TunisianDinar"] = "TND";
  Currency["TonganPaanga"] = "TOP";
  Currency["TurkishLira"] = "TRY";
  Currency["TrinidadAndTobagoDollar"] = "TTD";
  Currency["NewTaiwanDollar"] = "TWD";
  Currency["TanzanianShilling"] = "TZS";
  Currency["UkrainianHryvnia"] = "UAH";
  Currency["UgandanShilling"] = "UGX";
  Currency["UnitedArabEmiratesDirham"] = "AED";
  Currency["UnitedStatesDollar"] = "USD";
  Currency["UruguayanPeso"] = "UYU";
  Currency["UzbekistaniSom"] = "UZS";
  Currency["VenezuelanBolivar"] = "VEF";
  Currency["VietnameseDong"] = "VND";
  Currency["VanuatuVatu"] = "VUV";
  Currency["SamoanTala"] = "WST";
  Currency["CentralAfricanCFAFranc"] = "XAF";
  Currency["EastCaribbeanDollar"] = "XCD";
  Currency["WestAfricanCFAFranc"] = "XOF";
  Currency["CFPFranc"] = "XPF";
  Currency["YemeniRial"] = "YER";
  Currency["SouthAfricanRand"] = "ZAR";
  Currency["ZambianKwacha"] = "ZMW";
  Currency["ZimbabweanDollar"] = "ZWL";
})(Currency = exports.Currency || (exports.Currency = {}));
