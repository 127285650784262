import type {
  Alignment,
  Border,
  Borders,
  BorderStyle,
  Cell,
  Column,
  FillPattern,
  Style,
  Workbook,
  Worksheet,
} from 'exceljs';

export const DEFAULT_CELL_FONT = {
  name: 'Calibri',
  family: 2,
  bold: false,
  size: 11,
};

export const HEADER_BACKGROUND_COLOR = 'E5E8F4';

export const ACRONYMS_LIST = ['PO', 'FOC', 'COGS', 'QTY'];

export interface CellFormatOptions {
  readonly qtyCols?: Set<string>;
  readonly currencyCols?: Set<string>;
  readonly dateCols?: Set<string>;
  readonly percentCols?: Set<string>;
}

export interface DataColumn {
  readonly header: string;
  readonly key: string;
}

export interface CreateWorksheetOptions extends CellFormatOptions {
  data: unknown[];
  columns?: (string | DataColumn)[];
}

export async function createWorksheet(
  workbook: Workbook,
  name: string,
  options: CreateWorksheetOptions,
): Promise<Worksheet> {
  const { data, columns, ...formatCellOptions } = options;
  const hasColumns = Boolean(columns?.length);
  const worksheet = workbook.addWorksheet(
    name,
    hasColumns
      ? {
          views: [{ state: 'frozen', ySplit: 1 }],
        }
      : undefined,
  );

  if (hasColumns) {
    worksheet.columns =
      typeof columns[0] === 'string' ? columns.map(header => getTransformedHeader(header as string)) : columns;
  }
  worksheet.addRows(data);

  await updateSheetStyles(worksheet, formatCellOptions, hasColumns);

  return worksheet;
}

export async function updateSheetStyles(sheet: Worksheet, formatCellOptions: CellFormatOptions, hasColumns: boolean) {
  const { ValueType } = await import('exceljs');
  const headerRow = sheet.getRow(1);
  const headerFill: FillPattern = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: HEADER_BACKGROUND_COLOR },
  };
  const headerBorder: Partial<Borders> = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };

  if (hasColumns) {
    headerRow.height = 18;

    headerRow.eachCell(function (cell, colNumber) {
      cell.font = { ...DEFAULT_CELL_FONT, bold: true };
      cell.fill = headerFill;
      cell.border = headerBorder;
      cell.alignment = {
        vertical: 'middle',
      };
    });
  }

  sheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
    if (hasColumns && rowNumber === 1) {
      return;
    }

    row.eachCell(function (cell: Cell, colNumber) {
      cell.font = DEFAULT_CELL_FONT;

      const col: Column = sheet.getColumn(colNumber);

      formatCell(cell, col.key, formatCellOptions);
    });

    row.height = 18;
  });

  sheet.columns.forEach(column => {
    let maxLength = 0;

    column.eachCell?.({ includeEmpty: true }, cell => {
      const columnLength = cell.value ? (cell.value as string).toString().length + 3 : 10;

      if (cell.type === (ValueType?.Date || 4)) {
        maxLength = 20;
      } else if (columnLength < 30 && columnLength > maxLength) {
        maxLength = columnLength + 3;
      }
    });
    column.width = maxLength < 10 ? 10 : maxLength;
  });
}

export function getTransformedHeader(key: string): Partial<Column> {
  const header = camelCaseToTitleCaseWithAcronyms(key);

  return { header, key };
}

export function createOuterBorder(
  worksheet: Worksheet,
  start = { row: 1, col: 1 },
  end = { row: 1, col: 1 },
  borderWidth: BorderStyle = 'medium',
) {
  const borderStyle: Partial<Border> = {
    style: borderWidth,
  };

  for (let i = start.row; i <= end.row; i++) {
    const leftBorderCell = worksheet.getCell(i, start.col);
    const rightBorderCell = worksheet.getCell(i, end.col);

    leftBorderCell.border = {
      ...leftBorderCell.border,
      left: borderStyle,
    };
    rightBorderCell.border = {
      ...rightBorderCell.border,
      right: borderStyle,
    };
  }

  for (let i = start.col; i <= end.col; i++) {
    const topBorderCell = worksheet.getCell(start.row, i);
    const bottomBorderCell = worksheet.getCell(end.row, i);

    topBorderCell.border = {
      ...topBorderCell.border,
      top: borderStyle,
    };
    bottomBorderCell.border = {
      ...bottomBorderCell.border,
      bottom: borderStyle,
    };
  }
}

export function camelCaseToTitleCaseWithAcronyms(input: string, acronyms: string[] = ACRONYMS_LIST) {
  const acronymPattern = acronyms.join('|');
  const pattern = new RegExp(`(${acronymPattern}|[A-Z]?[a-z]+)|[A-Z]+|[0-9]+`, 'g');

  const words = input.match(pattern) || [];

  const titleCaseWords = words.map((word: string) => {
    const isAcronym = acronyms.includes(word);

    return isAcronym ? word : `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`;
  });

  return titleCaseWords.join(' ');
}

export function formatCell(
  cell: Cell,
  key: string,
  { qtyCols, currencyCols, dateCols, percentCols }: CellFormatOptions,
) {
  if (qtyCols?.has(key)) {
    formatQuantityCell(cell);
  } else if (currencyCols?.has(key)) {
    formatPriceCell(cell);
  } else if (dateCols?.has(key)) {
    cell.value === 0 ? (cell.value = '-') : formatDateCell(cell);
  } else if (percentCols?.has(key)) {
    formatPercentageCell(cell);
  }
}

/**
 * Sets number format for defined range of columns
 *
 * @param {Style} style - ExcelJS Style to assign alignment
 * @param {Alignment} [alignment] - column alignment. If nothing passed, will align to the right horizontally
 */
export function alignStyle(style: Partial<Style>, alignment: Partial<Alignment> = { horizontal: 'right' }) {
  if (alignment) {
    style.alignment = alignment;
  }
}

/**
 * Sets number format for defined range of columns
 *
 * @param {Style} style - ExcelJS Style to update
 * @param {string} [format] - any Excel number format: "$0.00" or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)'
 */
export function formatNumberCell(style: Partial<Style>, format?: string) {
  if (format) {
    style.numFmt = format;
    alignStyle(style);
  }
}

/**
 * Sets percentage format for defined range of columns
 * Format is '0.00%' - display percentages with 2 decimal places.
 *
 * @param {Style} style - ExcelJS Style to update
 */
export function formatPercentageCell(style: Partial<Style>) {
  const format = '0.00%';

  formatNumberCell(style, format);
}

/**
 * Sets default quantity format for defined range of columns
 * Format is '#,##0.000' - display a thousands separator and 3 decimal places.
 *
 * @param {Style} style - ExcelJS Style to update
 */
export function formatQuantityCell(style: Partial<Style>) {
  const format = '#,##0.000';

  formatNumberCell(style, format);
}

/**
 * Sets default price format for defined range of columns
 * Format is '#,##0.00' - display a thousands separator and 2 decimal places.
 *
 * @param {Style} style - ExcelJS Style to update
 */
export function formatPriceCell(style: Partial<Style>) {
  const format = '#,##0.00';

  formatNumberCell(style, format);
}

/**
 * Sets default date format for defined range of columns
 * Format is 'dd-mmm-yy' - 07-Oct-22.
 *
 * @param {Style} style - ExcelJS Style to update
 */
export function formatDateCell(style: Partial<Style>) {
  const format = 'dd-mmm-yy';

  formatNumberCell(style, format);
}

export function saveWorkbook(workbook: Workbook, fileName: string): void {
  void workbook.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveExcelBlob(blob, fileName);
  });
}

export function saveExcelBlob(blob: Blob, name: string) {
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');

  anchor.href = url;
  anchor.download = name;
  anchor.click();
  window.URL.revokeObjectURL(url);
}
