"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AustraliaTimezone = void 0;
var AustraliaTimezone;
(function (AustraliaTimezone) {
  AustraliaTimezone["Lord_Howe"] = "Australia/Lord_Howe";
  AustraliaTimezone["Macquarie"] = "Antarctica/Macquarie";
  AustraliaTimezone["Hobart"] = "Australia/Hobart";
  AustraliaTimezone["Melbourne"] = "Australia/Melbourne";
  AustraliaTimezone["Sydney"] = "Australia/Sydney";
  AustraliaTimezone["Broken_Hill"] = "Australia/Broken_Hill";
  AustraliaTimezone["Brisbane"] = "Australia/Brisbane";
  AustraliaTimezone["Lindeman"] = "Australia/Lindeman";
  AustraliaTimezone["Adelaide"] = "Australia/Adelaide";
  AustraliaTimezone["Darwin"] = "Australia/Darwin";
  AustraliaTimezone["Perth"] = "Australia/Perth";
  AustraliaTimezone["Eucla"] = "Australia/Eucla";
})(AustraliaTimezone = exports.AustraliaTimezone || (exports.AustraliaTimezone = {}));
