import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../icon';
import { LoaderModule } from '../loader';
import { LoadingOverlayComponent } from './components';

@NgModule({
  declarations: [LoadingOverlayComponent],
  imports: [CommonModule, IconModule, LoaderModule],
  exports: [LoadingOverlayComponent],
})
export class LoadingOverlayModule {}
