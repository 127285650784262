<section
  class="statistics-card"
  [ngClass]="{
    'statistics-card--active': isActive,
    'statistics-card--clickable': statisticCard?.clickable
  }"
>
  <div *ngIf="loading" class="statistics-card--loading"></div>
  <div *ngIf="!loading && statisticCard" class="statistics-card__main">
    <div class="statistics-card__main-text">
      <header>
        <h5 class="statistics-card__title">{{ statisticCard?.title }} {{ currency ? '(' + currency + ')' : '' }}</h5>
      </header>
      <p>
        <span class="statistics-card__value">
          {{ statisticCard?.value | supyPrecision: statisticCard?.precision ?? 2 | number: '1.' }}
        </span>
      </p>
    </div>
    <div class="statistics-card__main-icon">
      <supy-icon
        size="large"
        shape="rounded"
        roundedBackgroundSize="large"
        [name]="statisticCard?.icon?.name"
        [color]="statisticCard?.icon?.color"
      ></supy-icon>
    </div>
  </div>
</section>
