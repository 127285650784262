import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IgxToastModule } from '@infragistics/igniteui-angular';

import { VersionCheckComponent } from './components';

@NgModule({
  declarations: [VersionCheckComponent],
  imports: [CommonModule, ServiceWorkerModule, IgxToastModule],
  exports: [VersionCheckComponent],
})
export class VersionCheckModule {}
