export enum InventoryEventType {
  Wastage = 'wastage',
  Production = 'production',
  Transfer = 'transfer',
  StockCount = 'stock-count',
  Grn = 'grn',
  SupplierReturn = 'supplier-return',
  SalesImport = 'sales-import',
  Recipe = 'recipe',
}
