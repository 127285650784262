export function assertProcessEnv() {
  globalThis.process = {
    env: new Proxy({} as NodeJS.ProcessEnv, {
      get(object: NodeJS.ProcessEnv, prop: string) {
        if (prop in object) {
          return object[prop];
        }

        if (isOpenTelemetryConfig(prop) || isSupportConfig(prop)) {
          return;
        }

        throw new TypeError(`Environment variable "${prop}" is not defined!`);
      },
    }),
  } as NodeJS.Process;
}

const OTEL_PROPERTIES = new Set([
  'CONTAINER_NAME',
  'ECS_CONTAINER_METADATA_URI_V4',
  'ECS_CONTAINER_METADATA_URI',
  'HOSTNAME',
  'KUBERNETES_SERVICE_HOST',
  'NAMESPACE',
]);

const SUPPORT_PROPERTIES = new Set(['VERSION_HASH', 'NODE_DEBUG']);

function isOpenTelemetryConfig(property: string): boolean {
  return property.startsWith('OTEL_') || OTEL_PROPERTIES.has(property);
}

function isSupportConfig(property: string): boolean {
  return SUPPORT_PROPERTIES.has(property);
}
