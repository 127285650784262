import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxInputGroupModule, IgxTextSelectionModule } from '@infragistics/igniteui-angular';

import { SupyCommonModule } from '@supy/common';

import { IconModule } from '../icon';
import { InputHintsModule } from '../input-hints';
import { LoaderModule } from '../loader';
import {
  AddedContentTemplateDirective,
  InputComponent,
  InputGroupComponent,
  PrefixTemplateDirective,
  SuffixTemplateDirective,
} from './components';

@NgModule({
  declarations: [
    InputComponent,
    PrefixTemplateDirective,
    SuffixTemplateDirective,
    AddedContentTemplateDirective,
    InputGroupComponent,
  ],
  imports: [
    CommonModule,
    IgxInputGroupModule,
    IconModule,
    InputHintsModule,
    IgxTextSelectionModule,
    SupyCommonModule,
    LoaderModule,
  ],
  exports: [
    InputComponent,
    InputHintsModule,
    PrefixTemplateDirective,
    SuffixTemplateDirective,
    AddedContentTemplateDirective,
    InputGroupComponent,
  ],
})
export class InputModule {}
