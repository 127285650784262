import { Observable } from 'rxjs';
import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';
import { IgxIconLoadedEvent, IgxIconService } from '@infragistics/igniteui-angular';

import { IconSetComponent } from '../components/icon-set';

@Injectable({ providedIn: 'root' })
export class IconRegistryService {
  static readonly FAMILY: string = 'supy-icons';
  readonly iconLoaded: Observable<IgxIconLoadedEvent> = this.igxIconService.iconLoaded;
  private iconList: string[];
  private readonly svgSprite: string;

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly igxIconService: IgxIconService,
  ) {
    const componentFactory: ComponentFactory<IconSetComponent> =
      this.componentFactoryResolver.resolveComponentFactory(IconSetComponent);
    const component: ComponentRef<IconSetComponent> = componentFactory.create(Injector.create({ providers: [] }));
    const element: HTMLElement = component.location.nativeElement as HTMLElement;
    const componentTemplate: string = element.innerHTML;

    const svgs = element.querySelectorAll('defs > svg');

    svgs.forEach(({ id, outerHTML }) => this.addSvgIconFromText(id, outerHTML));

    this.svgSprite = componentTemplate;
  }

  getAvailableIconList(): string[] {
    if (!this.iconList) {
      this.iconList = [];

      const shapeIdRegexp = /<svg[^>]*id="([\w-]+)"[^>]*>/g;
      let match: RegExpExecArray = shapeIdRegexp.exec(this.svgSprite);

      while (match !== null) {
        this.iconList.push(match[1]);
        match = shapeIdRegexp.exec(this.svgSprite);
      }
    }

    return this.iconList;
  }

  isCached(name: string, family = IconRegistryService.FAMILY): boolean {
    return this.igxIconService.isSvgIconCached(name, family);
  }

  addSvgIcon(name: string, url: string, family = IconRegistryService.FAMILY): void {
    this.igxIconService.addSvgIcon(name, url, family);
  }

  addSvgIconFromText(name: string, text: string, family = IconRegistryService.FAMILY): void {
    this.igxIconService.addSvgIconFromText(name, text, family);
  }
}
