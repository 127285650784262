import { animate, state, style, transition, trigger } from '@angular/animations';

export const popoverAnimation = trigger('animation', [
  state(
    'hidden',
    style({
      opacity: 0,
      transform: 'scale(0.8)',
    }),
  ),
  state(
    'visible',
    style({
      opacity: 1,
      transform: 'scale(1)',
    }),
  ),
  transition('hidden <=> visible', animate('0.15s ease-in-out')),
]);
