<div class="supy-time-picker" #outlet>
  <igx-time-picker
    #timePicker
    class="{{ density }}"
    [mode]="mode"
    [minValue]="min"
    [maxValue]="max"
    [inputFormat]="format"
    [attr.data-name]="name"
    [value]="dateValue"
    [disabled]="disabled"
    [overlaySettings]="overlaySettings"
    [placeholder]="placeholder ?? (format | uppercase)"
    [itemsDelta]="itemsDelta"
    [spinLoop]="false"
    theme="material"
    type="border"
    (valueChange)="onValueChange($event)"
    (focusout)="onFocusOut()"
    (closed)="onFocusOut()"
    [outlet]="elementRef ?? overlayOutlet"
  >
    <igx-picker-toggle igxPrefix class="supy-time-picker__toggle">
      <supy-icon [name]="customToggleIcon ?? 'clock'" size="small" color="gray"></supy-icon>
    </igx-picker-toggle>

    <igx-picker-clear igxSuffix *ngIf="value" (click)="onValueClear()" class="supy-time-picker__toggle">
      <supy-icon name="close-circle" size="small" [color]="disabled ? 'gray' : 'error'"></supy-icon>
    </igx-picker-clear>

    <igx-hint class="supy-input__hint-placeholder">
      <supy-input-hint-container>
        <ng-content select="supy-input-error"></ng-content>
        <ng-content select="supy-input-hint"></ng-content>
      </supy-input-hint-container>
    </igx-hint>

    <ng-template igxPickerActions>
      <div class="supy-time-picker__actions">
        <supy-button color="default" width="full" (click)="timePicker.cancelButtonClick()" name="cancel-time-picker">
          Cancel
        </supy-button>
        <supy-button color="primary" width="full" (click)="timePicker.okButtonClick()" name="apply-time-picker">
          Apply
        </supy-button>
      </div>
    </ng-template>
  </igx-time-picker>
</div>
