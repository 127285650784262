import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconColor, IconSize } from '../../../icon';

@Component({
  selector: 'supy-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input() readonly color?: IconColor;
  @Input() readonly size?: IconSize;
}
