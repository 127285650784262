import { HexColor } from '@supy/common';

export enum ChartColor {
  Purple = 'purple',
  Yellow = 'yellow',
  Grey = 'grey',
  Red = 'red',
  Blue = 'blue',
  Green = 'green',
  Indigo = 'indigo',
}

export const COLORS_MAP = new Map<ChartColor, string[]>();
export const GRADIENTS_MAP = new Map<ChartColor, [HexColor, HexColor]>();

GRADIENTS_MAP.set(ChartColor.Purple, ['#B0A2DF', '#6144BF']);
GRADIENTS_MAP.set(ChartColor.Yellow, ['#FFE889', '#F1CB30']);
GRADIENTS_MAP.set(ChartColor.Grey, ['#A39EB3', '#706B80']);
GRADIENTS_MAP.set(ChartColor.Red, ['#E62E2E', '#990000']);
GRADIENTS_MAP.set(ChartColor.Blue, ['#2EA2E6', '#006199']);
GRADIENTS_MAP.set(ChartColor.Green, ['#82E6B6', '#069952']);
GRADIENTS_MAP.set(ChartColor.Indigo, ['#1338f8', '#001A92']);

COLORS_MAP.set(ChartColor.Purple, [
  '#ebdbff',
  '#dfc8ff',
  '#d1b4ff',
  '#c2a2ff',
  '#b390ff',
  '#a37ff9',
  '#926fee',
  '#8260df',
  '#7252cd',
  '#6246b8',
  '#533aa1',
  '#453087',
  '#38276c',
  '#2b1e50',
  '#1f1635',
]);
COLORS_MAP.set(ChartColor.Yellow, [
  '#FEFBF0',
  '#FDF8E2',
  '#FBF0C4',
  '#F9E9A7',
  '#F7E18A',
  '#F5DA6D',
  '#F3D24F',
  '#F1CB30',
  '#E9BE10',
]);
COLORS_MAP.set(ChartColor.Grey, [
  '#F7F6F9',
  '#EEEDF2',
  '#DEDCE6',
  '#BFBBCC',
  '#A39EB3',
  '#888399',
  '#706B80',
  '#585466',
  '#413E4C',
  '#2B2933',
]);
COLORS_MAP.set(ChartColor.Red, ['#FFE6E6', '#E62E2E', '#CC0000', '#B30000', '#990000', '#800000']);
COLORS_MAP.set(ChartColor.Blue, ['#E6F6FF', '#2EA2E6', '#0081CC', '#0071B3', '#006199', '#005180']);
COLORS_MAP.set(ChartColor.Green, ['#E6FFF3B2', '#82E6B6', '#3DCC87', '#18B368', '#069952', '#008042']);
COLORS_MAP.set(ChartColor.Indigo, ['#001A92']);
