import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { Store } from '@ngxs/store';

import { CommonConfig, extractCommitHash } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

import { SideNavItem } from '../../core';
import { SideNavSelectGroup, SideNavState } from '../../store';

const animDuration = 200;

type MenuState = 'in' | 'out';

@Component({
  selector: 'supy-side-nav',
  templateUrl: 'side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavComponent {
  readonly #store = inject(Store);
  readonly #config = inject<CommonConfig>(APP_CONFIG);

  protected readonly topItems = this.#store.selectSignal(SideNavState.topItems);

  protected readonly bottomItems = this.#store.selectSignal(SideNavState.bottomItems);

  protected readonly subMenuState = signal<MenuState>('in');

  protected readonly selectedGroup = this.#store.selectSignal(SideNavState.selectedGroup);

  protected readonly reversedMenuState = computed(() => (this.subMenuState() === 'out' ? 'in' : 'out'));

  protected readonly isSubMenuOpened = computed(() => this.subMenuState() === 'out');

  protected versionHash = extractCommitHash(this.#config.versionHash);

  protected onHandle(item: SideNavItem | null, menuState: MenuState): void {
    this.subMenuState.set(menuState);
    this.#store.dispatch(new SideNavSelectGroup(item));
  }

  protected onHandleAsync(item: SideNavItem): void {
    const groupSwitched = item.key !== this.selectedGroup()?.key;
    const itemInput = this.subMenuState() === 'in' || groupSwitched ? item : null;

    if (groupSwitched) {
      setTimeout(() => {
        this.subMenuState.set('out');
      }, animDuration / 1.5);
    }

    this.onHandle(itemInput, this.reversedMenuState());
  }

  protected onSubActionClick(item: SideNavItem): void {
    if (item.quickAction) {
      item.quickAction();
    }

    this.onHandle(null, 'in');
  }
}
