import { Observable } from 'rxjs';
import { IMutableContext } from 'unleash-proxy-client';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UnleashService } from '../unleash.service';

export abstract class BaseUnleashResolver<T = void> {
  protected abstract unleashService: UnleashService;

  async updateContextAndResolve(context: IMutableContext): Promise<void> {
    return await this.unleashService.updateContext({ ...context });
  }

  abstract resolve(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<T> | Promise<T> | T;
}
