import { takeUntil } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  contentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  model,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  AbsoluteScrollStrategy,
  AutoPositionStrategy,
  IgxInputGroupType,
  IgxSelectComponent,
  IgxSelectItemComponent,
  ISelectionEventArgs,
  OverlaySettings,
} from '@infragistics/igniteui-angular';
import { swingInTopBck } from '@infragistics/igniteui-angular/animations';

import { Destroyable } from '@supy/common';

import { IconSize } from '../../../icon';
import { InputComponent, InputDensity } from '../../../input';
import { SelectItemComponent } from '../select-item';
import { SelectFooterTemplateDirective, SelectHeaderTemplateDirective } from './select.directives';

@Component({
  selector: 'supy-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent<T> extends Destroyable implements OnInit, AfterViewInit, ControlValueAccessor {
  // FIXME: investigate when select items change the selected value is lost
  @Input() readonly placeholder: string;
  @Input() @HostBinding('attr.name') readonly name: string;
  @Input() readonly searchId: string;
  @Input() readonly selectType: IgxInputGroupType = 'border';
  @Input() readonly density: InputDensity = 'small';

  @Input() readonly clearable: boolean;
  @Input() readonly loading: boolean;
  @Input() readonly hideArrow: boolean;
  @Input() readonly arrowSize: IconSize = 'small';
  @Input() readonly searchEnabled: boolean;
  @Input() readonly showSearchIcon: boolean;
  @Input() readonly variant: 'border' | 'flat' = 'border';

  @ViewChild(IgxSelectComponent, { static: true })
  readonly select: IgxSelectComponent;

  @ViewChild(InputComponent)
  readonly searchInput: InputComponent<string>;

  overlaySettings: OverlaySettings;

  readonly children = contentChildren<SelectItemComponent<T>>(SelectItemComponent);

  @ContentChild(SelectFooterTemplateDirective)
  readonly selectFooterTemplate: SelectFooterTemplateDirective;

  @ContentChild(SelectHeaderTemplateDirective)
  readonly selectHeaderTemplate: SelectHeaderTemplateDirective;

  readonly value = model<T>(null);
  readonly disabled = model<boolean>(false);
  readonly touched = model<boolean>(false);

  @Output() selected = new EventEmitter<T>();
  @Output() search = new EventEmitter<string>();
  @Output() closed = new EventEmitter<void>();
  @Output() readonly opening = new EventEmitter<void>();
  @Output() readonly opened = new EventEmitter<void>();

  onChange?: (value: T) => void;
  onTouched?: () => void;

  constructor(
    readonly elementRef: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) private readonly document: Document,
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    super();

    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    this.overlaySettings = {
      target: this.select?.inputGroup.element.nativeElement,
      scrollStrategy: new AbsoluteScrollStrategy(),
      positionStrategy: new AutoPositionStrategy({
        openAnimation: swingInTopBck,
      }),
    };
  }

  open() {
    this.select.open();

    setTimeout(() => this.select.input.nativeElement.focus());
  }

  writeValue(value: T): void {
    this.value.set(value);
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled.set(disabled);
  }

  selectItem(item: IgxSelectItemComponent, event?: PointerEvent): void {
    this.select.selectItem(item, event);
  }

  ngOnInit(): void {
    this.control?.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value: T) => {
      this.writeValue(value);
    });
  }

  onSelect({ newSelection }: ISelectionEventArgs): void {
    this.onChange?.(newSelection?.value as T);

    this.selected.emit(newSelection?.value as T);
  }

  clearSelection(event: MouseEvent): void {
    this.select.clearSelection();
    this.onChange?.(null);
    this.selected.emit(null);
    event.stopPropagation();
  }

  onFocus(): void {
    if (this.touched()) {
      return;
    }
    this.onTouched?.();
    this.opening.emit();
  }

  focusOnSearch() {
    // igx-select doesn't allow to focus on <input> in igxSelectHeader, igxSelectFooter
    (
      this.document.getElementById(this.searchId).getElementsByClassName('igx-input-group__bundle')[0] as HTMLElement
    ).click();

    this.opened.emit();
  }

  onSearch(value: string) {
    this.search.emit(value);
  }

  onClose(): void {
    this.closed.emit();

    if (this.searchInput) {
      this.searchInput.value = null;
    }
  }

  trackByValue(_: number, item: SelectItemComponent<T>): T {
    return item.value;
  }
}
