import { Observable, OperatorFunction } from 'rxjs';

export enum ReadyStateEnum {
  Initial,
  Loading,
  Success,
  Error,
}

export class ReadyState {
  private state = ReadyStateEnum.Initial;

  get isLoading(): boolean {
    return this.state === ReadyStateEnum.Loading;
  }

  get isSuccess(): boolean {
    return this.state === ReadyStateEnum.Success;
  }

  get isError(): boolean {
    return this.state === ReadyStateEnum.Error;
  }

  get isInitial(): boolean {
    return this.state === ReadyStateEnum.Initial;
  }

  setState(state: ReadyStateEnum): void {
    this.state = state;
  }
}

export type ReadyStateFn = () => ReadyState;

export function getState(stateLike: ReadyState | ReadyStateFn): ReadyState {
  if (stateLike instanceof Function) {
    return stateLike();
  }

  if (stateLike instanceof ReadyState) {
    return stateLike;
  }

  return new ReadyState();
}

export function readyState<T>(stateLike: ReadyState | ReadyStateFn): OperatorFunction<T, T> {
  return source => {
    const state = getState(stateLike);

    state.setState(ReadyStateEnum.Loading);

    return new Observable(subscriber => {
      const subscription = source.subscribe({
        next: value => {
          state.setState(ReadyStateEnum.Success);
          subscriber.next(value);
        },
        error: error => {
          state.setState(ReadyStateEnum.Error);
          subscriber.error(error);
        },
        complete: () => {
          subscriber.complete();
        },
      });

      return subscription;
    });
  };
}
