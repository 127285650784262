import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChildren,
  HostBinding,
  Input,
  input,
  QueryList,
  ViewChild,
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';

import { DropdownItemComponent } from '../dropdown-item';

@Component({
  selector: 'supy-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent {
  @ViewChild(MatMenu, { static: true })
  readonly menu: MatMenu;

  @ContentChildren(DropdownItemComponent)
  readonly children: QueryList<DropdownItemComponent>;

  @Input() @HostBinding('attr.name') readonly name: string;

  @Input()
  @HostBinding('attr.width')
  readonly width: string;

  protected readonly customClasses = input<string[]>([]);

  readonly getCustomClasses = computed(() => this.customClasses().join(' '));
}
