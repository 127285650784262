"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PacificTimezone = void 0;
var PacificTimezone;
(function (PacificTimezone) {
  PacificTimezone["Apia"] = "Pacific/Apia";
  PacificTimezone["Auckland"] = "Pacific/Auckland";
  PacificTimezone["Bougainville"] = "Pacific/Bougainville";
  PacificTimezone["Chatham"] = "Pacific/Chatham";
  PacificTimezone["Chuuk"] = "Pacific/Chuuk";
  PacificTimezone["Easter"] = "Pacific/Easter";
  PacificTimezone["Efate"] = "Pacific/Efate";
  PacificTimezone["Enderbury"] = "Pacific/Enderbury";
  PacificTimezone["Fakaofo"] = "Pacific/Fakaofo";
  PacificTimezone["Fiji"] = "Pacific/Fiji";
  PacificTimezone["Funafuti"] = "Pacific/Funafuti";
  PacificTimezone["Galapagos"] = "Pacific/Galapagos";
  PacificTimezone["Gambier"] = "Pacific/Gambier";
  PacificTimezone["Guadalcanal"] = "Pacific/Guadalcanal";
  PacificTimezone["Guam"] = "Pacific/Guam";
  PacificTimezone["Honolulu"] = "Pacific/Honolulu";
  PacificTimezone["Kiritimati"] = "Pacific/Kiritimati";
  PacificTimezone["Kosrae"] = "Pacific/Kosrae";
  PacificTimezone["Kwajalein"] = "Pacific/Kwajalein";
  PacificTimezone["Majuro"] = "Pacific/Majuro";
  PacificTimezone["Marquesas"] = "Pacific/Marquesas";
  PacificTimezone["Midway"] = "Pacific/Midway";
  PacificTimezone["Nauru"] = "Pacific/Nauru";
  PacificTimezone["Niue"] = "Pacific/Niue";
  PacificTimezone["Norfolk"] = "Pacific/Norfolk";
  PacificTimezone["Noumea"] = "Pacific/Noumea";
  PacificTimezone["PagoPago"] = "Pacific/Pago_Pago";
  PacificTimezone["Palau"] = "Pacific/Palau";
  PacificTimezone["Pitcairn"] = "Pacific/Pitcairn";
  PacificTimezone["Pohnpei"] = "Pacific/Pohnpei";
  PacificTimezone["PortMoresby"] = "Pacific/Port_Moresby";
  PacificTimezone["Rarotonga"] = "Pacific/Rarotonga";
  PacificTimezone["Saipan"] = "Pacific/Saipan";
  PacificTimezone["Tahiti"] = "Pacific/Tahiti";
  PacificTimezone["Tarawa"] = "Pacific/Tarawa";
  PacificTimezone["Tongatapu"] = "Pacific/Tongatapu";
  PacificTimezone["Wake"] = "Pacific/Wake";
  PacificTimezone["Wallis"] = "Pacific/Wallis";
  PacificTimezone["Kanton"] = "Pacific/Kanton";
})(PacificTimezone = exports.PacificTimezone || (exports.PacificTimezone = {}));
