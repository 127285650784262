import { Scalar } from '../../nodes/Scalar.js';
function boolStringify({
  value,
  source
}, ctx) {
  const boolObj = value ? trueTag : falseTag;
  if (source && boolObj.test.test(source)) return source;
  return value ? ctx.options.trueStr : ctx.options.falseStr;
}
const trueTag = {
  identify: value => value === true,
  default: true,
  tag: 'tag:yaml.org,2002:bool',
  test: /^(?:Y|y|[Yy]es|YES|[Tt]rue|TRUE|[Oo]n|ON)$/,
  resolve: () => new Scalar(true),
  stringify: boolStringify
};
const falseTag = {
  identify: value => value === false,
  default: true,
  tag: 'tag:yaml.org,2002:bool',
  test: /^(?:N|n|[Nn]o|NO|[Ff]alse|FALSE|[Oo]ff|OFF)$/i,
  resolve: () => new Scalar(false),
  stringify: boolStringify
};
export { falseTag, trueTag };