import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { AuthService } from '../../services';
import { Logout } from '../../store';

@Component({
  selector: 'supy-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {
  private readonly navigation: Navigation | null;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly store: Store,
  ) {
    this.navigation = this.router.getCurrentNavigation();
  }

  async ngOnInit(): Promise<void> {
    const { state } = this.navigation?.extras ?? {};

    this.authService.logOut();
    this.store.dispatch(new Logout());

    await this.router.navigateByUrl('/auth/login', {
      replaceUrl: true,
      state: { ...state },
    });
  }
}
