import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type SeparatorType = 'solid' | 'dashed';
type SeparatorThickness = 'thin' | 'thick';
type SeparatorOrientation = 'horizontal' | 'vertical';

@Component({
  selector: 'supy-separator',
  templateUrl: './separator.component.html',
  styleUrls: ['./separator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeparatorComponent {
  @Input() readonly type: SeparatorType = 'dashed';
  @Input() readonly thickness: SeparatorThickness = 'thin';
  @Input() readonly orientation: SeparatorOrientation = 'horizontal';
}
