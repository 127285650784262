"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var uuid_1 = require("uuid");
var EventsHandler = /** @class */function () {
  function EventsHandler() {}
  EventsHandler.prototype.generateEventId = function () {
    return (0, uuid_1.v4)();
  };
  EventsHandler.prototype.createImpressionEvent = function (context, enabled, featureName, eventType, variant) {
    var baseEvent = this.createBaseEvent(context, enabled, featureName, eventType);
    if (variant) {
      return __assign(__assign({}, baseEvent), {
        variant: variant
      });
    }
    return baseEvent;
  };
  EventsHandler.prototype.createBaseEvent = function (context, enabled, featureName, eventType) {
    return {
      eventType: eventType,
      eventId: this.generateEventId(),
      context: context,
      enabled: enabled,
      featureName: featureName
    };
  };
  return EventsHandler;
}();
exports.default = EventsHandler;
