import { ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core';
import { GridColumnDataType, IColumnPipeArgs, IgxFilteringOperand } from '@infragistics/igniteui-angular';

import {
  FilterCellTemplateDirective,
  GridCellEditorTemplateDirective,
  GridCellHeaderTemplateDirective,
  GridCellTemplateDirective,
} from './templates.directive';

@Component({
  selector: 'supy-grid-column',
  templateUrl: './grid-column.component.html',
  styleUrls: ['./grid-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridColumnComponent {
  @Input() readonly disabled: boolean;
  @Input() readonly sortable: boolean = false;
  @Input() readonly header: string = '';
  @Input() readonly field: string;
  @Input() readonly width: string = null;
  @Input() readonly minWidth: string = '';
  @Input() readonly maxWidth: string = '';
  @Input() readonly movable: boolean = false;
  @Input() readonly formatter: (value: unknown, rowData?: unknown) => unknown;
  @Input() readonly filters: IgxFilteringOperand = null;
  @Input() readonly disablePinning: boolean = false;
  // FIXME: switch `filterable` to false or remove once implement filters component
  @Input() readonly filterable: boolean = true;
  @Input() readonly disableHiding: boolean = false;
  @Input() readonly hidden: boolean = false;
  @Input() readonly type: string;
  @Input() readonly headerClasses: string;
  @Input() readonly headerGroupClasses: string;
  @Input() readonly align: 'start' | 'center' | 'end' = 'start';
  @Input() readonly dataType: GridColumnDataType = GridColumnDataType.String;
  @Input() readonly editable: boolean;
  @Input() readonly cellClasses: { [key: string]: boolean | ((...args) => boolean) };
  @Input() readonly resizable: boolean;
  @Input() readonly required: boolean;
  @Input() readonly pipeArgs: IColumnPipeArgs;

  @ContentChild(GridCellTemplateDirective)
  readonly cellTemplate: GridCellTemplateDirective;

  @ContentChild(GridCellEditorTemplateDirective)
  readonly cellEditorTemplate: GridCellEditorTemplateDirective;

  @ContentChild(FilterCellTemplateDirective)
  readonly filterCellTemplate: FilterCellTemplateDirective;

  @ContentChild(GridCellHeaderTemplateDirective)
  readonly cellHeaderTemplate: GridCellHeaderTemplateDirective;
}
