import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SwitchSize } from '../../../switch';

export interface SwitchCellRendererContext {
  readonly disabled?: boolean;
  readonly size?: SwitchSize;
}

type SwitchCellRendererParams = ICellRendererParams<unknown, boolean, SwitchCellRendererContext>;

@Component({
  selector: 'supy-switch-cell-renderer',
  template: `<div class="supy-switch-cell-renderer">
    <supy-switch
      [value]="value"
      [size]="context?.size ?? 'small'"
      [disabled]="context?.disabled"
      (valueChange)="valueChangeHandler($event)"
    ></supy-switch>
  </div>`,
  styleUrls: ['./switch-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchCellRendererComponent implements ICellRendererAngularComp {
  protected value: boolean;
  protected params: SwitchCellRendererParams;
  protected context: SwitchCellRendererContext;

  agInit(params: SwitchCellRendererParams): void {
    this.params = params;
    this.context = params.context;
    this.value = params.value;
  }

  getValue(): boolean {
    return this.value;
  }

  refresh(): boolean {
    return true;
  }

  protected valueChangeHandler(value: boolean): void {
    this.params.setValue(value);
    this.value = value;
  }
}
