import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxButtonModule } from '@infragistics/igniteui-angular';

import { IconModule } from '../icon';
import { ButtonComponent, ButtonGroupComponent } from './components';

@NgModule({
  declarations: [ButtonComponent, ButtonGroupComponent],
  imports: [CommonModule, IgxButtonModule, IconModule],
  exports: [ButtonComponent, ButtonGroupComponent],
})
export class ButtonModule {}
