import { expand, map, Observable, takeWhile, toArray } from 'rxjs';

import { Query, QueryPaging } from './query-builder';
import { IQueryResponse, mergeQueryResponses } from './response.interface';

export function fetchInBatches<T, S>(
  request: (q: Query<S>) => Observable<IQueryResponse<T>>,
  query: Query<S>,
  batchSize: number,
): Observable<IQueryResponse<T>> {
  let offset = 0;
  let batchQuery = new Query<S>({ ...query, paging: new QueryPaging(offset, batchSize) });

  return request(batchQuery).pipe(
    expand(response => {
      offset += response.metadata.count;
      batchQuery = new Query<S>({ ...query, paging: new QueryPaging(offset, batchSize) });

      return request(batchQuery);
    }),
    takeWhile(response => response.metadata.count > 0),
    toArray<IQueryResponse<T>>(),
    map(responses => mergeQueryResponses<T>(responses)),
  );
}
