<button
  #buttonElement
  [igxButton]="variant"
  (buttonClick)="onClick($event)"
  (mouseup)="buttonElement.blur()"
  [type]="type"
  [disabled]="disabled"
  class="supy-button"
  [ngClass]="getClasses()"
  [name]="name"
>
  <ng-content></ng-content>
</button>
