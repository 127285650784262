<ng-container *ngIf="dateActionFirst; else defaultTemplate">
  <div class="supy-activity-log">
    <div class="supy-activity-log__content">
      <div *ngFor="let activity of activities; let last = last" class="supy-activity-log__item">
        <div>
          <div class="supy-activity-log__item-indicator"></div>
          <div *ngIf="!last" class="supy-activity-log__item-border"></div>
        </div>
        <div class="supy-activity-log__item-content">
          <span class="supy-activity-log__item-content-item supy-activity-log__item-content-item--bold">
            {{ activity.createdAt | timezone: ianaTimeZone | date: 'EE, MMMM d y hh:mm a' }}
          </span>
          <span class="supy-activity-log__item-content-item">{{ activity.action | titlecase }}</span>
          <span class="supy-activity-log__item-content-item" i18n="@@components.activityLog.userInfoSimple">
            by {{ activity.user.firstName }}
            {{ activity.user.lastName }}
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #defaultTemplate>
  <div class="supy-activity-log">
    <div class="supy-activity-log__content">
      <div *ngFor="let activity of activities; let last = last" class="supy-activity-log__item">
        <div>
          <div class="supy-activity-log__item-indicator"></div>
          <div *ngIf="!last" class="supy-activity-log__item-border"></div>
        </div>
        <div class="supy-activity-log__item-content">
          <span class="supy-activity-log__item-content-item">{{ activity.action | titlecase }}</span>
          <span class="supy-activity-log__item-content-item" i18n="@@components.activityLog.userInfo">
            on {{ activity.createdAt | date: 'EE, MMMM d y' }} by {{ activity.user.firstName }}
            {{ activity.user.lastName }}
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
