import { LocalizedData } from './localized.interface';

export interface BaseSelectItem<T = string> {
  readonly value: T;
  readonly label: string;
}

export interface NumberPayload {
  readonly number: string;
}

export interface UploadFileResponse {
  readonly photoUrl: string;
}

export class IdType {
  readonly id: string;

  static default(): IdType {
    return { id: crypto.randomUUID() };
  }
}

export class SkeletonObjectType extends IdType {
  readonly name: string;

  static default(): SkeletonObjectType {
    return { ...super.default(), name: '' };
  }
}

export class LocalizedSkeletonObjectType extends IdType {
  readonly name: LocalizedData;

  static default(): LocalizedSkeletonObjectType {
    return { ...super.default(), name: LocalizedData.default() };
  }
}

export type HexColor = `#${string}`;

export type Color = HexColor;

export type SimpleTypeofValues = 'string' | 'number' | 'boolean';

export type SimpleValueTypes = string | number | boolean;
export type Value = SimpleValueTypes | null | { [key: string]: Value } | Value[];
