export interface BaseRequestMetadata {
  readonly limit: number;
  readonly page: number;
}

export interface BaseResponseMetadata {
  readonly total: number;
  readonly count: number;
}

export const BASE_REQUEST_META_DEFAULT = { page: 0, limit: 30 };
export const BASE_RESPONSE_META_DEFAULT = { total: 0, count: 0 };
