"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Language = void 0;
var Language;
(function (Language) {
  Language["Abkhazian"] = "ab";
  Language["Afar"] = "aa";
  Language["Afrikaans"] = "af";
  Language["Akan"] = "ak";
  Language["Albanian"] = "sq";
  Language["Amharic"] = "am";
  Language["Arabic"] = "ar";
  Language["Aragonese"] = "an";
  Language["Armenian"] = "hy";
  Language["Assamese"] = "as";
  Language["Avaric"] = "av";
  Language["Avestan"] = "ae";
  Language["Aymara"] = "ay";
  Language["Azerbaijani"] = "az";
  Language["Bambara"] = "bm";
  Language["Bashkir"] = "ba";
  Language["Basque"] = "eu";
  Language["Belarusian"] = "be";
  Language["Bengali"] = "bn";
  Language["BihariLanguages"] = "bh";
  Language["Bislama"] = "bi";
  Language["Bosnian"] = "bs";
  Language["Breton"] = "br";
  Language["Bulgarian"] = "bg";
  Language["Burmese"] = "my";
  Language["Catalan"] = "ca";
  Language["CentralKhmer"] = "km";
  Language["Chamorro"] = "ch";
  Language["Chechen"] = "ce";
  Language["Chewa"] = "ny";
  Language["Chinese"] = "zh";
  Language["ChurchSlavic"] = "cu";
  Language["Chuvash"] = "cv";
  Language["Cornish"] = "kw";
  Language["Corsican"] = "co";
  Language["Cree"] = "cr";
  Language["Croatian"] = "hr";
  Language["Czech"] = "cs";
  Language["Danish"] = "da";
  Language["Divehi"] = "dv";
  Language["Dutch"] = "nl";
  Language["Dzongkha"] = "dz";
  Language["English"] = "en";
  Language["Esperanto"] = "eo";
  Language["Estonian"] = "et";
  Language["Ewe"] = "ee";
  Language["Faroese"] = "fo";
  Language["Fijian"] = "fj";
  Language["Finnish"] = "fi";
  Language["French"] = "fr";
  Language["Fulah"] = "ff";
  Language["Galician"] = "gl";
  Language["Ganda"] = "lg";
  Language["Georgian"] = "ka";
  Language["German"] = "de";
  Language["Gilbertese"] = "gil";
  Language["Greek"] = "el";
  Language["Greenlandic"] = "kl";
  Language["Guarani"] = "gn";
  Language["Gujarati"] = "gu";
  Language["Haitian"] = "ht";
  Language["Hausa"] = "ha";
  Language["Hebrew"] = "he";
  Language["Herero"] = "hz";
  Language["Hindi"] = "hi";
  Language["HiriMotu"] = "ho";
  Language["Hungarian"] = "hu";
  Language["Icelandic"] = "is";
  Language["Ido"] = "io";
  Language["Igbo"] = "ig";
  Language["Indonesian"] = "id";
  Language["Interlingua"] = "ia";
  Language["Interlingue"] = "ie";
  Language["Inuktitut"] = "iu";
  Language["Inupiaq"] = "ik";
  Language["Irish"] = "ga";
  Language["Italian"] = "it";
  Language["Japanese"] = "ja";
  Language["Javanese"] = "jv";
  Language["Kalaallisut"] = "kl";
  Language["Kannada"] = "kn";
  Language["Kanuri"] = "kr";
  Language["Kashmiri"] = "ks";
  Language["Kazakh"] = "kk";
  Language["Kikuyu"] = "ki";
  Language["Kinyarwanda"] = "rw";
  Language["Kyrgyz"] = "ky";
  Language["Komi"] = "kv";
  Language["Kongo"] = "kg";
  Language["Korean"] = "ko";
  Language["Kurdish"] = "ku";
  Language["Kwanyama"] = "kj";
  Language["Lao"] = "lo";
  Language["Latin"] = "la";
  Language["Latvian"] = "lv";
  Language["Limburgan"] = "li";
  Language["Lingala"] = "ln";
  Language["Lithuanian"] = "lt";
  Language["LubaKatanga"] = "lu";
  Language["Luxembourgish"] = "lb";
  Language["Macedonian"] = "mk";
  Language["Malagasy"] = "mg";
  Language["Malay"] = "ms";
  Language["Malayalam"] = "ml";
  Language["Maltese"] = "mt";
  Language["Manx"] = "gv";
  Language["Maori"] = "mi";
  Language["Marathi"] = "mr";
  Language["Marshallese"] = "mh";
  Language["Mongolian"] = "mn";
  Language["Nauru"] = "na";
  Language["Navajo"] = "nv";
  Language["Ndonga"] = "ng";
  Language["Nepali"] = "ne";
  Language["NorthNdebele"] = "nd";
  Language["NorthernSami"] = "se";
  Language["NorwegianBokmal"] = "nb";
  Language["NorwegianNynorsk"] = "nn";
  Language["Norwegian"] = "no";
  Language["Nuosu"] = "ii";
  Language["Occitan"] = "oc";
  Language["Ojibwa"] = "oj";
  Language["OldChurchSlavonic"] = "cu";
  Language["Oriya"] = "or";
  Language["Oromo"] = "om";
  Language["Ossetian"] = "os";
  Language["Pali"] = "pi";
  Language["Panjabi"] = "pa";
  Language["Pashto"] = "ps";
  Language["Persian"] = "fa";
  Language["Polish"] = "pl";
  Language["Portuguese"] = "pt";
  Language["Quechua"] = "qu";
  Language["Romanian"] = "ro";
  Language["Romansh"] = "rm";
  Language["Rundi"] = "rn";
  Language["Russian"] = "ru";
  Language["Samoan"] = "sm";
  Language["Sango"] = "sg";
  Language["Sanskrit"] = "sa";
  Language["Sardinian"] = "sc";
  Language["ScottishGaelic"] = "gd";
  Language["Serbian"] = "sr";
  Language["Shona"] = "sn";
  Language["Sindhi"] = "sd";
  Language["Sinhala"] = "si";
  Language["Slovak"] = "sk";
  Language["Slovenian"] = "sl";
  Language["Somali"] = "so";
  Language["SouthNdebele"] = "nr";
  Language["SouthernSotho"] = "st";
  Language["Spanish"] = "es";
  Language["Sundanese"] = "su";
  Language["Swahili"] = "sw";
  Language["Swati"] = "ss";
  Language["Swedish"] = "sv";
  Language["Tagalog"] = "tl";
  Language["Tahitian"] = "ty";
  Language["Tajik"] = "tg";
  Language["Tamil"] = "ta";
  Language["Tatar"] = "tt";
  Language["Telugu"] = "te";
  Language["Thai"] = "th";
  Language["Tibetan"] = "bo";
  Language["Tigrinya"] = "ti";
  Language["Tonga"] = "to";
  Language["Tsonga"] = "ts";
  Language["Tswana"] = "tn";
  Language["Turkish"] = "tr";
  Language["Turkmen"] = "tk";
  Language["Twi"] = "tw";
  Language["Uighur"] = "ug";
  Language["Ukrainian"] = "uk";
  Language["Urdu"] = "ur";
  Language["Uzbek"] = "uz";
  Language["Venda"] = "ve";
  Language["Vietnamese"] = "vi";
  Language["Volapuk"] = "vo";
  Language["Walloon"] = "wa";
  Language["Welsh"] = "cy";
  Language["WesternFrisian"] = "fy";
  Language["Wolof"] = "wo";
  Language["Xhosa"] = "xh";
  Language["Yiddish"] = "yi";
  Language["Yoruba"] = "yo";
  Language["Zhuang"] = "za";
  Language["Zulu"] = "zu";
})(Language = exports.Language || (exports.Language = {}));
