import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'supy-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingOverlayComponent {
  @Input() readonly top?: string;
  @Input() readonly left?: string;
  @Input() readonly right?: string;
  @Input() readonly bottom?: string;

  get isCustom(): boolean {
    const isCustom = this.top || this.left || this.right || this.bottom;

    return Boolean(isCustom);
  }
}
