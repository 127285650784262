import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InputModule } from '../input';
import { SearchInputComponent } from './components';

@NgModule({
  declarations: [SearchInputComponent],
  imports: [CommonModule, InputModule],
  exports: [SearchInputComponent],
})
export class SearchInputModule {}
