"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EuropeTimezone = void 0;
var EuropeTimezone;
(function (EuropeTimezone) {
  EuropeTimezone["Amsterdam"] = "Europe/Amsterdam";
  EuropeTimezone["Andorra"] = "Europe/Andorra";
  EuropeTimezone["Astrakhan"] = "Europe/Astrakhan";
  EuropeTimezone["Athens"] = "Europe/Athens";
  EuropeTimezone["Belgrade"] = "Europe/Belgrade";
  EuropeTimezone["Berlin"] = "Europe/Berlin";
  EuropeTimezone["Bratislava"] = "Europe/Bratislava";
  EuropeTimezone["Brussels"] = "Europe/Brussels";
  EuropeTimezone["Bucharest"] = "Europe/Bucharest";
  EuropeTimezone["Budapest"] = "Europe/Budapest";
  EuropeTimezone["Busingen"] = "Europe/Busingen";
  EuropeTimezone["Chisinau"] = "Europe/Chisinau";
  EuropeTimezone["Copenhagen"] = "Europe/Copenhagen";
  EuropeTimezone["Dublin"] = "Europe/Dublin";
  EuropeTimezone["Gibraltar"] = "Europe/Gibraltar";
  EuropeTimezone["Guernsey"] = "Europe/Guernsey";
  EuropeTimezone["Helsinki"] = "Europe/Helsinki";
  EuropeTimezone["IsleOfMan"] = "Europe/Isle_of_Man";
  EuropeTimezone["Istanbul"] = "Europe/Istanbul";
  EuropeTimezone["Jersey"] = "Europe/Jersey";
  EuropeTimezone["Kaliningrad"] = "Europe/Kaliningrad";
  EuropeTimezone["Kyiv"] = "Europe/Kyiv";
  EuropeTimezone["Kirov"] = "Europe/Kirov";
  EuropeTimezone["Lisbon"] = "Europe/Lisbon";
  EuropeTimezone["Ljubljana"] = "Europe/Ljubljana";
  EuropeTimezone["London"] = "Europe/London";
  EuropeTimezone["Luxembourg"] = "Europe/Luxembourg";
  EuropeTimezone["Madrid"] = "Europe/Madrid";
  EuropeTimezone["Malta"] = "Europe/Malta";
  EuropeTimezone["Mariehamn"] = "Europe/Mariehamn";
  EuropeTimezone["Minsk"] = "Europe/Minsk";
  EuropeTimezone["Monaco"] = "Europe/Monaco";
  EuropeTimezone["Moscow"] = "Europe/Moscow";
  EuropeTimezone["Oslo"] = "Europe/Oslo";
  EuropeTimezone["Paris"] = "Europe/Paris";
  EuropeTimezone["Podgorica"] = "Europe/Podgorica";
  EuropeTimezone["Prague"] = "Europe/Prague";
  EuropeTimezone["Riga"] = "Europe/Riga";
  EuropeTimezone["Rome"] = "Europe/Rome";
  EuropeTimezone["Samara"] = "Europe/Samara";
  EuropeTimezone["SanMarino"] = "Europe/San_Marino";
  EuropeTimezone["Sarajevo"] = "Europe/Sarajevo";
  EuropeTimezone["Saratov"] = "Europe/Saratov";
  EuropeTimezone["Simferopol"] = "Europe/Simferopol";
  EuropeTimezone["Skopje"] = "Europe/Skopje";
  EuropeTimezone["Sofia"] = "Europe/Sofia";
  EuropeTimezone["Stockholm"] = "Europe/Stockholm";
  EuropeTimezone["Tallinn"] = "Europe/Tallinn";
  EuropeTimezone["Tirane"] = "Europe/Tirane";
  EuropeTimezone["Ulyanovsk"] = "Europe/Ulyanovsk";
  EuropeTimezone["Uzhgorod"] = "Europe/Uzhgorod";
  EuropeTimezone["Vaduz"] = "Europe/Vaduz";
  EuropeTimezone["Vatican"] = "Europe/Vatican";
  EuropeTimezone["Vienna"] = "Europe/Vienna";
  EuropeTimezone["Vilnius"] = "Europe/Vilnius";
  EuropeTimezone["Volgograd"] = "Europe/Volgograd";
  EuropeTimezone["Warsaw"] = "Europe/Warsaw";
  EuropeTimezone["Zagreb"] = "Europe/Zagreb";
  EuropeTimezone["Zaporozhye"] = "Europe/Zaporozhye";
  EuropeTimezone["Zurich"] = "Europe/Zurich";
})(EuropeTimezone = exports.EuropeTimezone || (exports.EuropeTimezone = {}));
