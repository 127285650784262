import 'reflect-metadata';

import { catchError, finalize, Observable } from 'rxjs';
import { Context, context, Span, SpanOptions, SpanStatusCode, trace } from '@opentelemetry/api';

export interface AnyFunction<A extends unknown[] = unknown[], R = unknown> extends Function {
  (...args: [...A]): R;
}

async function executePromise<T>(promise: Promise<T>, span: Span): Promise<T> {
  return promise
    .catch((error: Error) => {
      recordException(span, error);

      throw error;
    })
    .finally(() => {
      span.end();
    });
}

function executeObservable<T>(observable: Observable<T>, span: Span): Observable<T> {
  return observable.pipe(
    catchError((error: Error) => {
      recordException(span, error);

      throw error;
    }),
    finalize(() => {
      span.end();
    }),
  );
}

export function execute<F extends AnyFunction, A extends Parameters<F>, R extends ReturnType<F>>(
  this: ThisType<F>,
  fn: F,
  args: A,
  ctx: Context,
  span: Span,
): R {
  return context.with(ctx, () => {
    try {
      const result = Reflect.apply(fn, this, args) as R;

      if (result instanceof Promise) {
        return executePromise<R>(result, span) as R;
      }

      if (result instanceof Observable) {
        executeObservable<R>(result, span);
      }

      span.end();

      return result;
    } catch (error) {
      recordException(span, error as Error);
      span.end();

      throw error;
    }
  });
}

export function recordException(span: Span, error: Error) {
  span.recordException(error);
  span.setStatus({
    code: SpanStatusCode.ERROR,
    message: error.message,
  });
}

export function spanContextProvider(name: string, options?: SpanOptions): () => [Context, Span] {
  return () => {
    const tracer = trace.getTracer('default');
    const span = tracer.startSpan(name, options);

    const ctx = trace.setSpan(context.active(), span);

    return [ctx, span];
  };
}

export function wrap<F extends AnyFunction, A extends Parameters<F>, R extends ReturnType<F>>(
  fn: F,
  provider: () => [ctx: Context, span: Span],
): F {
  const method = {
    [fn.name]: function (this: ThisParameterType<F>, ...args: A): R {
      return Reflect.apply(execute, this, [fn, args, ...provider()]) as R;
    },
  }[fn.name] as F;

  redecorate(fn, method);

  return method;
}

export function redecorate<F extends AnyFunction>(source: F, target: F): void {
  const keys = Reflect.getOwnMetadataKeys(source);

  for (const key of keys) {
    const value = Reflect.getOwnMetadata(key, source) as unknown;

    Reflect.defineMetadata(key, value, target);
  }
}
