import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxDropDownModule, IgxSelectModule, IgxToggleModule } from '@infragistics/igniteui-angular';

import { IconModule } from '../icon';
import { InputHintsModule } from '../input-hints';
import { LoaderModule } from '../loader';
import { InputModule } from './../input/input.module';
import {
  LanguageSelectComponent,
  SelectComponent,
  SelectFooterTemplateDirective,
  SelectHeaderTemplateDirective,
  SelectItemComponent,
  SelectSearchPipe,
} from './components';

@NgModule({
  declarations: [
    SelectComponent,
    SelectItemComponent,
    SelectFooterTemplateDirective,
    SelectHeaderTemplateDirective,
    SelectSearchPipe,
    LanguageSelectComponent,
  ],
  imports: [
    CommonModule,
    IgxSelectModule,
    IgxDropDownModule,
    IgxToggleModule,
    IconModule,
    InputHintsModule,
    InputModule,
    LoaderModule,
  ],
  exports: [
    SelectComponent,
    SelectItemComponent,
    SelectFooterTemplateDirective,
    SelectHeaderTemplateDirective,
    SelectSearchPipe,
    LanguageSelectComponent,
  ],
})
export class SelectModule {}
