import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BadgeWidth } from '../../../badge';
import { BadgeCircleSize, BadgeSize, BadgeStatus, BadgeWeight } from '../../models';

@Component({
  selector: 'supy-icon-status-badge',
  templateUrl: './icon-status-badge.component.html',
  styleUrls: ['./icon-status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconStatusBadgeComponent {
  @Input() status: BadgeStatus;
  @Input() width: BadgeWidth = 'auto';
  @Input() weight: BadgeWeight;
  @Input() size: BadgeSize = 'small';
  @Input() text: string;
  @Input() title: string;
  @Input() circleSize: BadgeCircleSize = 'medium';

  getClasses(): string[] {
    const className = 'supy-icon-status-badge--';
    const classes: string[] = [`${className}circle-${this.circleSize}`];

    if (this.status) {
      classes.push(`${className}${this.status}`);
    }

    if (this.weight) {
      classes.push(`${className}${this.weight}`);
    }

    return classes;
  }
}
