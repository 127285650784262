import { CommonModule as NgCommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { BackupImageDirective, NumericDirective, ScrollTopDirective, SupyContextDirective } from './directives';
import {
  FileNamePipe,
  JoinPipe,
  MatchPipe,
  NestedValuePipe,
  PluckPipe,
  PrecisionPipe,
  SafeUrlPipe,
  SiNumberPipe,
  TimezonePipe,
} from './pipes';

const PIPES = [
  FileNamePipe,
  JoinPipe,
  MatchPipe,
  NestedValuePipe,
  PluckPipe,
  PrecisionPipe,
  SafeUrlPipe,
  SiNumberPipe,
  TimezonePipe,
];

const DIRECTIVES = [SupyContextDirective, ScrollTopDirective, BackupImageDirective, NumericDirective];

@NgModule({
  declarations: [...DIRECTIVES, ...PIPES],
  imports: [NgCommonModule],
  exports: [NgCommonModule, ...DIRECTIVES, ...PIPES],
  providers: [...PIPES, DecimalPipe],
})
export class SupyCommonModule {}
