"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AmericaTimezone = void 0;
var AmericaTimezone;
(function (AmericaTimezone) {
  AmericaTimezone["Adak"] = "America/Adak";
  AmericaTimezone["Anchorage"] = "America/Anchorage";
  AmericaTimezone["Anguilla"] = "America/Anguilla";
  AmericaTimezone["Antigua"] = "America/Antigua";
  AmericaTimezone["Araguaina"] = "America/Araguaina";
  AmericaTimezone["Ciudad_Juarez"] = "America/Ciudad_Juarez";
  AmericaTimezone["Nuuk"] = "America/Nuuk";
  AmericaTimezone["ArgentinaBuenosAires"] = "America/Argentina/Buenos_Aires";
  AmericaTimezone["ArgentinaCatamarca"] = "America/Argentina/Catamarca";
  AmericaTimezone["ArgentinaCordoba"] = "America/Argentina/Cordoba";
  AmericaTimezone["ArgentinaJujuy"] = "America/Argentina/Jujuy";
  AmericaTimezone["ArgentinaLaRioja"] = "America/Argentina/La_Rioja";
  AmericaTimezone["ArgentinaMendoza"] = "America/Argentina/Mendoza";
  AmericaTimezone["ArgentinaRioGallegos"] = "America/Argentina/Rio_Gallegos";
  AmericaTimezone["ArgentinaSalta"] = "America/Argentina/Salta";
  AmericaTimezone["ArgentinaSanJuan"] = "America/Argentina/San_Juan";
  AmericaTimezone["ArgentinaSanLuis"] = "America/Argentina/San_Luis";
  AmericaTimezone["ArgentinaTucuman"] = "America/Argentina/Tucuman";
  AmericaTimezone["ArgentinaUshuaia"] = "America/Argentina/Ushuaia";
  AmericaTimezone["Aruba"] = "America/Aruba";
  AmericaTimezone["Asuncion"] = "America/Asuncion";
  AmericaTimezone["Atikokan"] = "America/Atikokan";
  AmericaTimezone["Bahia"] = "America/Bahia";
  AmericaTimezone["BahiaBanderas"] = "America/Bahia_Banderas";
  AmericaTimezone["Barbados"] = "America/Barbados";
  AmericaTimezone["Belem"] = "America/Belem";
  AmericaTimezone["Belize"] = "America/Belize";
  AmericaTimezone["BlancSablon"] = "America/Blanc-Sablon";
  AmericaTimezone["BoaVista"] = "America/Boa_Vista";
  AmericaTimezone["Bogota"] = "America/Bogota";
  AmericaTimezone["Boise"] = "America/Boise";
  AmericaTimezone["CambridgeBay"] = "America/Cambridge_Bay";
  AmericaTimezone["CampoGrande"] = "America/Campo_Grande";
  AmericaTimezone["Cancun"] = "America/Cancun";
  AmericaTimezone["Caracas"] = "America/Caracas";
  AmericaTimezone["Cayenne"] = "America/Cayenne";
  AmericaTimezone["Cayman"] = "America/Cayman";
  AmericaTimezone["Chicago"] = "America/Chicago";
  AmericaTimezone["Chihuahua"] = "America/Chihuahua";
  AmericaTimezone["CostaRica"] = "America/Costa_Rica";
  AmericaTimezone["Creston"] = "America/Creston";
  AmericaTimezone["Cuiaba"] = "America/Cuiaba";
  AmericaTimezone["Curacao"] = "America/Curacao";
  AmericaTimezone["Danmarkshavn"] = "America/Danmarkshavn";
  AmericaTimezone["Dawson"] = "America/Dawson";
  AmericaTimezone["DawsonCreek"] = "America/Dawson_Creek";
  AmericaTimezone["Denver"] = "America/Denver";
  AmericaTimezone["Detroit"] = "America/Detroit";
  AmericaTimezone["Dominica"] = "America/Dominica";
  AmericaTimezone["Edmonton"] = "America/Edmonton";
  AmericaTimezone["Eirunepe"] = "America/Eirunepe";
  AmericaTimezone["ElSalvador"] = "America/El_Salvador";
  AmericaTimezone["FortNelson"] = "America/Fort_Nelson";
  AmericaTimezone["Fortaleza"] = "America/Fortaleza";
  AmericaTimezone["GlaceBay"] = "America/Glace_Bay";
  AmericaTimezone["Godthab"] = "America/Godthab";
  AmericaTimezone["GooseBay"] = "America/Goose_Bay";
  AmericaTimezone["GrandTurk"] = "America/Grand_Turk";
  AmericaTimezone["Grenada"] = "America/Grenada";
  AmericaTimezone["Guadeloupe"] = "America/Guadeloupe";
  AmericaTimezone["Guatemala"] = "America/Guatemala";
  AmericaTimezone["Guayaquil"] = "America/Guayaquil";
  AmericaTimezone["Guyana"] = "America/Guyana";
  AmericaTimezone["Halifax"] = "America/Halifax";
  AmericaTimezone["Havana"] = "America/Havana";
  AmericaTimezone["Hermosillo"] = "America/Hermosillo";
  AmericaTimezone["IndianaIndianapolis"] = "America/Indiana/Indianapolis";
  AmericaTimezone["IndianaKnox"] = "America/Indiana/Knox";
  AmericaTimezone["IndianaMarengo"] = "America/Indiana/Marengo";
  AmericaTimezone["IndianaPetersburg"] = "America/Indiana/Petersburg";
  AmericaTimezone["IndianaTellCity"] = "America/Indiana/Tell_City";
  AmericaTimezone["IndianaVevay"] = "America/Indiana/Vevay";
  AmericaTimezone["IndianaVincennes"] = "America/Indiana/Vincennes";
  AmericaTimezone["IndianaWinamac"] = "America/Indiana/Winamac";
  AmericaTimezone["Inuvik"] = "America/Inuvik";
  AmericaTimezone["Iqaluit"] = "America/Iqaluit";
  AmericaTimezone["Jamaica"] = "America/Jamaica";
  AmericaTimezone["Juneau"] = "America/Juneau";
  AmericaTimezone["KentuckyLouisville"] = "America/Kentucky/Louisville";
  AmericaTimezone["KentuckyMonticello"] = "America/Kentucky/Monticello";
  AmericaTimezone["Kralendijk"] = "America/Kralendijk";
  AmericaTimezone["LaPaz"] = "America/La_Paz";
  AmericaTimezone["Lima"] = "America/Lima";
  AmericaTimezone["LosAngeles"] = "America/Los_Angeles";
  AmericaTimezone["LowerPrinces"] = "America/Lower_Princes";
  AmericaTimezone["Maceio"] = "America/Maceio";
  AmericaTimezone["Managua"] = "America/Managua";
  AmericaTimezone["Manaus"] = "America/Manaus";
  AmericaTimezone["Marigot"] = "America/Marigot";
  AmericaTimezone["Martinique"] = "America/Martinique";
  AmericaTimezone["Matamoros"] = "America/Matamoros";
  AmericaTimezone["Mazatlan"] = "America/Mazatlan";
  AmericaTimezone["Menominee"] = "America/Menominee";
  AmericaTimezone["Merida"] = "America/Merida";
  AmericaTimezone["Metlakatla"] = "America/Metlakatla";
  AmericaTimezone["MexicoCity"] = "America/Mexico_City";
  AmericaTimezone["Miquelon"] = "America/Miquelon";
  AmericaTimezone["Moncton"] = "America/Moncton";
  AmericaTimezone["Monterrey"] = "America/Monterrey";
  AmericaTimezone["Montevideo"] = "America/Montevideo";
  AmericaTimezone["Montserrat"] = "America/Montserrat";
  AmericaTimezone["Nassau"] = "America/Nassau";
  AmericaTimezone["NewYork"] = "America/New_York";
  AmericaTimezone["Nipigon"] = "America/Nipigon";
  AmericaTimezone["Nome"] = "America/Nome";
  AmericaTimezone["Noronha"] = "America/Noronha";
  AmericaTimezone["NorthDakotaBeulah"] = "America/North_Dakota/Beulah";
  AmericaTimezone["NorthDakotaCenter"] = "America/North_Dakota/Center";
  AmericaTimezone["NorthDakotaNewSalem"] = "America/North_Dakota/New_Salem";
  AmericaTimezone["Ojinaga"] = "America/Ojinaga";
  AmericaTimezone["Panama"] = "America/Panama";
  AmericaTimezone["Pangnirtung"] = "America/Pangnirtung";
  AmericaTimezone["Paramaribo"] = "America/Paramaribo";
  AmericaTimezone["Phoenix"] = "America/Phoenix";
  AmericaTimezone["PortAuPrince"] = "America/Port-au-Prince";
  AmericaTimezone["PortOfSpain"] = "America/Port_of_Spain";
  AmericaTimezone["PortoVelho"] = "America/Porto_Velho";
  AmericaTimezone["PuertoRico"] = "America/Puerto_Rico";
  AmericaTimezone["PuntaArenas"] = "America/Punta_Arenas";
  AmericaTimezone["RainyRiver"] = "America/Rainy_River";
  AmericaTimezone["RankinInlet"] = "America/Rankin_Inlet";
  AmericaTimezone["Recife"] = "America/Recife";
  AmericaTimezone["Regina"] = "America/Regina";
  AmericaTimezone["Resolute"] = "America/Resolute";
  AmericaTimezone["RioBranco"] = "America/Rio_Branco";
  AmericaTimezone["Santarem"] = "America/Santarem";
  AmericaTimezone["Santiago"] = "America/Santiago";
  AmericaTimezone["SantoDomingo"] = "America/Santo_Domingo";
  AmericaTimezone["SaoPaulo"] = "America/Sao_Paulo";
  AmericaTimezone["Scoresbysund"] = "America/Scoresbysund";
  AmericaTimezone["Sitka"] = "America/Sitka";
  AmericaTimezone["StBarthelemy"] = "America/St_Barthelemy";
  AmericaTimezone["StJohns"] = "America/St_Johns";
  AmericaTimezone["StKitts"] = "America/St_Kitts";
  AmericaTimezone["StLucia"] = "America/St_Lucia";
  AmericaTimezone["StThomas"] = "America/St_Thomas";
  AmericaTimezone["StVincent"] = "America/St_Vincent";
  AmericaTimezone["SwiftCurrent"] = "America/Swift_Current";
  AmericaTimezone["Tegucigalpa"] = "America/Tegucigalpa";
  AmericaTimezone["Thule"] = "America/Thule";
  AmericaTimezone["ThunderBay"] = "America/Thunder_Bay";
  AmericaTimezone["Tijuana"] = "America/Tijuana";
  AmericaTimezone["Toronto"] = "America/Toronto";
  AmericaTimezone["Tortola"] = "America/Tortola";
  AmericaTimezone["Vancouver"] = "America/Vancouver";
  AmericaTimezone["Whitehorse"] = "America/Whitehorse";
  AmericaTimezone["Winnipeg"] = "America/Winnipeg";
  AmericaTimezone["Yakutat"] = "America/Yakutat";
  AmericaTimezone["Yellowknife"] = "America/Yellowknife";
})(AmericaTimezone = exports.AmericaTimezone || (exports.AmericaTimezone = {}));
