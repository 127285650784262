import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ButtonModule } from '../button';
import { ImageModule } from '../image';
import { NotFoundComponent } from './components';

@NgModule({
  declarations: [NotFoundComponent],
  imports: [CommonModule, ImageModule, ButtonModule, RouterModule],
  exports: [NotFoundComponent],
})
export class NotFoundModule {}
