<ul class="supy-breadcrumb">
  <li *ngIf="showBackButton" class="supy-breadcrumb__item supy-breadcrumb__item-back" (click)="goBack()">
    <supy-icon name="arrow-left-secondary" class="supy-breadcrumb__item-text" size="small"></supy-icon>
  </li>
  <ng-container *ngFor="let breadcrumb of breadcrumbList$ | async; let isLast = last; trackBy: trackByLabel">
    <li
      class="supy-breadcrumb__item"
      [ngClass]="{ 'supy-breadcrumb__item--static': !breadcrumb.url, 'supy-breadcrumb__item--last': isLast }"
    >
      <span
        (click)="onClickBreadCrumb(breadcrumb)"
        class="supy-anchor supy-anchor--breadcrumb supy-breadcrumb__item-title"
        routerLinkActive="supy-anchor--active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ breadcrumb.label | titlecase }}
      </span>
    </li>

    <li *ngIf="!isLast" class="supy-breadcrumb__caret">/</li>
  </ng-container>
</ul>
