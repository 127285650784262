import produce from 'immer';
import { Observable, of, tap } from 'rxjs';
import { Params, Router } from '@angular/router';
import { createSelector, StateContext } from '@ngxs/store';

export interface IEntity<T = string> {
  readonly id: T;
}

export interface EntityListStateModel<T extends IEntity> {
  readonly entities: T[];
  readonly filters: object;
  readonly sequence: EntitySequence;
}

export interface EntitySequence {
  readonly id: string | null;
  readonly isFirst: boolean;
  readonly isLast: boolean;
}

export interface EntityListDateRangeFilter {
  readonly start: Date | string | null;
  readonly end: Date | string | null;
}

const SEQUENCE_DEFAULT: EntitySequence = {
  id: null,
  isFirst: false,
  isLast: false,
};

export interface FilterActionsOptions {
  readonly saveToUrl?: boolean;
}

const DEFAULT_FILTER_ACTIONS_OPTIONS: FilterActionsOptions = {
  saveToUrl: true,
};

export class EntityListState {
  constructor(protected readonly router: Router) {}

  protected static default() {
    return {
      entities: [],
      sequence: SEQUENCE_DEFAULT,
      filters: {},
    };
  }

  protected static all<T extends IEntity>(state: EntityListStateModel<T>) {
    return state.entities;
  }

  protected static current<T extends IEntity>(state: EntityListStateModel<T>) {
    return state.entities.find(o => o.id === state.sequence.id) ?? null;
  }

  protected static isFirst(state: EntityListStateModel<IEntity>) {
    return state.sequence.isFirst;
  }

  protected static isLast(state: EntityListStateModel<IEntity>) {
    return state.sequence.isLast;
  }

  protected static appliedFiltersCount(
    state: EntityListStateModel<IEntity>,
    defaultFilters: object,
    excludedFilters: string[] = [],
    excludeDefault = true,
  ): number {
    if (!Object.keys(state.filters).length) {
      return 0;
    }

    const count = Object.entries(state.filters).filter(
      ([key, value]: [string, object]) =>
        !excludedFilters?.includes(key) &&
        value !== null &&
        value !== undefined &&
        (typeof value === 'object' && !(value instanceof Date)
          ? Object.values(value).some(Boolean)
          : excludeDefault
          ? defaultFilters[key] !== value
          : true),
    ).length;

    return this.isDateRangeFilterApplied(state.filters as EntityListDateRangeFilter) ? count - 1 : count;
  }

  protected static currentFilters<T>(state: EntityListStateModel<IEntity>): T {
    return state.filters as unknown as T;
  }

  protected static one(id: string, next?: boolean) {
    return createSelector([EntityListState], <T extends IEntity>(state: EntityListStateModel<T>) => {
      let index = EntityListState.getIndex(id, state.entities);

      return state.entities?.[next === undefined ? index : next ? ++index : --index];
    });
  }

  protected static getIndex<T extends IEntity>(id: string, entities: readonly T[]) {
    return entities?.findIndex(e => e.id === id);
  }

  private static isDateRangeFilterApplied(filter: EntityListDateRangeFilter): boolean {
    return !!filter.start && !!filter.end;
  }

  protected setSequence<T extends EntityListStateModel<IEntity>>(ctx: StateContext<T>, payload: EntitySequence) {
    ctx.setState(
      produce<T>(draft => {
        draft.sequence = payload;
      }),
    );
  }

  protected resetSequence<T extends EntityListStateModel<IEntity>>(ctx: StateContext<T>) {
    ctx.setState(
      produce<T>(draft => {
        draft.sequence = SEQUENCE_DEFAULT;
      }),
    );
  }

  protected setOne<T extends EntityListStateModel<IEntity>>(ctx: StateContext<T>, payload: IEntity) {
    const entities = ctx.getState().entities;
    const entityIndex = EntityListState.getIndex(payload.id, entities);
    const entityExists = entities?.length && entityIndex !== -1;

    ctx.setState(
      produce<T>(draft => {
        if (entityExists) {
          draft.entities[entityIndex] = payload;
        } else {
          draft.entities = [payload];
        }

        draft.sequence = {
          id: payload.id,
          isFirst: entities?.length > 1 ? EntityListState.getIndex(payload.id, entities) === 0 : true,
          isLast: entities?.length > 1 ? EntityListState.getIndex(payload.id, entities) === entities?.length - 1 : true,
        };
      }),
    );
  }

  protected setMany<T extends EntityListStateModel<IEntity>>(ctx: StateContext<T>, payload: IEntity[]) {
    ctx.setState(
      produce<T>(draft => {
        draft.entities = payload;
        draft.sequence = SEQUENCE_DEFAULT;
      }),
    );
  }

  protected getOne<R extends IEntity, T extends EntityListStateModel<R>>(
    ctx: StateContext<T>,
    payload: {
      id: IEntity['id'];
      fetchApi: () => Observable<IEntity>;
      fromCache?: boolean;
      skipListChanges?: boolean;
    },
  ) {
    let entity$: Observable<IEntity>;
    const entities = ctx.getState().entities;
    const entityIndex = EntityListState.getIndex(payload.id, entities);
    const entityExists = entities?.length && entityIndex !== -1;

    if (payload.fromCache && entityExists) {
      entity$ = of(entities[entityIndex]);
    } else {
      entity$ = payload.fetchApi();
    }

    return entity$.pipe(
      tap(entity => {
        if (!payload.skipListChanges) {
          this.setOne(ctx, entity);
        }
      }),
    ) as Observable<R>;
  }

  protected initFilter<T extends EntityListStateModel<IEntity>>(
    ctx: StateContext<T>,
    defaultFilters: object,
    options: FilterActionsOptions = DEFAULT_FILTER_ACTIONS_OPTIONS,
  ) {
    const anyFilterInState = EntityListState.appliedFiltersCount(ctx.getState(), defaultFilters, null, false) > 0;
    const currentFilters = ctx.getState().filters;
    const filtersFromUrl = this.getFilterStateFromUrl(defaultFilters);

    if (anyFilterInState && !Object.keys(filtersFromUrl).length) {
      if (options.saveToUrl) {
        this.saveFilterStateToUrl(currentFilters, defaultFilters);
      }
    } else {
      ctx.setState(
        produce<T>(draft => {
          draft.filters = Object.assign(draft.filters, filtersFromUrl);
        }),
      );
    }
  }

  protected setFilter<T extends EntityListStateModel<IEntity>>(
    ctx: StateContext<T>,
    payload: object,
    defaultFilters: object,
    options: FilterActionsOptions = DEFAULT_FILTER_ACTIONS_OPTIONS,
  ) {
    ctx.setState(
      produce<T>(draft => {
        draft.filters = payload;
      }),
    );

    if (options.saveToUrl) {
      this.saveFilterStateToUrl(payload, defaultFilters);
    }
  }

  protected patchFilter<T extends EntityListStateModel<IEntity>>(
    ctx: StateContext<T>,
    payload: Partial<object>,
    defaultFilters: object,
    options: FilterActionsOptions = DEFAULT_FILTER_ACTIONS_OPTIONS,
  ) {
    const updatedFilter = {
      ...ctx.getState().filters,
      ...payload,
    };

    ctx.setState(
      produce<T>(draft => {
        draft.filters = updatedFilter;
      }),
    );

    if (options.saveToUrl) {
      this.saveFilterStateToUrl(updatedFilter, defaultFilters);
    }
  }

  protected resetFilter<T extends EntityListStateModel<IEntity>>(
    ctx: StateContext<T>,
    defaultFilters: object,
    options: FilterActionsOptions = DEFAULT_FILTER_ACTIONS_OPTIONS,
  ) {
    ctx.setState(
      produce<T>(draft => {
        draft.filters = defaultFilters;
      }),
    );

    if (options.saveToUrl) {
      this.saveFilterStateToUrl(null, defaultFilters);
    }
  }

  private saveFilterStateToUrl(filters: object, defaultFilters: object) {
    const url = this.router.url.split('?')[0];
    const queryParams = this.convertFiltersToQueryParams(filters ?? defaultFilters, defaultFilters);

    void this.router.navigate([url], { queryParams, queryParamsHandling: 'merge' });
  }

  private convertFiltersToQueryParams(filters: object, defaultFilters: object): Params {
    const queryParams: Params = {};

    Object.keys(defaultFilters).forEach(key => {
      const element = filters[key] as object;

      if (element !== null && element !== undefined) {
        if (!Array.isArray(element)) {
          queryParams[key] = encodeURIComponent(element as unknown as string);
        } else if (element.length) {
          queryParams[key] = encodeURIComponent(element.join(','));
        } else {
          queryParams[key] = null;
        }
      } else {
        queryParams[key] = null;
      }
    });

    return queryParams;
  }

  private getFilterStateFromUrl(defaultFilters: object): object {
    const filters = {};
    const params = new URLSearchParams(location.search);

    Object.entries(defaultFilters).forEach(([key, value]) => {
      let param = params.get(key);

      if (param) {
        param = decodeURIComponent(param);
        filters[key] = Array.isArray(value)
          ? param.split(',')
          : param === 'true'
          ? true
          : param === 'false'
          ? false
          : param;
      }
    });

    return filters;
  }
}
