import { isNil, mapValues, omitBy, pickBy } from 'lodash-es';
import { defer, distinctUntilChanged, map, merge, Observable, ReplaySubject, startWith, switchMap } from 'rxjs';
import { Directive, inject } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormArray, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';

import { isInstanceOf } from '../../helpers';
import { Destroyable } from '../../lifecycle';

type FormControlConfig<T> = [
  T | { value: T; disabled: boolean },
  (ValidatorFn | ValidatorFn[])?,
  (AsyncValidatorFn | AsyncValidatorFn[])?,
];
type FormControlType<T> = NonNullable<T> extends readonly unknown[] ? FormArray : FormGroup;
type StringKeys<T> = T extends never ? never : Extract<keyof T, string>;
type FormValue<T> = { [P in keyof T]: FormControlType<T[P]> | T[P] };
type NullableProperties<T> = { [P in keyof T]: T[P] | null };
type FormGroupConfig<T> = {
  [P in keyof T]: FormControlType<T[P]> | FormControlConfig<T[P]>;
};

@Directive()
export abstract class FormComponent<T extends object> extends Destroyable {
  readonly #formBuilder = inject(FormBuilder);
  /** The default value in the initial form controls configuration. */
  readonly #defaultValue: NullableProperties<T>;
  /** The original value from after the form finished loading. */
  readonly #initialValue: NullableProperties<T>;
  readonly #controlsSubject = new ReplaySubject<{
    [key: string]: AbstractControl | undefined;
  }>(1);

  protected readonly form: FormGroup;

  constructor(formControlsConfig: FormGroupConfig<NullableProperties<T>>) {
    super();

    this.form = this.#formBuilder.group(formControlsConfig);
    this.#defaultValue = this.form.value as NullableProperties<T>;
    this.#initialValue = this.form.value as NullableProperties<T>;
    this.#controlsSubject.next(this.form.controls);
  }

  protected get canSubmit(): boolean {
    return this.form.valid && this.form.enabled && this.form.dirty;
  }

  protected get canSubmitClean(): boolean {
    return this.form.valid && this.form.enabled;
  }

  protected getControl<K extends StringKeys<T>>(name: K): AbstractControl {
    const control = this.form.get(name);

    if (!control) {
      throw new Error(`Could not find form control "${name}".`);
    }

    return control;
  }

  protected getControlChanges<K extends StringKeys<T>>(name: K): Observable<AbstractControl> {
    return this.#controlsSubject.pipe(
      map(controls => {
        const control = controls[name];

        if (!control) {
          throw new Error(`Could not find form control "${name}".`);
        }

        return control;
      }),
      distinctUntilChanged(),
    );
  }

  protected isValidChanges<K extends StringKeys<T>>(name?: K): Observable<boolean> {
    const statusChanges = name
      ? this.getControlChanges(name).pipe(
          switchMap(control => control.statusChanges),
          startWith(this.getControl(name).status),
        )
      : this.form.statusChanges.pipe(startWith(this.form.status));

    return statusChanges.pipe(
      map(status => status === 'VALID'),
      distinctUntilChanged(),
    );
  }

  protected isValid<K extends StringKeys<T>>(name?: K): boolean {
    return name ? this.getControl(name).valid : this.form.valid;
  }

  protected setAndUpdateValidators<K extends StringKeys<T>>(
    name: K,
    validators: ValidatorFn | ValidatorFn[] | null,
  ): void {
    this.reconfigure(name, { validators });
  }

  protected reconfigure<K extends StringKeys<T>>(
    name: K,
    {
      asyncValidators,
      isDirty,
      isDisabled,
      isTouched,
      validators,
      value,
    }: {
      asyncValidators?: AsyncValidatorFn | AsyncValidatorFn[] | null;
      isDirty?: boolean;
      isDisabled?: boolean;
      isTouched?: boolean;
      validators?: ValidatorFn | ValidatorFn[] | null;
      value?: T[K] | FormControlType<T[K]> | null;
    } = {},
  ): void {
    const control = this.getControl(name);

    if (isDirty !== undefined) {
      if (isDirty) {
        control.markAsDirty();
      } else {
        control.markAsPristine();
      }
    }

    if (isDisabled !== undefined) {
      if (isDisabled) {
        control.disable();
      } else {
        control.enable();
      }
    }

    if (isTouched !== undefined) {
      if (isTouched) {
        control.markAsTouched();
      } else {
        control.markAsUntouched();
      }
    }

    if (value !== undefined) {
      this.setValue(name, value);
    }

    if (validators !== undefined) {
      control.setValidators(validators);
    }

    if (asyncValidators !== undefined) {
      control.setAsyncValidators(asyncValidators);
    }

    if (value !== undefined || validators !== undefined) {
      control.updateValueAndValidity();
    }
  }

  protected getValueChanges(): Observable<NullableProperties<T>>;
  protected getValueChanges<K extends StringKeys<T>>(name: K): Observable<T[K] | null>;
  protected getValueChanges<K extends StringKeys<T>>(name?: K): Observable<NullableProperties<T> | T[K] | null>;
  protected getValueChanges<K extends StringKeys<T>>(name?: K): Observable<NullableProperties<T> | T[K] | null> {
    if (name) {
      return this.getControlChanges(name).pipe(
        switchMap(control =>
          control.valueChanges.pipe(
            map(value => parseControlValue<NullableProperties<T>[K]>(value)),
            // Emit an initial value whenever the control changes since
            // `valueChanges` doesn't. Must be inside the `switchMap` in order
            // to respond to control changes.
            startWith(this.getValue(name)),
          ),
        ),
      );
    } else {
      return merge(
        this.form.valueChanges.pipe(
          // Disabled controls won't have their values included in
          // `valueChanges` but we still want to include them. Merging with
          // `getValue()` will ensure that we include all those fields as well
          // since it checks the value of each control individually.
          map((value: NullableProperties<T>[K]) => ({
            ...this.getValue(),
            ...value,
          })),
        ),
        // Get the initial value on first subscription so we always have
        // something to replay. `startWith` doesn't work because creation of
        // the observable could happen well before subscription, and the value
        // would be stale.
        defer(() => [this.getValue(name)]),
      ).pipe(distinctUntilChanged(isShallowEqual));
    }
  }

  protected getValidatedValueChanges(): Observable<T | null>;
  protected getValidatedValueChanges<K extends StringKeys<T>>(name: K): Observable<T[K] | null>;
  protected getValidatedValueChanges<K extends StringKeys<T>>(name?: K): Observable<T | T[K] | null> {
    return this.getValueChanges(name).pipe(
      switchMap(value =>
        this.isValidChanges(name).pipe(
          // Assuming the validators were set up correctly, we can assume that all
          // required values in T are set so we can safely cast NullableProperties<T> to T.
          map(isValid => (isValid ? (value as T | T[K] | null) : null)),
        ),
      ),
    );
  }

  protected getValue(): NullableProperties<T>;
  protected getValue<K extends StringKeys<T>>(name: K): T[K] | null;
  protected getValue<K extends StringKeys<T>>(name?: K): NullableProperties<T> | T[K] | null;
  protected getValue<K extends StringKeys<T>>(name?: K): NullableProperties<T> | T[K] | null {
    return name ? getControlValue(this.getControl(name)) : (mapValues(this.form.controls, getControlValue) as T | null);
  }

  protected setValue(value: NullableProperties<FormValue<T>>): void;
  protected setValue<K extends StringKeys<T>>(name: K, value: T[K] | FormControlType<T[K]> | null): void;
  protected setValue<K extends StringKeys<T>>(
    nameOrValue: K | NullableProperties<T>,
    maybeValue?: T[K] | FormControlType<T[K]> | null,
  ): void {
    if (typeof nameOrValue === 'string') {
      const name = nameOrValue;
      const value = maybeValue;

      if (value === undefined) {
        throw new Error('Missing value.');
      }

      const existingControl = this.getControl(name);

      if (isFormArray(value)) {
        if (existingControl.value !== null && !isFormArray(existingControl)) {
          throw new Error(`Cannot set a form array control to "${name}" as it is not a form array.`);
        }
        this.form.setControl(name, value);
      } else {
        existingControl.setValue(value);
      }
    } else {
      const formValue = nameOrValue;

      const arrayValues = pickBy(formValue, isFormArray);

      for (const [arrayName, arrayValue] of Object.entries(arrayValues)) {
        this.form.setControl(arrayName, arrayValue);
      }

      const literalValues = omitBy(formValue, isFormArray);

      this.form.patchValue(literalValues);
    }

    // Update with the latest controls in case any were updated above.
    this.#controlsSubject.next(this.form.controls);
    this.form.updateValueAndValidity();
  }

  protected disableForm(opts?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    this.form.disable(opts);
  }

  protected enableForm(opts?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    this.form.enable(opts);
  }

  /**
   * Resets the form back to the default value in the initial form controls
   * configuration.
   */
  protected resetToDefaultValue(): void {
    this.resetToValue(this.#defaultValue);
  }

  /**
   * Resets the form back to the original value from after the form finished
   * loading.
   */
  protected resetToInitialValue(): void {
    this.resetToValue(this.#initialValue);
  }

  private resetToValue(value: NullableProperties<T>): void {
    this.form.reset(value);
    // Odd fix: Marking this pristine fixes bug 15314 even though `this.form.reset`
    // supposedly resets all descendants back to pristine & untouched as well.
    this.form.markAsPristine();
  }
}

const isFormArray = isInstanceOf(FormArray);

function getControlValue<T = unknown>(control: AbstractControl): T | null {
  return parseControlValue(control.value);
}

function parseControlValue<T>(value: unknown): T | null {
  if (value instanceof FormArray) {
    // FormArray types are only allowed for array values, so converting
    // to `T` type from `any[]` should be valid.
    return value.controls.map(getControlValue) as unknown as T;
  } else {
    // No real way to ensure the type, but if used correctly, the values should
    // be enforced at construction time. If they're wrong, it's probably because
    // of bad template bindings with controls that return the wrong type.
    return value as T;
  }
}

function isShallowEqual<T>(a: T, b: T): boolean {
  if (isNil(a) || isNil(b)) {
    return a === b;
  }

  const aKeys = new Set(Object.keys(a));
  const bKeys = Object.keys(b);

  // Make sure all of `b`s keys are in `a`.
  if (aKeys.size !== bKeys.length || bKeys.some(key => !aKeys.has(key))) {
    return false;
  }

  // Make sure all of `a`s keys are in `b` and that each value matches.
  for (const key of aKeys) {
    if (a[key] !== b[key]) {
      return false;
    }
  }

  return true;
}
