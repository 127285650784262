import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  IgxActionStripModule,
  IgxDragDropModule,
  IgxGridModule,
  IgxTreeGridModule,
} from '@infragistics/igniteui-angular';

import { SupyCommonModule } from '@supy/common';

import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import {
  FilterCellTemplateDirective,
  GridCellEditorTemplateDirective,
  GridCellHeaderTemplateDirective,
  GridCellTemplateDirective,
  GridColumnComponent,
  GridComponent,
  GridContextMenuComponent,
  GridContextMenuTemplateDirective,
  GridDetailTemplateDirective,
  GridFooterComponent,
  GridRowSelectorTemplateDirective,
  TreeGridComponent,
} from './components';

const COMPONENTS = [
  GridComponent,
  GridColumnComponent,
  GridFooterComponent,
  TreeGridComponent,
  GridContextMenuComponent,
];

const COLUMN_DIRECTIVES = [
  GridCellEditorTemplateDirective,
  GridCellTemplateDirective,
  FilterCellTemplateDirective,
  GridCellHeaderTemplateDirective,
];

const GRID_DIRECTIVES = [
  GridDetailTemplateDirective,
  GridRowSelectorTemplateDirective,
  GridContextMenuTemplateDirective,
];

@NgModule({
  declarations: [...COMPONENTS, ...COLUMN_DIRECTIVES, ...GRID_DIRECTIVES],
  imports: [
    IgxGridModule,
    IgxTreeGridModule,
    IgxActionStripModule,
    IgxDragDropModule,
    ReactiveFormsModule,
    CommonModule,
    SupyCommonModule,
    ButtonModule,
    IconModule,
  ],
  exports: [...COMPONENTS, ...COLUMN_DIRECTIVES, ...GRID_DIRECTIVES],
})
export class GridModule {}
