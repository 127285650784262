import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Language } from '@supy.api/dictionaries';

import { IconType } from '../../../icon';
import { InputDensity } from '../../../input';

export interface LanguageSelectOption {
  readonly value: Language;
  readonly label: string;
  readonly icon?: IconType;
}

export const ENGLISH_SELECT_OPTION: LanguageSelectOption = {
  label: 'English',
  value: Language.English,
  icon: 'flag_en',
};
export const ARABIC_SELECT_OPTION: LanguageSelectOption = { label: 'العربية', value: Language.Arabic, icon: 'flag_ae' };
export const SPANISH_SELECT_OPTION: LanguageSelectOption = {
  label: 'Española',
  value: Language.Spanish,
  icon: 'flag_es',
};

@Component({
  selector: 'supy-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectComponent implements OnInit, ControlValueAccessor {
  @Input() readonly placeholder: string;
  @Input() @HostBinding('attr.name') readonly name: string;

  @Input() readonly clearable: boolean;
  @Input() readonly loading: boolean;

  @Input() value?: Language;
  @Input() protected readonly languages: LanguageSelectOption[] = [
    ENGLISH_SELECT_OPTION,
    ARABIC_SELECT_OPTION,
    SPANISH_SELECT_OPTION,
  ];

  @Input() protected readonly density: InputDensity = 'small';

  @Input() disabled?: boolean;
  protected touched?: boolean;

  @Output() selected = new EventEmitter<Language>();

  onChange?: (value: Language) => void;
  onTouched?: () => void;

  get selectedLanguageFlag(): IconType {
    return this.languages.find(item => item.value === (this.value ?? this.control?.value))?.icon;
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    readonly elementRef: ElementRef<HTMLElement>,
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  writeValue(value: Language): void {
    this.value = value;
  }

  registerOnChange(fn: (value: Language) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.cdr.markForCheck();
  }

  async ngOnInit(): Promise<void> {
    await Promise.resolve().then(() => {
      this.cdr.markForCheck();
    });

    if (this.control?.value && !this.value) {
      this.writeValue(this.control.value as Language);
    }

    this.control?.control.valueChanges.subscribe((value: Language) => {
      this.writeValue(value);
      this.cdr.markForCheck();
    });
  }

  trackByValue(_: number, item: LanguageSelectOption): Language {
    return item.value;
  }
}
