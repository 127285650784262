import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxInputGroupModule } from '@infragistics/igniteui-angular';

import { IconModule } from '../icon';
import { InputHintsModule } from '../input-hints';
import { LoaderModule } from '../loader';
import { TextareaComponent } from './components';

@NgModule({
  declarations: [TextareaComponent],
  imports: [CommonModule, IgxInputGroupModule, InputHintsModule, IconModule, LoaderModule],
  exports: [TextareaComponent, InputHintsModule],
})
export class TextareaModule {}
