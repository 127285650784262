import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minMax(): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const minValue = formGroup.get('min').value as number;
    const maxValue = formGroup.get('max').value as number;

    if (minValue === null || maxValue === null) {
      return null;
    }

    return minValue <= maxValue ? null : { minMax: true };
  };
}
