import { parsePhoneNumber } from 'awesome-phonenumber';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function phone(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;
    const phoneNumberRegex = /^\+[0-9]+$/;

    if (!value) {
      return null;
    }

    if (!phoneNumberRegex.test(value)) {
      return { phone: true };
    }

    try {
      const phoneNumber = parsePhoneNumber(value);

      if (!phoneNumber.valid) {
        return { phone: true };
      }
    } catch {
      return { phone: true };
    }

    return null;
  };
}
