import { HttpUrlEncodingCodec } from '@angular/common/http';

export class DefaultQueryEncoder extends HttpUrlEncodingCodec {
  encodeKey(key: string): string {
    return super.encodeKey(key);
  }

  encodeValue<T>(value: T | string): string {
    return typeof value === 'object' ? JSON.stringify(value) : super.encodeValue(value as string);
  }

  decodeKey(key: string): string {
    return super.decodeKey(key);
  }

  decodeValue<T>(value: string): T {
    return JSON.parse(super.decodeValue(value)) as T;
  }
}
