import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CountdownDirective } from './directives';

@NgModule({
  declarations: [CountdownDirective],
  imports: [CommonModule],
  exports: [CountdownDirective],
})
export class CountdownModule {}
