import { IConfig, UnleashClient } from 'unleash-proxy-client';
import { APP_INITIALIZER, EnvironmentProviders, ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { DefaultUnleashGuard } from './guards';
import { DefaultUnleashResolver } from './resolvers';
import { UnleashDirective } from './unleash.directive';
import { UNLEASH_CLIENT } from './unleash.injector';

@NgModule({
  declarations: [UnleashDirective],
  providers: [DefaultUnleashResolver, DefaultUnleashGuard],
  exports: [UnleashDirective],
})
export class UnleashModule {
  static forRoot(config: IConfig, initialize = true): ModuleWithProviders<UnleashModule> {
    const PROVIDERS: Array<Provider | EnvironmentProviders> = [
      {
        provide: UNLEASH_CLIENT,
        useFactory: () => {
          return new UnleashClient({ ...config });
        },
      },
    ];

    if (initialize) {
      PROVIDERS.push({
        provide: APP_INITIALIZER,
        useFactory: (unleash: UnleashClient) => {
          return () => unleash.start();
        },
        deps: [UNLEASH_CLIENT],
        multi: true,
      });
    }

    return {
      ngModule: UnleashModule,
      providers: [...PROVIDERS],
    };
  }
}
