import { Injectable } from '@angular/core';
import { Meter, MeterProvider } from '@opentelemetry/api-metrics';
import { NOOP_METER } from '@opentelemetry/api-metrics/build/src/NoopMeter';
import { MeterOptions } from '@opentelemetry/api-metrics/build/src/types/Meter';

import { DEFAULT_METER } from '../../common';
import { IMetricService } from '../base';

@Injectable()
export class NoopMetricService implements IMetricService {
  private readonly meterProvider: MeterProvider;

  constructor() {
    this.meterProvider = new NoopMeterProvider();
  }

  getMeter(name: string = DEFAULT_METER): Meter {
    return this.meterProvider.getMeter(name);
  }

  getMeterProvider(): MeterProvider {
    return this.meterProvider;
  }
}

class NoopMeterProvider implements MeterProvider {
  getMeter(_name: string, _version?: string, _options?: MeterOptions) {
    return NOOP_METER;
  }
}
