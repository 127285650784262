export function getNestedValue<T extends object, S>(item: T, path: string): S | undefined {
  if (!path) {
    return undefined;
  }

  const chunks = path.split('.');
  let res: object = item,
    i: number;

  for (i = 0; i < chunks.length - 1; i++) {
    if (typeof res[chunks[i]] === 'object') {
      res = res[chunks[i]] as object;
    } else {
      return undefined;
    }
  }

  return res[chunks[i]] as S;
}

export function removeEmpty<T extends object>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => value !== null && value !== undefined && value !== '')
      .map(([key, value]) => [
        key,
        value === Object(value) && !shouldLeaveAsIs(value)
          ? Array.isArray(value)
            ? removeEmptyFromArray(value)
            : removeEmpty(value as object)
          : (value as object),
      ])
      .filter(([_, value]) =>
        value instanceof Object && !shouldLeaveAsIs(value) ? Object.keys(value).length > 0 : true,
      ),
  ) as T;
}

export function removeEmptyFromArray(arr: unknown[]): unknown[] {
  return arr.reduce<unknown[]>((acc, value) => {
    if (value !== null && value !== undefined && value !== '') {
      if (Array.isArray(value)) {
        acc.push(removeEmptyFromArray(value));
      } else {
        acc.push(typeof value === 'object' && !shouldLeaveAsIs(value) ? removeEmpty(value) : value);
      }
    }

    return acc;
  }, []);
}

function shouldLeaveAsIs<T extends object>(asd: T): boolean {
  return asd instanceof Date || asd instanceof Blob;
}
