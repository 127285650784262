import { NonFunctionProperties } from '@supy/core';

import { getApiDetailsDecorator } from '../helpers';
import { BranchRegionApi, CreateBranchRegionRequest, UpdateBranchRegionRequest } from './branch-region.model';

const ApiProperty = getApiDetailsDecorator<BranchRegionApi>();

export class BranchRegion {
  private constructor(args: NonFunctionProperties<BranchRegion>) {
    this.id = args.id;
    this.name = args.name;
    this.code = args.code;
    this.isActive = args.isActive;
    this.branchCount = args.branchCount ?? 0;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly name: string;
  @ApiProperty() readonly code: string;
  @ApiProperty() readonly isActive: boolean;
  @ApiProperty() readonly branchCount?: number;

  static default(): BranchRegion {
    return { id: '', name: '', code: crypto.randomUUID().slice(0, 6), isActive: true };
  }

  static deserialize(data: BranchRegionApi): BranchRegion {
    return new BranchRegion({
      id: data.id,
      name: data.name,
      code: data.code,
      isActive: data.isActive,
    });
  }

  static deserializeList(data: BranchRegionApi[]): BranchRegion[] {
    return data.map(branchRegion => BranchRegion.deserialize(branchRegion));
  }

  static serializeCreate(data: BranchRegion, retailerId: string): CreateBranchRegionRequest {
    return {
      name: data.name,
      code: data.code,
      isActive: data.isActive,
      retailer: { id: retailerId },
    };
  }

  static serializeUpdate(data: BranchRegion, retailerId: string): UpdateBranchRegionRequest {
    return {
      id: data.id,
      ...this.serializeCreate(data, retailerId),
    };
  }
}
