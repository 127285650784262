import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IgxToggleModule } from '@infragistics/igniteui-angular';

import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { InputModule } from '../input';
import { EditableOptionsComponent } from './components';

@NgModule({
  declarations: [EditableOptionsComponent],
  imports: [CommonModule, IgxToggleModule, ReactiveFormsModule, ButtonModule, IconModule, InputModule],
  exports: [EditableOptionsComponent],
})
export class EditableOptionsModule {}
