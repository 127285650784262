<igx-grid
  class="supy-grid"
  igxDrop
  [height]="height"
  [width]="width"
  [moving]="hasMovingColumns"
  [cellSelection]="cellSelection"
  [rowSelection]="rowSelection"
  [data]="data"
  [rowEditable]="rowEditable"
  [rowHeight]="minRowHeight"
  [rowClasses]="rowClasses"
  [dataCloneStrategy]="dataCloneStrategy"
  [autoGenerate]="autoGenerate"
  [primaryKey]="primaryKey"
  [clipboardOptions]="clipboardOptions"
  [allowFiltering]="allowFiltering"
  [filterStrategy]="filterStrategy"
  [sortStrategy]="sortStrategy"
  [filterMode]="filterMode"
  [selectRowOnClick]="selectRowOnClick"
  [selectedRows]="selectedRows"
  [rowDraggable]="rowDraggable"
  [hideRowSelectors]="hideRowSelectors"
  [emptyGridTemplate]="emptyGridTemplate"
  [addRowEmptyTemplate]="addRowEmptyTemplate"
  [pagingMode]="paginationMode"
  (rowSelectionChanging)="rowSelectionChanging.emit($event)"
  (columnMovingEnd)="columnMovingEnd.emit($event)"
  (cellClick)="cellClick.emit($event)"
  (doubleClick)="doubleClick.emit($event)"
  (selected)="selected.emit($event)"
  (cellEdit)="cellEdit.emit($event)"
  (cellEditDone)="cellEditDone.emit($event)"
  (cellEditEnter)="cellEditEnter.emit($event)"
  (cellEditExit)="cellEditExit.emit($event)"
  (rowEdit)="rowEdit.emit($event)"
  (rowEditDone)="rowEditDone.emit($event)"
  (rowEditEnter)="rowEditEnter.emit($event)"
  (rowEditExit)="rowEditExit.emit($event)"
  (sorting)="sorting.emit($event)"
  (filtering)="filtering.emit($event)"
  (filteringDone)="filteringDone.emit($event)"
  (dropped)="onDropAllowed($event)"
  (rowDeleted)="onRowDeleted($event)"
  (rowDragEnd)="onRowDragEnd($event)"
  (rowAdded)="onRowAdded($event)"
  (filteringExpressionsTreeChange)="filteringExpressionsTreeChange.emit($event)"
  (contextMenu)="contextMenuHandler($event)"
  (document:click)="hideContextMenu()"
  (document:scroll)="hideContextMenu()"
  (rendered)="rendered.emit($event)"
>
  <ng-template igxExcelStyleHeaderIcon>
    <supy-icon name="filter-alt"></supy-icon>
  </ng-template>
  <ng-template igxRowCollapsedIndicator></ng-template>
  <ng-template igxRowExpandedIndicator></ng-template>
  <ng-template igxSortHeaderIcon>
    <supy-icon name="grid-sort-default" color="secondary"></supy-icon>
  </ng-template>
  <ng-template igxSortAscendingHeaderIcon>
    <supy-icon name="grid-sort-ascending" color="secondary"></supy-icon>
  </ng-template>
  <ng-template igxSortDescendingHeaderIcon>
    <supy-icon name="grid-sort-descending" color="secondary"></supy-icon>
  </ng-template>

  <igx-paginator
    *ngIf="paginationMode"
    [page]="page"
    [perPage]="perPage"
    [totalRecords]="10000"
    (pageChange)="onPageChange($event)"
  >
    <igx-paginator-content>
      <div class="supy-grid__pagination">
        <supy-button [disabled]="prevPageDisabled" (buttonClick)="onPageChange(page - 1)" color="primary" name="left"
          ><supy-icon [name]="previousIconName"></supy-icon
        ></supy-button>

        <supy-button color="default-outline" name="page-index">{{ page + 1 }}</supy-button>

        <supy-button [disabled]="nextPageDisabled" (buttonClick)="onPageChange(page + 1)" color="primary" name="right"
          ><supy-icon [name]="nextIconName"></supy-icon
        ></supy-button>
      </div>
    </igx-paginator-content>
  </igx-paginator>

  <igx-column
    *ngFor="let gridColumn of columns; trackBy: trackColumnChanges"
    [sortable]="gridColumn.sortable"
    [header]="gridColumn.header"
    [field]="gridColumn.field"
    [width]="gridColumn.width"
    [minWidth]="gridColumn.minWidth"
    [maxWidth]="gridColumn.maxWidth"
    [dataType]="gridColumn.dataType"
    [disablePinning]="gridColumn.disablePinning"
    [disableHiding]="gridColumn.disableHiding"
    [hidden]="gridColumn.hidden"
    [formatter]="gridColumn.formatter"
    [filters]="gridColumn.filters"
    [filterable]="gridColumn.filterable"
    [headerClasses]="'align-' + gridColumn.align + ' ' + gridColumn.headerClasses"
    [headerGroupClasses]="gridColumn.headerGroupClasses"
    [editable]="gridColumn.editable"
    [cellClasses]="gridColumn.cellClasses"
    [required]="gridColumn.required"
    [pipeArgs]="gridColumn.pipeArgs"
  >
    <ng-template *ngIf="gridColumn?.cellTemplate?.template" igxCell let-value let-cell="cell">
      <ng-container
        *ngTemplateOutlet="
          gridColumn.cellTemplate.template;
          context: { $implicit: value, value: value, cell: cell, rowData: cell.row.data }
        "
      >
      </ng-container>
    </ng-template>
    <ng-template
      *ngIf="gridColumn?.cellEditorTemplate?.template"
      igxCellEditor
      let-value
      let-cell="cell"
      let-fc="formControl"
    >
      <ng-container
        *ngTemplateOutlet="
          gridColumn.cellEditorTemplate.template;
          context: { $implicit: value, value: value, cell: cell, fc: fc, rowData: cell.row.data }
        "
      ></ng-container>
    </ng-template>

    <ng-template *ngIf="gridColumn?.cellHeaderTemplate?.template" igxHeader let-value let-column="column">
      <ng-container
        *ngTemplateOutlet="gridColumn.cellHeaderTemplate.template; context: { $implicit: value, column: column }"
      ></ng-container>
    </ng-template>

    <ng-template *ngIf="gridColumn?.filterCellTemplate?.template" igxFilterCellTemplate let-column="column">
      <ng-container
        *ngTemplateOutlet="gridColumn.filterCellTemplate.template; context: { column: column }"
      ></ng-container>
    </ng-template>
  </igx-column>

  <igx-column *ngIf="detailTemplate?.template" width="5%" [sortable]="false" [filterable]="false">
    <ng-template igxCell let-cell="cell">
      <supy-icon
        *ngIf="!cell.row.expanded"
        class="expanded-indicator cursor--pointer"
        (click)="onToggleRow($event, cell.id.rowID)"
        name="arrow-down"
      ></supy-icon>
      <supy-icon
        *ngIf="cell.row.expanded"
        class="expanded-indicator cursor--pointer"
        (click)="onToggleRow($event, cell.id.rowID)"
        name="arrow-up"
      ></supy-icon>
    </ng-template>
  </igx-column>

  <ng-template *ngIf="detailTemplate?.template" igxGridDetail let-dataItem>
    <ng-container
      *ngTemplateOutlet="detailTemplate.template; context: { $implicit: dataItem, dataItem: dataItem }"
    ></ng-container>
  </ng-template>

  <ng-template *ngIf="rowSelectorTemplate?.template" igxRowSelector let-rowContext>
    <ng-container
      *ngTemplateOutlet="rowSelectorTemplate.template; context: { $implicit: rowContext, rowContext: rowContext }"
    ></ng-container>
  </ng-template>

  <igx-action-strip *ngIf="editableActions" #actionStrip>
    <div class="supy-grid__editable-actions" *ngIf="!inEditMode(actionStrip.context)">
      <supy-button *ngIf="showAddAction" variant="icon" title="Add" (buttonClick)="startAddRow(actionStrip.context)">
        <supy-icon name="plus-flat-circle" color="success" size="medium"></supy-icon>
      </supy-button>
      <supy-button *ngIf="showEditAction" variant="icon" title="Edit" (buttonClick)="startEditRow(actionStrip.context)">
        <supy-icon name="edit" color="primary" size="medium"></supy-icon>
      </supy-button>
      <supy-button
        *ngIf="showDeleteAction"
        variant="icon"
        title="Delete"
        (buttonClick)="deleteRow(actionStrip.context)"
      >
        <supy-icon name="delete" color="error" size="medium"></supy-icon>
      </supy-button>
    </div>
  </igx-action-strip>

  <ng-template igxRowEditActions let-endRowEdit>
    <div class="supy-grid__row-edit-actions">
      <supy-button type="button" color="error-secondary" igxRowEditTabStop (buttonClick)="endRowEdit(false)">
        <supy-icon name="close"></supy-icon>
        <span i18n="@@cancel">Cancel</span>
      </supy-button>
      <supy-button color="secondary" igxRowEditTabStop (buttonClick)="endRowEdit(true)">
        <supy-icon name="check"></supy-icon>
        <span i18n="@@apply">Apply</span>
      </supy-button>
    </div>
  </ng-template>

  <ng-template #addRowEmptyTemplate>
    <supy-button
      *ngIf="showAddRowEmptyButton"
      color="secondary"
      (buttonClick)="onRowAdd()"
      i18n="@@common.actions.addItem"
      >Add Item</supy-button
    >
  </ng-template>

  <ng-template igxRowEditText></ng-template>
  <ng-template igxRowAddText></ng-template>

  <igx-grid-footer>
    <ng-content select="supy-grid-footer"></ng-content>
  </igx-grid-footer>

  <igx-grid-footer *ngIf="addedInFooter" class="supy-grid-footer__add-row">
    <supy-button color="default" width="full" (buttonClick)="onRowAdd()">
      <span class="supy-grid-footer__add-row-content">
        <span class="supy-grid-footer__add-row-content-label">{{ addedInFooterTitle }}</span>
        <supy-icon
          name="plus-flat-circle"
          title="Add"
          color="primary"
          size="medium"
          [loading]="addedInFooterLoading"
        ></supy-icon>
      </span>
    </supy-button>
  </igx-grid-footer>
</igx-grid>
<ng-container *ngIf="contextMenuTemplate?.template && showContextMenu">
  <ng-container
    *ngTemplateOutlet="
      contextMenuTemplate?.template;
      context: { $implicit: targetCell, cell: targetCell, menuLeft: contextMenuX, menuTop: contextMenuY }
    "
  ></ng-container>
</ng-container>
